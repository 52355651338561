var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "learning-form-header flex-wrap" }, [
      _c("h4", [_vm._v("LLM Dialogic Interaction")]),
      _c("div", { staticClass: "learning-quiz-btn" }, [
        _vm.status == "published"
          ? _c(
              "button",
              {
                staticClass: "btn header-btn secondary",
                on: {
                  click: function ($event) {
                    return _vm.publishLO("published")
                  },
                },
              },
              [_vm._v(" Save ")]
            )
          : _vm._e(),
        _vm.status == "drafted"
          ? _c(
              "button",
              {
                staticClass: "btn header-btn primary",
                on: {
                  click: function ($event) {
                    return _vm.publishLO("published")
                  },
                },
              },
              [_vm._v(" Publish LO ")]
            )
          : _vm._e(),
      ]),
    ]),
    _c("div", { staticClass: "learning-lo-tabs" }, [
      _c("div", { staticClass: "setup-container" }, [
        _c("ul", [
          _c(
            "li",
            { class: { active: _vm.isActiveTab === "basic-information" } },
            [
              _c(
                "button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.switchTab("basic-information")
                    },
                  },
                },
                [
                  _c("span", { staticClass: "check-box" }),
                  _c("span", [_vm._v("Basic Information")]),
                ]
              ),
            ]
          ),
          _c("li", { class: { active: _vm.isActiveTab === "kb-setup" } }, [
            _c(
              "button",
              {
                on: {
                  click: function ($event) {
                    return _vm.switchTab("kb-setup")
                  },
                },
              },
              [
                _c("span", { staticClass: "check-box" }),
                _c("span", [_vm._v("Knowledge Base Setup")]),
              ]
            ),
          ]),
          _c("li", { class: { active: _vm.isActiveTab === "prompts-setup" } }, [
            _c(
              "button",
              {
                on: {
                  click: function ($event) {
                    return _vm.switchTab("prompts-setup")
                  },
                },
              },
              [
                _c("span", { staticClass: "check-box" }),
                _c("span", [_vm._v("Dialogic Questions Setup")]),
              ]
            ),
          ]),
          _c("li", { class: { active: _vm.isActiveTab === "chat-testing" } }, [
            _c(
              "button",
              {
                on: {
                  click: function ($event) {
                    return _vm.switchTab("chat-testing")
                  },
                },
              },
              [
                _c("span", { staticClass: "check-box" }),
                _c("span", [_vm._v("Testing")]),
              ]
            ),
          ]),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "learning-quiz-content" }, [
      _vm.isActiveTab == "basic-information"
        ? _c(
            "div",
            { staticClass: "setup-content" },
            [
              _vm._m(0),
              _c("ValidationObserver", { ref: "form" }, [
                _c("form", { staticClass: "w-100" }, [
                  _c("div", { staticClass: "row custom-row" }, [
                    _c("div", { staticClass: "col-sm-5" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", [_vm._v("Interaction title")]),
                          _c("ValidationProvider", {
                            attrs: {
                              name: "Interaction Title",
                              rules: "required|max:100",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ errors }) {
                                    return [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.name,
                                            expression: "name",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        class: [
                                          _vm.isActive && errors[0]
                                            ? "invalid"
                                            : "",
                                        ],
                                        attrs: { type: "text" },
                                        domProps: { value: _vm.name },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.name = $event.target.value
                                          },
                                        },
                                      }),
                                      errors[0]
                                        ? _c(
                                            "span",
                                            {
                                              class: [
                                                _vm.isActive ? "invalid" : "",
                                              ],
                                              style: [
                                                _vm.isActive
                                                  ? { display: "block" }
                                                  : { display: "none" },
                                              ],
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  errors[0].replace("The ", "")
                                                )
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1531519230
                            ),
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", [_vm._v("Administrative notes")]),
                          _c("ValidationProvider", {
                            attrs: {
                              name: "Administrative Notes",
                              rules: "required",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ errors }) {
                                    return [
                                      _c("textarea", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.adminNotes,
                                            expression: "adminNotes",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        class: [
                                          _vm.isActive && errors[0]
                                            ? "invalid"
                                            : "",
                                        ],
                                        attrs: {
                                          type: "Description",
                                          id: "notes",
                                          rows: "4",
                                        },
                                        domProps: { value: _vm.adminNotes },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.adminNotes = $event.target.value
                                          },
                                        },
                                      }),
                                      errors[0]
                                        ? _c(
                                            "span",
                                            {
                                              class: [
                                                _vm.isActive ? "invalid" : "",
                                              ],
                                              style: [
                                                _vm.isActive
                                                  ? { display: "block" }
                                                  : { display: "none" },
                                              ],
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  errors[0].replace("The ", "")
                                                )
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1222137434
                            ),
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", [_vm._v("Character Name")]),
                          _c("ValidationProvider", {
                            attrs: {
                              name: "Administrative Notes",
                              rules: "required",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ errors }) {
                                    return [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.characterName,
                                            expression: "characterName",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        class: [
                                          _vm.isActive && errors[0]
                                            ? "invalid"
                                            : "",
                                        ],
                                        attrs: {
                                          type: "text",
                                          id: "character-name",
                                          placeholder:
                                            "Add your character's name",
                                        },
                                        domProps: { value: _vm.characterName },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.characterName =
                                              $event.target.value
                                          },
                                        },
                                      }),
                                      errors[0]
                                        ? _c(
                                            "span",
                                            {
                                              class: [
                                                _vm.isActive ? "invalid" : "",
                                              ],
                                              style: [
                                                _vm.isActive
                                                  ? { display: "block" }
                                                  : { display: "none" },
                                              ],
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  errors[0].replace("The ", "")
                                                )
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3312015705
                            ),
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", [_vm._v("Character Pronouns")]),
                          _c("ValidationProvider", {
                            attrs: {
                              name: "Administrative Notes",
                              rules: "required",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ errors }) {
                                    return [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.characterPronouns,
                                            expression: "characterPronouns",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        class: [
                                          _vm.isActive && errors[0]
                                            ? "invalid"
                                            : "",
                                        ],
                                        attrs: {
                                          type: "text",
                                          id: "character-name",
                                          placeholder:
                                            "Add your character's name",
                                        },
                                        domProps: {
                                          value: _vm.characterPronouns,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.characterPronouns =
                                              $event.target.value
                                          },
                                        },
                                      }),
                                      errors[0]
                                        ? _c(
                                            "span",
                                            {
                                              class: [
                                                _vm.isActive ? "invalid" : "",
                                              ],
                                              style: [
                                                _vm.isActive
                                                  ? { display: "block" }
                                                  : { display: "none" },
                                              ],
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  errors[0].replace("The ", "")
                                                )
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              692423257
                            ),
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", [_vm._v("Character Age")]),
                          _c("ValidationProvider", {
                            attrs: {
                              name: "Administrative Notes",
                              rules: "required",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ errors }) {
                                    return [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.characterAge,
                                            expression: "characterAge",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        class: [
                                          _vm.isActive && errors[0]
                                            ? "invalid"
                                            : "",
                                        ],
                                        attrs: {
                                          type: "text",
                                          id: "character-age",
                                          placeholder:
                                            "Add your character's age",
                                        },
                                        domProps: { value: _vm.characterAge },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.characterAge =
                                              $event.target.value
                                          },
                                        },
                                      }),
                                      errors[0]
                                        ? _c(
                                            "span",
                                            {
                                              class: [
                                                _vm.isActive ? "invalid" : "",
                                              ],
                                              style: [
                                                _vm.isActive
                                                  ? { display: "block" }
                                                  : { display: "none" },
                                              ],
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  errors[0].replace("The ", "")
                                                )
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2978458425
                            ),
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", [_vm._v("Character Job")]),
                          _c("ValidationProvider", {
                            attrs: {
                              name: "Administrative Notes",
                              rules: "required",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ errors }) {
                                    return [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.characterJob,
                                            expression: "characterJob",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        class: [
                                          _vm.isActive && errors[0]
                                            ? "invalid"
                                            : "",
                                        ],
                                        attrs: {
                                          type: "text",
                                          id: "character-job",
                                          placeholder:
                                            "Add your character's job",
                                        },
                                        domProps: { value: _vm.characterJob },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.characterJob =
                                              $event.target.value
                                          },
                                        },
                                      }),
                                      errors[0]
                                        ? _c(
                                            "span",
                                            {
                                              class: [
                                                _vm.isActive ? "invalid" : "",
                                              ],
                                              style: [
                                                _vm.isActive
                                                  ? { display: "block" }
                                                  : { display: "none" },
                                              ],
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  errors[0].replace("The ", "")
                                                )
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              4192617913
                            ),
                          }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", [_vm._v("Character Image")]),
                        _vm.characterImageURL
                          ? _c("img", {
                              staticClass: "character-image",
                              attrs: { src: _vm.characterImageURL },
                            })
                          : _vm._e(),
                        _vm.characterImageURL
                          ? _c(
                              "button",
                              {
                                staticClass:
                                  "btn header-btn secondary small-btn",
                                on: { click: _vm.deleteCharacterImage },
                              },
                              [_vm._v("Delete Character Image")]
                            )
                          : _vm._e(),
                        !_vm.characterImageURL
                          ? _c("input", {
                              ref: "fileInput",
                              staticStyle: { display: "none" },
                              attrs: {
                                type: "file",
                                accept: "image/jpeg, image/png",
                              },
                              on: { change: _vm.uploadImage },
                            })
                          : _vm._e(),
                        !_vm.characterImageURL
                          ? _c(
                              "a",
                              {
                                staticClass:
                                  "btn header-btn secondary small-btn",
                                on: {
                                  click: function ($event) {
                                    return _vm.$refs.fileInput.click()
                                  },
                                },
                              },
                              [_vm._v(" Upload Character Image ")]
                            )
                          : _vm._e(),
                      ]),
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", [_vm._v("Conversation Characteristics")]),
                        _c(
                          "select",
                          {
                            staticClass: "form-select",
                            on: {
                              change: function ($event) {
                                return _vm.setCharacteristics($event)
                              },
                            },
                          },
                          _vm._l(_vm.optionsCharacteristics, function (option) {
                            return _c(
                              "option",
                              {
                                key: option.id,
                                domProps: {
                                  value: option.text,
                                  selected:
                                    option.text ===
                                    _vm.characterCharacteristics,
                                },
                              },
                              [_vm._v(" " + _vm._s(option.text) + " ")]
                            )
                          }),
                          0
                        ),
                      ]),
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { staticClass: "form-label" }, [
                          _vm._v("Character Stance"),
                        ]),
                        _c(
                          "div",
                          { staticClass: "d-flex justify-content-between" },
                          [
                            _c("div", { staticClass: "trigger-item" }, [
                              _c("label", [_vm._v("Correct / Incorrect")]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.characterStance,
                                    expression: "characterStance",
                                  },
                                ],
                                attrs: { type: "radio" },
                                domProps: {
                                  value: "correct",
                                  checked: _vm._q(
                                    _vm.characterStance,
                                    "correct"
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    _vm.characterStance = "correct"
                                  },
                                },
                              }),
                            ]),
                            _c("div", { staticClass: "trigger-item" }, [
                              _c("label", [_vm._v("Opinion / Argument")]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.characterStance,
                                    expression: "characterStance",
                                  },
                                ],
                                attrs: { type: "radio" },
                                domProps: {
                                  value: "opinion",
                                  checked: _vm._q(
                                    _vm.characterStance,
                                    "opinion"
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    _vm.characterStance = "opinion"
                                  },
                                },
                              }),
                            ]),
                            _c("div", { staticClass: "trigger-item" }, [
                              _c("label", [
                                _vm._v("I understand / I don't understand"),
                              ]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.characterStance,
                                    expression: "characterStance",
                                  },
                                ],
                                attrs: { type: "radio" },
                                domProps: {
                                  value: "understand",
                                  checked: _vm._q(
                                    _vm.characterStance,
                                    "understand"
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    _vm.characterStance = "understand"
                                  },
                                },
                              }),
                            ]),
                            _c("div", { staticClass: "trigger-item" }, [
                              _c("label", [_vm._v("GPT")]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.characterStance,
                                    expression: "characterStance",
                                  },
                                ],
                                attrs: { type: "radio" },
                                domProps: {
                                  value: "gpt",
                                  checked: _vm._q(_vm.characterStance, "gpt"),
                                },
                                on: {
                                  change: function ($event) {
                                    _vm.characterStance = "gpt"
                                  },
                                },
                              }),
                            ]),
                            _c("div", { staticClass: "trigger-item" }, [
                              _c("label", [_vm._v("Follow up")]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.characterStance,
                                    expression: "characterStance",
                                  },
                                ],
                                attrs: { type: "radio" },
                                domProps: {
                                  value: "followup",
                                  checked: _vm._q(
                                    _vm.characterStance,
                                    "followup"
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    _vm.characterStance = "followup"
                                  },
                                },
                              }),
                            ]),
                          ]
                        ),
                      ]),
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", [_vm._v("Conclusion Message")]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.conclusionMessage,
                              expression: "conclusionMessage",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            placeholder: "Conclusion Message",
                          },
                          domProps: { value: _vm.conclusionMessage },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.conclusionMessage = $event.target.value
                            },
                          },
                        }),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-secondary",
                            on: {
                              click: function ($event) {
                                return _vm.saveLO("drafted")
                              },
                            },
                          },
                          [_vm._v("Save Conclusion")]
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass:
                            "form-group custom-control custom-checkbox",
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.enableVoice,
                                expression: "enableVoice",
                              },
                            ],
                            staticClass: "custom-control-input",
                            attrs: { type: "checkbox", id: "enableVoice" },
                            domProps: {
                              checked: Array.isArray(_vm.enableVoice)
                                ? _vm._i(_vm.enableVoice, null) > -1
                                : _vm.enableVoice,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.enableVoice,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      (_vm.enableVoice = $$a.concat([$$v]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.enableVoice = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.enableVoice = $$c
                                }
                              },
                            },
                          }),
                          _c(
                            "label",
                            {
                              staticClass: "custom-control-label",
                              attrs: { for: "enableVoice" },
                            },
                            [_vm._v("Enable Voice")]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "form-group custom-control custom-checkbox",
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.reattemptsEnabled,
                                expression: "reattemptsEnabled",
                              },
                            ],
                            staticClass: "custom-control-input",
                            attrs: {
                              type: "checkbox",
                              id: "reattemptsEnabled",
                            },
                            domProps: {
                              checked: Array.isArray(_vm.reattemptsEnabled)
                                ? _vm._i(_vm.reattemptsEnabled, null) > -1
                                : _vm.reattemptsEnabled,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.reattemptsEnabled,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      (_vm.reattemptsEnabled = $$a.concat([
                                        $$v,
                                      ]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.reattemptsEnabled = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.reattemptsEnabled = $$c
                                }
                              },
                            },
                          }),
                          _c(
                            "label",
                            {
                              staticClass: "custom-control-label",
                              attrs: { for: "reattemptsEnabled" },
                            },
                            [_vm._v("Enable Reattempts")]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "form-group custom-control custom-checkbox",
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.enableLlmAssessments,
                                expression: "enableLlmAssessments",
                              },
                            ],
                            staticClass: "custom-control-input",
                            attrs: {
                              type: "checkbox",
                              id: "enableLlmAssessments",
                            },
                            domProps: {
                              checked: Array.isArray(_vm.enableLlmAssessments)
                                ? _vm._i(_vm.enableLlmAssessments, null) > -1
                                : _vm.enableLlmAssessments,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.enableLlmAssessments,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      (_vm.enableLlmAssessments = $$a.concat([
                                        $$v,
                                      ]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.enableLlmAssessments = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.enableLlmAssessments = $$c
                                }
                              },
                            },
                          }),
                          _c(
                            "label",
                            {
                              staticClass: "custom-control-label",
                              attrs: { for: "enableLlmAssessments" },
                            },
                            [_vm._v("Enable LLM Assessments")]
                          ),
                        ]
                      ),
                      _vm.reattemptsEnabled
                        ? _c(
                            "div",
                            { staticClass: "form-group custom-control" },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.maxReattempts,
                                    expression: "maxReattempts",
                                  },
                                ],
                                attrs: {
                                  type: "number",
                                  id: "maxReattempts",
                                  placeholder: "Max Reattempts",
                                },
                                domProps: { value: _vm.maxReattempts },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.maxReattempts = $event.target.value
                                  },
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                    ]),
                    _c("div", { staticClass: "col-sm-7" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", [_vm._v("Learner Description")]),
                          _c("ValidationProvider", {
                            attrs: { name: "Description", rules: "required" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ errors }) {
                                    return [
                                      _c("vue-editor", {
                                        class: [
                                          _vm.isActive && errors[0]
                                            ? "form-control invalid"
                                            : "form-control",
                                        ],
                                        attrs: {
                                          id: "Description",
                                          placeholder: "Add a Description",
                                        },
                                        model: {
                                          value: _vm.description,
                                          callback: function ($$v) {
                                            _vm.description = $$v
                                          },
                                          expression: "description",
                                        },
                                      }),
                                      errors[0]
                                        ? _c(
                                            "span",
                                            {
                                              class: [
                                                _vm.isActive ? "invalid" : "",
                                              ],
                                              style: [
                                                _vm.isActive
                                                  ? { display: "block" }
                                                  : { display: "none" },
                                              ],
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  errors[0].replace("The ", "")
                                                )
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              518365344
                            ),
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "col-12 pt-5" }, [
                      _c(
                        "div",
                        { staticClass: "d-flex justify-content-end mb-4" },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "btn primary",
                              attrs: { href: "javascript:void(0);" },
                              on: {
                                click: function ($event) {
                                  return _vm.saveLO("drafted", 1)
                                },
                              },
                            },
                            [_vm._v(" Save ")]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "btn-container justify-content-end" },
                        [
                          _c("div", { staticClass: "delete-btn" }, [
                            _c("div", { staticClass: "delete-box" }, [
                              _c("span", { staticClass: "icon-delete" }),
                            ]),
                            _c(
                              "button",
                              {
                                staticClass: "text",
                                on: {
                                  click: function ($event) {
                                    return _vm.archiveLO("archived")
                                  },
                                },
                              },
                              [_vm._v(" Archive this Learning Object(LO) ")]
                            ),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm.isActiveTab == "kb-setup"
        ? _c("div", { staticClass: "setup-content" }, [
            _vm._m(1),
            _c("div", { staticClass: "knowledge-base-container" }, [
              _c("div", { staticClass: "knowledge-base-item" }, [
                _c("h5", [_vm._v("General Knowledge base")]),
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.generalKnowledgeBase,
                      expression: "generalKnowledgeBase",
                    },
                  ],
                  staticClass: "text-area",
                  domProps: { value: _vm.generalKnowledgeBase },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.generalKnowledgeBase = $event.target.value
                    },
                  },
                }),
              ]),
              _c("div", { staticClass: "knowledge-base-item" }, [
                _c("h5", [_vm._v("Global Narrative Knowledge base")]),
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.narrativeKnowledgeBase,
                      expression: "narrativeKnowledgeBase",
                    },
                  ],
                  staticClass: "text-area narrativeKnowledgeBaseItem",
                  attrs: { placeholder: `Add narrative knowledge base` },
                  domProps: { value: _vm.narrativeKnowledgeBase },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.narrativeKnowledgeBase = $event.target.value
                    },
                  },
                }),
              ]),
            ]),
            _c("div", { staticClass: "kb-btn-container" }, [
              _c(
                "button",
                {
                  staticClass: "btn primary",
                  on: {
                    click: function ($event) {
                      return _vm.saveLO("drafted", 1)
                    },
                  },
                },
                [_vm._v(" Save ")]
              ),
            ]),
          ])
        : _vm._e(),
      _vm.isActiveTab == "prompts-setup"
        ? _c("div", { staticClass: "setup-content" }, [
            _c(
              "div",
              {
                staticClass:
                  "d-flex justify-content-between align-items-center",
              },
              [
                _c("h4", { staticClass: "head-title" }, [
                  _vm._v("Dialogic Questions Setup"),
                ]),
                _vm.isMOAT
                  ? _c("div", { staticClass: "ml-3" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.isMOAT,
                            expression: "isMOAT",
                          },
                        ],
                        staticClass: "form-check-input",
                        attrs: {
                          type: "checkbox",
                          value: "",
                          id: "showLabels",
                        },
                        domProps: {
                          checked: Array.isArray(_vm.isMOAT)
                            ? _vm._i(_vm.isMOAT, "") > -1
                            : _vm.isMOAT,
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.isMOAT,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = "",
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 && (_vm.isMOAT = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.isMOAT = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.isMOAT = $$c
                            }
                          },
                        },
                      }),
                      _c(
                        "label",
                        {
                          staticClass: "form-check-label",
                          attrs: { for: "showLabels" },
                        },
                        [_vm._v(" Show old Dialogic view ")]
                      ),
                    ])
                  : _vm._e(),
              ]
            ),
            _vm.isMOAT
              ? _c("div", { staticClass: "prompts-body" }, [
                  _c("h4", [_vm._v("MOAT Dialogic Questions")]),
                  _c(
                    "ol",
                    _vm._l(
                      _vm.dialogicQuestions,
                      function (dialogicQuestion, index) {
                        return _c("li", { key: index }, [
                          _vm._v(" - " + _vm._s(dialogicQuestion) + " "),
                        ])
                      }
                    ),
                    0
                  ),
                ])
              : _vm._e(),
            _vm.isMOAT
              ? _c("div", { staticClass: "prompts-body" }, [
                  _c("div", { staticClass: "prompts-container" }, [
                    _c("div", { staticClass: "prompts-body" }, [
                      _c(
                        "div",
                        { staticClass: "side-menu" },
                        [
                          _c(
                            "draggable",
                            {
                              on: {
                                start: function ($event) {
                                  _vm.isDragging = true
                                },
                                end: _vm.onEnd,
                              },
                              model: {
                                value: _vm.promptData,
                                callback: function ($$v) {
                                  _vm.promptData = $$v
                                },
                                expression: "promptData",
                              },
                            },
                            _vm._l(_vm.promptData, function (prompt, index) {
                              return _c(
                                "ul",
                                { key: prompt.id, staticClass: "side-prompt" },
                                [
                                  _c(
                                    "li",
                                    {
                                      staticClass: "menu-item",
                                      on: {
                                        click: function ($event) {
                                          return _vm.chooseMOATPrompt(index)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            prompt.title ||
                                              `Prompt: ${prompt.id}`
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            }),
                            0
                          ),
                          _c("hr"),
                        ],
                        1
                      ),
                      _vm.showPrompts
                        ? _c("div", { staticClass: "prompts-edit" }, [
                            _c("div", { staticClass: "edit-body" }, [
                              _c("h4", [
                                _vm._v(
                                  "Editing: " +
                                    _vm._s(
                                      this.chosenPrompt.title || "New Prompt"
                                    )
                                ),
                              ]),
                              _c("form", { staticClass: "kb_form" }, [
                                _c("div", { staticClass: "kb_form--row" }, [
                                  _c(
                                    "div",
                                    { staticClass: "kb_form--item form-group" },
                                    [
                                      _c("label", [_vm._v("Prompt Title")]),
                                      _c("textarea", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.chosenPrompt.title,
                                            expression: "chosenPrompt.title",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          placeholder:
                                            "Input prompt Title here",
                                        },
                                        domProps: {
                                          value: _vm.chosenPrompt.title,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.chosenPrompt,
                                              "title",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "kb_form--item form-group" },
                                    [
                                      _c("label", [_vm._v("Admin Notes")]),
                                      _c("textarea", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.chosenPrompt.admin_notes,
                                            expression:
                                              "chosenPrompt.admin_notes",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          placeholder: "Input Admin Notes here",
                                        },
                                        domProps: {
                                          value: _vm.chosenPrompt.admin_notes,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.chosenPrompt,
                                              "admin_notes",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                    ]
                                  ),
                                ]),
                                _c("div", { staticClass: "kb_form--row" }, [
                                  _c(
                                    "div",
                                    { staticClass: "kb_form--item form-group" },
                                    [
                                      _c("label", [_vm._v("Add Prompt")]),
                                      _c("textarea", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.chosenPrompt.prompt,
                                            expression: "chosenPrompt.prompt",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          placeholder: "Input prompt here",
                                        },
                                        domProps: {
                                          value: _vm.chosenPrompt.prompt,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.chosenPrompt,
                                              "prompt",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "kb_form--item form-group" },
                                    [
                                      _c("label", [
                                        _vm._v(
                                          "Prompt Narrative Knowledge Base"
                                        ),
                                      ]),
                                      _c("textarea", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.chosenPrompt
                                                .narrative_knowledge_base,
                                            expression:
                                              "chosenPrompt.narrative_knowledge_base",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          placeholder:
                                            "Input Prompt Narrative Knowledge Base here",
                                        },
                                        domProps: {
                                          value:
                                            _vm.chosenPrompt
                                              .narrative_knowledge_base,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.chosenPrompt,
                                              "narrative_knowledge_base",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                    ]
                                  ),
                                ]),
                                _c("div", { staticClass: "kb_form--row" }, [
                                  _c(
                                    "div",
                                    { staticClass: "kb_form--item form-group" },
                                    [
                                      _c("label", [_vm._v("Prompt Type")]),
                                      _c(
                                        "select",
                                        {
                                          staticClass: "form-control",
                                          on: {
                                            change: function ($event) {
                                              return _vm.setPassFail($event)
                                            },
                                          },
                                        },
                                        _vm._l(
                                          _vm.optionsPassFail,
                                          function (option) {
                                            return _c(
                                              "option",
                                              {
                                                key: option.id,
                                                domProps: {
                                                  value: option.value,
                                                  selected:
                                                    option.value ==
                                                    _vm.chosenPassFail.value,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(option.text) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "kb_form--item form-group" },
                                    [
                                      _c("label", [
                                        _vm._v(
                                          "Independent / Dependendent Prompt"
                                        ),
                                      ]),
                                      _c(
                                        "select",
                                        {
                                          staticClass: "form-control",
                                          on: {
                                            change: function ($event) {
                                              return _vm.setIndependent($event)
                                            },
                                          },
                                        },
                                        _vm._l(
                                          _vm.optionsIndependentDependent,
                                          function (option) {
                                            return _c(
                                              "option",
                                              {
                                                key: option.id,
                                                domProps: {
                                                  value: option.value,
                                                  selected:
                                                    option.value ===
                                                    _vm.chosenPrompt
                                                      .independent_prompt,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(option.text) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("h4", [_vm._v("Tone/Triggers")]),
                                _c("div", { staticClass: "kb_form--row" }, [
                                  _c(
                                    "div",
                                    { staticClass: "kb_form--item form-group" },
                                    [
                                      _c("label", [_vm._v("Trigger Response")]),
                                      _c(
                                        "select",
                                        {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.chosenPrompt
                                                  .trigger_response,
                                              expression:
                                                "chosenPrompt.trigger_response",
                                            },
                                          ],
                                          ref: "trigger_response",
                                          staticClass: "form-control",
                                          on: {
                                            change: function ($event) {
                                              var $$selectedVal =
                                                Array.prototype.filter
                                                  .call(
                                                    $event.target.options,
                                                    function (o) {
                                                      return o.selected
                                                    }
                                                  )
                                                  .map(function (o) {
                                                    var val =
                                                      "_value" in o
                                                        ? o._value
                                                        : o.value
                                                    return val
                                                  })
                                              _vm.$set(
                                                _vm.chosenPrompt,
                                                "trigger_response",
                                                $event.target.multiple
                                                  ? $$selectedVal
                                                  : $$selectedVal[0]
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "option",
                                            {
                                              attrs: {
                                                disabled: "",
                                                value: "",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " Please select a response "
                                              ),
                                            ]
                                          ),
                                          _c("option", [_vm._v("Hint")]),
                                          _c("option", [_vm._v("Answer")]),
                                          _c("option", [_vm._v("None")]),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "kb_form--item form-group" },
                                    [
                                      _c("label", [_vm._v("Trigger Pass")]),
                                      _c(
                                        "select",
                                        {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.chosenPrompt.trigger_pass,
                                              expression:
                                                "chosenPrompt.trigger_pass",
                                            },
                                          ],
                                          ref: "trigger_pass",
                                          staticClass: "form-control",
                                          on: {
                                            change: function ($event) {
                                              var $$selectedVal =
                                                Array.prototype.filter
                                                  .call(
                                                    $event.target.options,
                                                    function (o) {
                                                      return o.selected
                                                    }
                                                  )
                                                  .map(function (o) {
                                                    var val =
                                                      "_value" in o
                                                        ? o._value
                                                        : o.value
                                                    return val
                                                  })
                                              _vm.$set(
                                                _vm.chosenPrompt,
                                                "trigger_pass",
                                                $event.target.multiple
                                                  ? $$selectedVal
                                                  : $$selectedVal[0]
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "option",
                                            {
                                              attrs: {
                                                disabled: "",
                                                value: "",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " Please select a Pass trigger "
                                              ),
                                            ]
                                          ),
                                          _c("option", [_vm._v("Sadness")]),
                                          _c("option", [_vm._v("Happiness")]),
                                          _c("option", [_vm._v("Fear")]),
                                          _c("option", [_vm._v("Anger")]),
                                          _c("option", [_vm._v("Surprise")]),
                                          _c("option", [_vm._v("None")]),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "kb_form--item form-group" },
                                    [
                                      _c("label", [_vm._v("Trigger Fail")]),
                                      _c(
                                        "select",
                                        {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.chosenPrompt.trigger_fail,
                                              expression:
                                                "chosenPrompt.trigger_fail",
                                            },
                                          ],
                                          ref: "trigger_fail",
                                          staticClass: "form-control",
                                          on: {
                                            change: function ($event) {
                                              var $$selectedVal =
                                                Array.prototype.filter
                                                  .call(
                                                    $event.target.options,
                                                    function (o) {
                                                      return o.selected
                                                    }
                                                  )
                                                  .map(function (o) {
                                                    var val =
                                                      "_value" in o
                                                        ? o._value
                                                        : o.value
                                                    return val
                                                  })
                                              _vm.$set(
                                                _vm.chosenPrompt,
                                                "trigger_fail",
                                                $event.target.multiple
                                                  ? $$selectedVal
                                                  : $$selectedVal[0]
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "option",
                                            {
                                              attrs: {
                                                disabled: "",
                                                value: "",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " Please select a Fail trigger "
                                              ),
                                            ]
                                          ),
                                          _c("option", [_vm._v("Sadness")]),
                                          _c("option", [_vm._v("Happiness")]),
                                          _c("option", [_vm._v("Fear")]),
                                          _c("option", [_vm._v("Anger")]),
                                          _c("option", [_vm._v("Surprise")]),
                                          _c("option", [_vm._v("None")]),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("div", { staticClass: "kb_form--row" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "kb_form--item form-group form-checkbox",
                                    },
                                    [
                                      _c("div", [
                                        _c("label", [
                                          _vm._v(
                                            "Include this prompt in assessment"
                                          ),
                                        ]),
                                        _c("div", [
                                          _c("p", [
                                            _vm._v(
                                              " Checking this option will include this prompt when assessing the entire ELM. "
                                            ),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.chosenPrompt
                                                      .optional_assessment,
                                                  expression:
                                                    "chosenPrompt.optional_assessment",
                                                },
                                              ],
                                              attrs: { type: "checkbox" },
                                              domProps: {
                                                checked: Array.isArray(
                                                  _vm.chosenPrompt
                                                    .optional_assessment
                                                )
                                                  ? _vm._i(
                                                      _vm.chosenPrompt
                                                        .optional_assessment,
                                                      null
                                                    ) > -1
                                                  : _vm.chosenPrompt
                                                      .optional_assessment,
                                              },
                                              on: {
                                                change: function ($event) {
                                                  var $$a =
                                                      _vm.chosenPrompt
                                                        .optional_assessment,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? true
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = null,
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        _vm.$set(
                                                          _vm.chosenPrompt,
                                                          "optional_assessment",
                                                          $$a.concat([$$v])
                                                        )
                                                    } else {
                                                      $$i > -1 &&
                                                        _vm.$set(
                                                          _vm.chosenPrompt,
                                                          "optional_assessment",
                                                          $$a
                                                            .slice(0, $$i)
                                                            .concat(
                                                              $$a.slice($$i + 1)
                                                            )
                                                        )
                                                    }
                                                  } else {
                                                    _vm.$set(
                                                      _vm.chosenPrompt,
                                                      "optional_assessment",
                                                      $$c
                                                    )
                                                  }
                                                },
                                              },
                                            }),
                                          ]),
                                        ]),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "kb_form--item form-group" },
                                    [
                                      _c("label", [
                                        _vm._v("Feedback Request Type"),
                                      ]),
                                      _vm._m(2),
                                      _c(
                                        "select",
                                        {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.chosenPrompt
                                                  .feedback_assessment_type,
                                              expression:
                                                "chosenPrompt.feedback_assessment_type",
                                            },
                                          ],
                                          ref: "feedback_request_type",
                                          staticClass: "form-control",
                                          on: {
                                            change: function ($event) {
                                              var $$selectedVal =
                                                Array.prototype.filter
                                                  .call(
                                                    $event.target.options,
                                                    function (o) {
                                                      return o.selected
                                                    }
                                                  )
                                                  .map(function (o) {
                                                    var val =
                                                      "_value" in o
                                                        ? o._value
                                                        : o.value
                                                    return val
                                                  })
                                              _vm.$set(
                                                _vm.chosenPrompt,
                                                "feedback_assessment_type",
                                                $event.target.multiple
                                                  ? $$selectedVal
                                                  : $$selectedVal[0]
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "option",
                                            {
                                              attrs: {
                                                disabled: "",
                                                value: "",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " Please select a feedback request type "
                                              ),
                                            ]
                                          ),
                                          _c("option", [_vm._v("All")]),
                                          _vm.chosenPrompt.pass_fail === false
                                            ? _c("option", [_vm._v(" Hit ")])
                                            : _vm._e(),
                                          _c("option", [_vm._v("None")]),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("div", { staticClass: "divider" }),
                                _c("div", { staticClass: "kb_form--row" }, [
                                  _vm.chosenPrompt.pass_fail === true
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "kb_form--item form-group",
                                        },
                                        [
                                          _c("label", [
                                            _vm._v("Feedback Request Items"),
                                          ]),
                                          _c("ul", [
                                            _c(
                                              "li",
                                              {
                                                staticClass:
                                                  "kb kb-feedback-request",
                                              },
                                              [
                                                _c("label", [_vm._v("Pass")]),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "kb-feedback-request-input-container",
                                                  },
                                                  [
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm
                                                              .feedback_assessment_pass_data
                                                              .label,
                                                          expression:
                                                            "feedback_assessment_pass_data.label",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "form-control",
                                                      attrs: {
                                                        type: "text",
                                                        placeholder:
                                                          "Input Pass Item Label",
                                                      },
                                                      domProps: {
                                                        value:
                                                          _vm
                                                            .feedback_assessment_pass_data
                                                            .label,
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          )
                                                            return
                                                          _vm.$set(
                                                            _vm.feedback_assessment_pass_data,
                                                            "label",
                                                            $event.target.value
                                                          )
                                                        },
                                                      },
                                                    }),
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm
                                                              .feedback_assessment_pass_data
                                                              .description,
                                                          expression:
                                                            "feedback_assessment_pass_data.description",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "form-control",
                                                      attrs: {
                                                        type: "text",
                                                        placeholder:
                                                          "Input Pass Item Description",
                                                      },
                                                      domProps: {
                                                        value:
                                                          _vm
                                                            .feedback_assessment_pass_data
                                                            .description,
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          )
                                                            return
                                                          _vm.$set(
                                                            _vm.feedback_assessment_pass_data,
                                                            "description",
                                                            $event.target.value
                                                          )
                                                        },
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "li",
                                              {
                                                staticClass:
                                                  "kb kb-feedback-request",
                                              },
                                              [
                                                _c("label", [_vm._v("Fail")]),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "kb-feedback-request-input-container",
                                                  },
                                                  [
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm
                                                              .feedback_assessment_fail_data
                                                              .label,
                                                          expression:
                                                            "feedback_assessment_fail_data.label",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "form-control",
                                                      attrs: {
                                                        type: "text",
                                                        placeholder:
                                                          "Input Fail Item Label",
                                                      },
                                                      domProps: {
                                                        value:
                                                          _vm
                                                            .feedback_assessment_fail_data
                                                            .label,
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          )
                                                            return
                                                          _vm.$set(
                                                            _vm.feedback_assessment_fail_data,
                                                            "label",
                                                            $event.target.value
                                                          )
                                                        },
                                                      },
                                                    }),
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm
                                                              .feedback_assessment_fail_data
                                                              .description,
                                                          expression:
                                                            "feedback_assessment_fail_data.description",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "form-control",
                                                      attrs: {
                                                        type: "text",
                                                        placeholder:
                                                          "Input Fail Item Description",
                                                      },
                                                      domProps: {
                                                        value:
                                                          _vm
                                                            .feedback_assessment_fail_data
                                                            .description,
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          )
                                                            return
                                                          _vm.$set(
                                                            _vm.feedback_assessment_fail_data,
                                                            "description",
                                                            $event.target.value
                                                          )
                                                        },
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.chosenPrompt.pass_fail === false
                                    ? _c(
                                        "div",
                                        {
                                          key: _vm.assessmentItemsKey,
                                          staticClass:
                                            "kb_form--item form-group",
                                        },
                                        [
                                          _c(
                                            "h5",
                                            {
                                              staticClass:
                                                "assessmentFeedbackTitle",
                                            },
                                            [_vm._v(" Feedback Request Items ")]
                                          ),
                                          Object.keys(_vm.assessmentItems)
                                            .length === 0
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "assessment-feedback-empty",
                                                },
                                                [
                                                  _c("p", [
                                                    _vm._v(
                                                      "No feedback request items have been added yet."
                                                    ),
                                                  ]),
                                                ]
                                              )
                                            : _vm._e(),
                                          _c(
                                            "ul",
                                            _vm._l(
                                              _vm.assessmentItems,
                                              function (item, index) {
                                                return _c(
                                                  "li",
                                                  {
                                                    key: index,
                                                    ref: "assessment_items",
                                                    refInFor: true,
                                                    staticClass:
                                                      "kb kb-feedback-request",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "kb-feedback-request-input-container",
                                                      },
                                                      [
                                                        _c("div", [
                                                          _c("label", [
                                                            _vm._v(
                                                              "Assessment Item Label"
                                                            ),
                                                          ]),
                                                          _c("input", {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  item.label,
                                                                expression:
                                                                  "item.label",
                                                              },
                                                            ],
                                                            ref: `assessment-item-label-${index}`,
                                                            refInFor: true,
                                                            staticClass:
                                                              "form-control",
                                                            attrs: {
                                                              type: "text",
                                                              placeholder:
                                                                "Input Assessment Item Label",
                                                            },
                                                            domProps: {
                                                              value: item.label,
                                                            },
                                                            on: {
                                                              input: function (
                                                                $event
                                                              ) {
                                                                if (
                                                                  $event.target
                                                                    .composing
                                                                )
                                                                  return
                                                                _vm.$set(
                                                                  item,
                                                                  "label",
                                                                  $event.target
                                                                    .value
                                                                )
                                                              },
                                                            },
                                                          }),
                                                          _c("label", [
                                                            _vm._v(
                                                              "Assessment Item Description"
                                                            ),
                                                          ]),
                                                          _c("textarea", {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  item.description,
                                                                expression:
                                                                  "item.description",
                                                              },
                                                            ],
                                                            ref: `assessment-item-description-${index}`,
                                                            refInFor: true,
                                                            staticClass:
                                                              "form-control",
                                                            attrs: {
                                                              placeholder:
                                                                "Input Assessment Item Description",
                                                            },
                                                            domProps: {
                                                              value:
                                                                item.description,
                                                            },
                                                            on: {
                                                              input: function (
                                                                $event
                                                              ) {
                                                                if (
                                                                  $event.target
                                                                    .composing
                                                                )
                                                                  return
                                                                _vm.$set(
                                                                  item,
                                                                  "description",
                                                                  $event.target
                                                                    .value
                                                                )
                                                              },
                                                            },
                                                          }),
                                                          _c("label", [
                                                            _vm._v(
                                                              "Item Level"
                                                            ),
                                                          ]),
                                                          _c("input", {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  item.assessment_level,
                                                                expression:
                                                                  "item.assessment_level",
                                                              },
                                                            ],
                                                            ref: `assessment-item-level-${index}`,
                                                            refInFor: true,
                                                            staticClass:
                                                              "form-control",
                                                            attrs: {
                                                              type: "number",
                                                              placeholder:
                                                                "Input Assessment Item Level",
                                                            },
                                                            domProps: {
                                                              value:
                                                                item.assessment_level,
                                                            },
                                                            on: {
                                                              input: function (
                                                                $event
                                                              ) {
                                                                if (
                                                                  $event.target
                                                                    .composing
                                                                )
                                                                  return
                                                                _vm.$set(
                                                                  item,
                                                                  "assessment_level",
                                                                  $event.target
                                                                    .value
                                                                )
                                                              },
                                                            },
                                                          }),
                                                        ]),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "feedback-item-btn-container",
                                                          },
                                                          [
                                                            _c(
                                                              "button",
                                                              {
                                                                ref: `assessment-item-${index}`,
                                                                refInFor: true,
                                                                staticClass:
                                                                  "btn primary",
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.editAssessmentItem(
                                                                        index
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [_vm._v(" Edit ")]
                                                            ),
                                                            _c(
                                                              "button",
                                                              {
                                                                staticClass:
                                                                  "btn delete-kb",
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.deleteAssessmentItem(
                                                                        index
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " Delete "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]),
                                _vm.addingNewAssessmentItem
                                  ? _c("div", [
                                      _c("div", { staticClass: "divider" }),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "form-group new-assessment-item-container",
                                        },
                                        [
                                          _c("p", [
                                            _vm._v("New Feedback Request Item"),
                                          ]),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "new-assessment-form",
                                            },
                                            [
                                              _c("label", [
                                                _vm._v("Assessment Item Label"),
                                              ]),
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: _vm.newItem.label,
                                                    expression: "newItem.label",
                                                  },
                                                ],
                                                staticClass: "form-control",
                                                attrs: {
                                                  type: "text",
                                                  placeholder:
                                                    "Input Assessment Item Label",
                                                },
                                                domProps: {
                                                  value: _vm.newItem.label,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    if ($event.target.composing)
                                                      return
                                                    _vm.$set(
                                                      _vm.newItem,
                                                      "label",
                                                      $event.target.value
                                                    )
                                                  },
                                                },
                                              }),
                                              _c("label", [
                                                _vm._v(
                                                  "Assessment Item Description"
                                                ),
                                              ]),
                                              _c("textarea", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.newItem.description,
                                                    expression:
                                                      "newItem.description",
                                                  },
                                                ],
                                                ref: `new-assessment-item-description`,
                                                staticClass: "form-control",
                                                attrs: {
                                                  placeholder:
                                                    "Input Assessment Item Description",
                                                },
                                                domProps: {
                                                  value:
                                                    _vm.newItem.description,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    if ($event.target.composing)
                                                      return
                                                    _vm.$set(
                                                      _vm.newItem,
                                                      "description",
                                                      $event.target.value
                                                    )
                                                  },
                                                },
                                              }),
                                              _c("label", [
                                                _vm._v("Item Level"),
                                              ]),
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.newItem
                                                        .assessment_level,
                                                    expression:
                                                      "newItem.assessment_level",
                                                  },
                                                ],
                                                ref: `new-assessment-item-level`,
                                                staticClass: "form-control",
                                                attrs: {
                                                  type: "number",
                                                  placeholder:
                                                    "Input Assessment Item Level",
                                                },
                                                domProps: {
                                                  value:
                                                    _vm.newItem
                                                      .assessment_level,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    if ($event.target.composing)
                                                      return
                                                    _vm.$set(
                                                      _vm.newItem,
                                                      "assessment_level",
                                                      $event.target.value
                                                    )
                                                  },
                                                },
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "feedback-item-btn-container",
                                            },
                                            [
                                              _c(
                                                "button",
                                                {
                                                  staticClass: "btn primary",
                                                  on: {
                                                    click: function ($event) {
                                                      $event.preventDefault()
                                                      return _vm.saveAssessmentItem()
                                                    },
                                                  },
                                                },
                                                [_vm._v(" Save ")]
                                              ),
                                              _c(
                                                "button",
                                                {
                                                  staticClass: "btn secondary",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.cancelAssessmentItem()
                                                    },
                                                  },
                                                },
                                                [_vm._v(" Cancel ")]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ])
                                  : _vm._e(),
                                !_vm.chosenPrompt.pass_fail
                                  ? _c(
                                      "h5",
                                      { staticClass: "formItem--title" },
                                      [_vm._v(" Assessment Knowledge Base ")]
                                    )
                                  : _vm._e(),
                                !_vm.chosenPrompt.pass_fail
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "kb_form--item form-group",
                                      },
                                      [
                                        _c("label", [_vm._v("Name")]),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.chosenPrompt
                                                  .assessment_knowledgebase_name,
                                              expression:
                                                "chosenPrompt.assessment_knowledgebase_name",
                                            },
                                          ],
                                          ref: "assessment_knowledgebase_name",
                                          staticClass: "form-control",
                                          attrs: {
                                            type: "text",
                                            placeholder:
                                              "Input Assessment Knowledge Base Name here",
                                          },
                                          domProps: {
                                            value:
                                              _vm.chosenPrompt
                                                .assessment_knowledgebase_name,
                                          },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.$set(
                                                _vm.chosenPrompt,
                                                "assessment_knowledgebase_name",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    )
                                  : _vm._e(),
                                !_vm.chosenPrompt.pass_fail
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "kb_form--item form-group",
                                      },
                                      [
                                        _c("div", [
                                          _c("label", [
                                            _vm._v("Knowledge Base Entries"),
                                          ]),
                                          _c(
                                            "ul",
                                            _vm._l(
                                              _vm.chosenPrompt
                                                .knowledge_base_items,
                                              function (assessment_kb, index) {
                                                return _c(
                                                  "li",
                                                  {
                                                    key: index,
                                                    ref: "kb_entries",
                                                    refInFor: true,
                                                    staticClass: "kb",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        ref: `kbitem-${index}`,
                                                        refInFor: true,
                                                        staticClass: "entry-kb",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              assessment_kb
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                        ]),
                                      ]
                                    )
                                  : _vm._e(),
                                _c(
                                  "div",
                                  { staticClass: "kb_form--item form-group" },
                                  [
                                    _c("label", [_vm._v("Task")]),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.chosenPrompt.task,
                                          expression: "chosenPrompt.task",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        type: "text",
                                        placeholder: "Input task here",
                                      },
                                      domProps: {
                                        value: _vm.chosenPrompt.task,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.chosenPrompt,
                                            "task",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "kb_form--item kb_form--itemhori",
                                  },
                                  [
                                    !_vm.chosenPrompt.pass_fail
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "kb_item--column form-group",
                                          },
                                          [
                                            _c("label", [_vm._v("Threshold")]),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.chosenPrompt.threshold,
                                                  expression:
                                                    "chosenPrompt.threshold",
                                                },
                                              ],
                                              ref: "thresholdRequired",
                                              staticClass: "form-control",
                                              attrs: { type: "text" },
                                              domProps: {
                                                value:
                                                  _vm.chosenPrompt.threshold,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.$set(
                                                    _vm.chosenPrompt,
                                                    "threshold",
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            }),
                                          ]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "kb_item--column form-group",
                                      },
                                      [
                                        _c("label", [_vm._v("Mandatory")]),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.chosenPrompt.mandatory,
                                              expression:
                                                "chosenPrompt.mandatory",
                                            },
                                          ],
                                          staticClass: "form-control",
                                          attrs: { type: "checkbox" },
                                          domProps: {
                                            checked: Array.isArray(
                                              _vm.chosenPrompt.mandatory
                                            )
                                              ? _vm._i(
                                                  _vm.chosenPrompt.mandatory,
                                                  null
                                                ) > -1
                                              : _vm.chosenPrompt.mandatory,
                                          },
                                          on: {
                                            change: function ($event) {
                                              var $$a =
                                                  _vm.chosenPrompt.mandatory,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = null,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    _vm.$set(
                                                      _vm.chosenPrompt,
                                                      "mandatory",
                                                      $$a.concat([$$v])
                                                    )
                                                } else {
                                                  $$i > -1 &&
                                                    _vm.$set(
                                                      _vm.chosenPrompt,
                                                      "mandatory",
                                                      $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        )
                                                    )
                                                }
                                              } else {
                                                _vm.$set(
                                                  _vm.chosenPrompt,
                                                  "mandatory",
                                                  $$c
                                                )
                                              }
                                            },
                                          },
                                        }),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "kb_item--column" },
                                      [
                                        _c("label", [
                                          _vm._v("Question Needed"),
                                        ]),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.chosenPrompt
                                                  .question_needed,
                                              expression:
                                                "chosenPrompt.question_needed",
                                            },
                                          ],
                                          attrs: { type: "checkbox" },
                                          domProps: {
                                            checked: Array.isArray(
                                              _vm.chosenPrompt.question_needed
                                            )
                                              ? _vm._i(
                                                  _vm.chosenPrompt
                                                    .question_needed,
                                                  null
                                                ) > -1
                                              : _vm.chosenPrompt
                                                  .question_needed,
                                          },
                                          on: {
                                            change: function ($event) {
                                              var $$a =
                                                  _vm.chosenPrompt
                                                    .question_needed,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = null,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    _vm.$set(
                                                      _vm.chosenPrompt,
                                                      "question_needed",
                                                      $$a.concat([$$v])
                                                    )
                                                } else {
                                                  $$i > -1 &&
                                                    _vm.$set(
                                                      _vm.chosenPrompt,
                                                      "question_needed",
                                                      $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        )
                                                    )
                                                }
                                              } else {
                                                _vm.$set(
                                                  _vm.chosenPrompt,
                                                  "question_needed",
                                                  $$c
                                                )
                                              }
                                            },
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                ])
              : _vm._e(),
            !_vm.isMOAT && !_vm.enableLlmAssessments
              ? _c("div", { staticClass: "prompts-body" }, [
                  _c("div", { staticClass: "prompts-container" }, [
                    _c("div", { staticClass: "prompts-body" }, [
                      _c(
                        "div",
                        { staticClass: "side-menu" },
                        [
                          _c(
                            "draggable",
                            {
                              on: {
                                start: function ($event) {
                                  _vm.isDragging = true
                                },
                                end: _vm.onEnd,
                              },
                              model: {
                                value: _vm.qaItems,
                                callback: function ($$v) {
                                  _vm.qaItems = $$v
                                },
                                expression: "qaItems",
                              },
                            },
                            _vm._l(_vm.qaItems, function (item, index) {
                              return _c(
                                "ul",
                                { key: index, staticClass: "side-prompt" },
                                [
                                  _c(
                                    "li",
                                    {
                                      staticClass: "d-flex align-items-center",
                                    },
                                    [
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn primary question-button",
                                          on: {
                                            click: function ($event) {
                                              return _vm.chooseQAItem(index)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                item.skill_name ||
                                                  "New Question"
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "button",
                                        {
                                          staticClass: "delete-button",
                                          on: {
                                            click: function ($event) {
                                              return _vm.deleteQuestion(index)
                                            },
                                          },
                                        },
                                        [_vm._v(" X ")]
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            }),
                            0
                          ),
                          _c("hr"),
                          _c(
                            "button",
                            {
                              staticClass: "btn add-question-btn",
                              on: { click: _vm.addQAItem },
                            },
                            [_vm._v(" Add Dialogic Question ")]
                          ),
                        ],
                        1
                      ),
                      _vm.showQuestion
                        ? _c("div", { staticClass: "prompts-edit" }, [
                            _c("div", { staticClass: "edit-body" }, [
                              _c("h4", [
                                _vm._v(
                                  "Editing: " +
                                    _vm._s(
                                      this.chosenQaItem.skill_name ||
                                        "New Question"
                                    )
                                ),
                              ]),
                              _c("form", { staticClass: "kb_form" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "kb_form--item qaitems-container",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        key: _vm.qaItemRefreshKey,
                                        staticClass: "row",
                                      },
                                      [
                                        _vm.qaItems.length > 0
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "qa-items--main form-group col",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex flex-column form-group",
                                                  },
                                                  [
                                                    _c("label", [
                                                      _vm._v("Skill name"),
                                                    ]),
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm.chosenQaItem
                                                              .skill_name,
                                                          expression:
                                                            "chosenQaItem.skill_name",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "form-control",
                                                      attrs: {
                                                        type: "text",
                                                        placeholder:
                                                          "Skill Name",
                                                      },
                                                      domProps: {
                                                        value:
                                                          _vm.chosenQaItem
                                                            .skill_name,
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          )
                                                            return
                                                          _vm.$set(
                                                            _vm.chosenQaItem,
                                                            "skill_name",
                                                            $event.target.value
                                                          )
                                                        },
                                                      },
                                                    }),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex flex-column form-group",
                                                  },
                                                  [
                                                    _c("label", [
                                                      _vm._v("Question"),
                                                    ]),
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm.chosenQaItem
                                                              .question,
                                                          expression:
                                                            "chosenQaItem.question",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "form-control",
                                                      attrs: {
                                                        type: "text",
                                                        placeholder:
                                                          "Enter Question",
                                                      },
                                                      domProps: {
                                                        value:
                                                          _vm.chosenQaItem
                                                            .question,
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          )
                                                            return
                                                          _vm.$set(
                                                            _vm.chosenQaItem,
                                                            "question",
                                                            $event.target.value
                                                          )
                                                        },
                                                      },
                                                    }),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "row flex-column answers-container",
                                                  },
                                                  [
                                                    _c("label", [
                                                      _vm._v("Answers"),
                                                    ]),
                                                    _c(
                                                      "ul",
                                                      _vm._l(
                                                        _vm.chosenQaItem
                                                          .llm_dialogic_question_answers_attributes,
                                                        function (answerItem) {
                                                          return _c(
                                                            "li",
                                                            {
                                                              key: answerItem.id,
                                                              staticClass:
                                                                "row",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "col-10",
                                                                },
                                                                [
                                                                  _c("p", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        answerItem.answer
                                                                      )
                                                                    ),
                                                                  ]),
                                                                ]
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "col-2",
                                                                },
                                                                [
                                                                  _c(
                                                                    "button",
                                                                    {
                                                                      staticClass:
                                                                        "btn",
                                                                      attrs: {
                                                                        type: "button",
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            $event.preventDefault()
                                                                            return _vm.deleteAnswer(
                                                                              answerItem.id
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Delete"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    ),
                                                    _c(
                                                      "div",
                                                      { staticClass: "row" },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "col-10",
                                                          },
                                                          [
                                                            _c("input", {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    _vm.newAnswer,
                                                                  expression:
                                                                    "newAnswer",
                                                                },
                                                              ],
                                                              staticClass:
                                                                "form-control",
                                                              attrs: {
                                                                type: "text",
                                                                placeholder:
                                                                  "Enter New Answer",
                                                              },
                                                              domProps: {
                                                                value:
                                                                  _vm.newAnswer,
                                                              },
                                                              on: {
                                                                input:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      $event
                                                                        .target
                                                                        .composing
                                                                    )
                                                                      return
                                                                    _vm.newAnswer =
                                                                      $event.target.value
                                                                  },
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "col-2",
                                                          },
                                                          [
                                                            _c(
                                                              "button",
                                                              {
                                                                staticClass:
                                                                  "btn",
                                                                attrs: {
                                                                  type: "button",
                                                                },
                                                                on: {
                                                                  click:
                                                                    _vm.addAnswer,
                                                                },
                                                              },
                                                              [_vm._v("Add")]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex row flex-column form-group answers-container",
                                                  },
                                                  [
                                                    _c("label", [
                                                      _vm._v("Assessments"),
                                                    ]),
                                                    _c(
                                                      "ul",
                                                      _vm._l(
                                                        _vm.chosenQaItem
                                                          .llm_dialogic_question_assessments_attributes,
                                                        function (
                                                          assessmentItem,
                                                          index
                                                        ) {
                                                          return _c(
                                                            "li",
                                                            { key: index },
                                                            [
                                                              _c("div", [
                                                                _c("label", [
                                                                  _vm._v(
                                                                    "Label"
                                                                  ),
                                                                ]),
                                                                _c("input", {
                                                                  directives: [
                                                                    {
                                                                      name: "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        assessmentItem.label,
                                                                      expression:
                                                                        "assessmentItem.label",
                                                                    },
                                                                  ],
                                                                  staticClass:
                                                                    "form-control",
                                                                  attrs: {
                                                                    type: "text",
                                                                    placeholder:
                                                                      "Enter Label",
                                                                  },
                                                                  domProps: {
                                                                    value:
                                                                      assessmentItem.label,
                                                                  },
                                                                  on: {
                                                                    input:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        if (
                                                                          $event
                                                                            .target
                                                                            .composing
                                                                        )
                                                                          return
                                                                        _vm.$set(
                                                                          assessmentItem,
                                                                          "label",
                                                                          $event
                                                                            .target
                                                                            .value
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                                _c("label", [
                                                                  _vm._v(
                                                                    "Response"
                                                                  ),
                                                                ]),
                                                                _c("input", {
                                                                  directives: [
                                                                    {
                                                                      name: "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        assessmentItem.response,
                                                                      expression:
                                                                        "assessmentItem.response",
                                                                    },
                                                                  ],
                                                                  staticClass:
                                                                    "form-control",
                                                                  attrs: {
                                                                    type: "text",
                                                                    placeholder:
                                                                      "Enter Response",
                                                                  },
                                                                  domProps: {
                                                                    value:
                                                                      assessmentItem.response,
                                                                  },
                                                                  on: {
                                                                    input:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        if (
                                                                          $event
                                                                            .target
                                                                            .composing
                                                                        )
                                                                          return
                                                                        _vm.$set(
                                                                          assessmentItem,
                                                                          "response",
                                                                          $event
                                                                            .target
                                                                            .value
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                                _c("label", [
                                                                  _vm._v(
                                                                    "Debrief"
                                                                  ),
                                                                ]),
                                                                _c("input", {
                                                                  directives: [
                                                                    {
                                                                      name: "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        assessmentItem.debrief,
                                                                      expression:
                                                                        "assessmentItem.debrief",
                                                                    },
                                                                  ],
                                                                  staticClass:
                                                                    "form-control",
                                                                  attrs: {
                                                                    type: "text",
                                                                    placeholder:
                                                                      "Enter Debrief",
                                                                  },
                                                                  domProps: {
                                                                    value:
                                                                      assessmentItem.debrief,
                                                                  },
                                                                  on: {
                                                                    input:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        if (
                                                                          $event
                                                                            .target
                                                                            .composing
                                                                        )
                                                                          return
                                                                        _vm.$set(
                                                                          assessmentItem,
                                                                          "debrief",
                                                                          $event
                                                                            .target
                                                                            .value
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                                _c("label", [
                                                                  _vm._v(
                                                                    "Follow Up"
                                                                  ),
                                                                ]),
                                                                _c("input", {
                                                                  directives: [
                                                                    {
                                                                      name: "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        assessmentItem.follow_up,
                                                                      expression:
                                                                        "assessmentItem.follow_up",
                                                                    },
                                                                  ],
                                                                  staticClass:
                                                                    "form-control",
                                                                  attrs: {
                                                                    type: "text",
                                                                    placeholder:
                                                                      "Enter Follow up",
                                                                  },
                                                                  domProps: {
                                                                    value:
                                                                      assessmentItem.follow_up,
                                                                  },
                                                                  on: {
                                                                    input:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        if (
                                                                          $event
                                                                            .target
                                                                            .composing
                                                                        )
                                                                          return
                                                                        _vm.$set(
                                                                          assessmentItem,
                                                                          "follow_up",
                                                                          $event
                                                                            .target
                                                                            .value
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                                _c("label", [
                                                                  _vm._v(
                                                                    "Threshold"
                                                                  ),
                                                                ]),
                                                                _c("input", {
                                                                  directives: [
                                                                    {
                                                                      name: "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        assessmentItem.threshold,
                                                                      expression:
                                                                        "assessmentItem.threshold",
                                                                    },
                                                                  ],
                                                                  staticClass:
                                                                    "form-control",
                                                                  attrs: {
                                                                    type: "text",
                                                                    placeholder:
                                                                      "Enter Threshold",
                                                                  },
                                                                  domProps: {
                                                                    value:
                                                                      assessmentItem.threshold,
                                                                  },
                                                                  on: {
                                                                    input:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        if (
                                                                          $event
                                                                            .target
                                                                            .composing
                                                                        )
                                                                          return
                                                                        _vm.$set(
                                                                          assessmentItem,
                                                                          "threshold",
                                                                          $event
                                                                            .target
                                                                            .value
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                              ]),
                                                              _c("div", [
                                                                _c(
                                                                  "button",
                                                                  {
                                                                    staticClass:
                                                                      "btn",
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          $event.preventDefault()
                                                                          return _vm.saveAssessmentItem(
                                                                            index
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Save"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "button",
                                                                  {
                                                                    staticClass:
                                                                      "btn",
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          $event.preventDefault()
                                                                          return _vm.deleteAssessmentItem(
                                                                            index
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Delete"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    ),
                                                    _c(
                                                      "button",
                                                      {
                                                        staticClass: "btn",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.preventDefault()
                                                            return _vm.addAssessmentItem.apply(
                                                              null,
                                                              arguments
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("Add Assessment")]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "row flex-column answers-container",
                                                  },
                                                  [
                                                    _c("h4", [
                                                      _vm._v(
                                                        "Show/Hide Options"
                                                      ),
                                                    ]),
                                                    _c(
                                                      "div",
                                                      { staticClass: "ml-3" },
                                                      [
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                _vm.chosenQaItem
                                                                  .show_labels,
                                                              expression:
                                                                "chosenQaItem.show_labels",
                                                            },
                                                          ],
                                                          staticClass:
                                                            "form-check-input",
                                                          attrs: {
                                                            type: "checkbox",
                                                            value: "",
                                                            id: "showLabels",
                                                          },
                                                          domProps: {
                                                            checked:
                                                              Array.isArray(
                                                                _vm.chosenQaItem
                                                                  .show_labels
                                                              )
                                                                ? _vm._i(
                                                                    _vm
                                                                      .chosenQaItem
                                                                      .show_labels,
                                                                    ""
                                                                  ) > -1
                                                                : _vm
                                                                    .chosenQaItem
                                                                    .show_labels,
                                                          },
                                                          on: {
                                                            change: function (
                                                              $event
                                                            ) {
                                                              var $$a =
                                                                  _vm
                                                                    .chosenQaItem
                                                                    .show_labels,
                                                                $$el =
                                                                  $event.target,
                                                                $$c =
                                                                  $$el.checked
                                                                    ? true
                                                                    : false
                                                              if (
                                                                Array.isArray(
                                                                  $$a
                                                                )
                                                              ) {
                                                                var $$v = "",
                                                                  $$i = _vm._i(
                                                                    $$a,
                                                                    $$v
                                                                  )
                                                                if (
                                                                  $$el.checked
                                                                ) {
                                                                  $$i < 0 &&
                                                                    _vm.$set(
                                                                      _vm.chosenQaItem,
                                                                      "show_labels",
                                                                      $$a.concat(
                                                                        [$$v]
                                                                      )
                                                                    )
                                                                } else {
                                                                  $$i > -1 &&
                                                                    _vm.$set(
                                                                      _vm.chosenQaItem,
                                                                      "show_labels",
                                                                      $$a
                                                                        .slice(
                                                                          0,
                                                                          $$i
                                                                        )
                                                                        .concat(
                                                                          $$a.slice(
                                                                            $$i +
                                                                              1
                                                                          )
                                                                        )
                                                                    )
                                                                }
                                                              } else {
                                                                _vm.$set(
                                                                  _vm.chosenQaItem,
                                                                  "show_labels",
                                                                  $$c
                                                                )
                                                              }
                                                            },
                                                          },
                                                        }),
                                                        _c(
                                                          "label",
                                                          {
                                                            staticClass:
                                                              "form-check-label",
                                                            attrs: {
                                                              for: "showLabels",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " Show Labels "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      { staticClass: "ml-3" },
                                                      [
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                _vm.chosenQaItem
                                                                  .show_responses,
                                                              expression:
                                                                "chosenQaItem.show_responses",
                                                            },
                                                          ],
                                                          staticClass:
                                                            "form-check-input",
                                                          attrs: {
                                                            type: "checkbox",
                                                            value: "",
                                                            id: "showResponses",
                                                          },
                                                          domProps: {
                                                            checked:
                                                              Array.isArray(
                                                                _vm.chosenQaItem
                                                                  .show_responses
                                                              )
                                                                ? _vm._i(
                                                                    _vm
                                                                      .chosenQaItem
                                                                      .show_responses,
                                                                    ""
                                                                  ) > -1
                                                                : _vm
                                                                    .chosenQaItem
                                                                    .show_responses,
                                                          },
                                                          on: {
                                                            change: function (
                                                              $event
                                                            ) {
                                                              var $$a =
                                                                  _vm
                                                                    .chosenQaItem
                                                                    .show_responses,
                                                                $$el =
                                                                  $event.target,
                                                                $$c =
                                                                  $$el.checked
                                                                    ? true
                                                                    : false
                                                              if (
                                                                Array.isArray(
                                                                  $$a
                                                                )
                                                              ) {
                                                                var $$v = "",
                                                                  $$i = _vm._i(
                                                                    $$a,
                                                                    $$v
                                                                  )
                                                                if (
                                                                  $$el.checked
                                                                ) {
                                                                  $$i < 0 &&
                                                                    _vm.$set(
                                                                      _vm.chosenQaItem,
                                                                      "show_responses",
                                                                      $$a.concat(
                                                                        [$$v]
                                                                      )
                                                                    )
                                                                } else {
                                                                  $$i > -1 &&
                                                                    _vm.$set(
                                                                      _vm.chosenQaItem,
                                                                      "show_responses",
                                                                      $$a
                                                                        .slice(
                                                                          0,
                                                                          $$i
                                                                        )
                                                                        .concat(
                                                                          $$a.slice(
                                                                            $$i +
                                                                              1
                                                                          )
                                                                        )
                                                                    )
                                                                }
                                                              } else {
                                                                _vm.$set(
                                                                  _vm.chosenQaItem,
                                                                  "show_responses",
                                                                  $$c
                                                                )
                                                              }
                                                            },
                                                          },
                                                        }),
                                                        _c(
                                                          "label",
                                                          {
                                                            staticClass:
                                                              "form-check-label",
                                                            attrs: {
                                                              for: "showResponses",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " Show Responses "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      { staticClass: "ml-3" },
                                                      [
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                _vm.chosenQaItem
                                                                  .show_debriefs,
                                                              expression:
                                                                "chosenQaItem.show_debriefs",
                                                            },
                                                          ],
                                                          staticClass:
                                                            "form-check-input",
                                                          attrs: {
                                                            type: "checkbox",
                                                            value: "",
                                                            id: "showDebriefs",
                                                          },
                                                          domProps: {
                                                            checked:
                                                              Array.isArray(
                                                                _vm.chosenQaItem
                                                                  .show_debriefs
                                                              )
                                                                ? _vm._i(
                                                                    _vm
                                                                      .chosenQaItem
                                                                      .show_debriefs,
                                                                    ""
                                                                  ) > -1
                                                                : _vm
                                                                    .chosenQaItem
                                                                    .show_debriefs,
                                                          },
                                                          on: {
                                                            change: function (
                                                              $event
                                                            ) {
                                                              var $$a =
                                                                  _vm
                                                                    .chosenQaItem
                                                                    .show_debriefs,
                                                                $$el =
                                                                  $event.target,
                                                                $$c =
                                                                  $$el.checked
                                                                    ? true
                                                                    : false
                                                              if (
                                                                Array.isArray(
                                                                  $$a
                                                                )
                                                              ) {
                                                                var $$v = "",
                                                                  $$i = _vm._i(
                                                                    $$a,
                                                                    $$v
                                                                  )
                                                                if (
                                                                  $$el.checked
                                                                ) {
                                                                  $$i < 0 &&
                                                                    _vm.$set(
                                                                      _vm.chosenQaItem,
                                                                      "show_debriefs",
                                                                      $$a.concat(
                                                                        [$$v]
                                                                      )
                                                                    )
                                                                } else {
                                                                  $$i > -1 &&
                                                                    _vm.$set(
                                                                      _vm.chosenQaItem,
                                                                      "show_debriefs",
                                                                      $$a
                                                                        .slice(
                                                                          0,
                                                                          $$i
                                                                        )
                                                                        .concat(
                                                                          $$a.slice(
                                                                            $$i +
                                                                              1
                                                                          )
                                                                        )
                                                                    )
                                                                }
                                                              } else {
                                                                _vm.$set(
                                                                  _vm.chosenQaItem,
                                                                  "show_debriefs",
                                                                  $$c
                                                                )
                                                              }
                                                            },
                                                          },
                                                        }),
                                                        _c(
                                                          "label",
                                                          {
                                                            staticClass:
                                                              "form-check-label",
                                                            attrs: {
                                                              for: "showDebriefs",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " Show Debriefs "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      { staticClass: "ml-3" },
                                                      [
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                _vm.chosenQaItem
                                                                  .show_follow_up,
                                                              expression:
                                                                "chosenQaItem.show_follow_up",
                                                            },
                                                          ],
                                                          staticClass:
                                                            "form-check-input",
                                                          attrs: {
                                                            type: "checkbox",
                                                            value: "",
                                                            id: "showFollowUps",
                                                          },
                                                          domProps: {
                                                            checked:
                                                              Array.isArray(
                                                                _vm.chosenQaItem
                                                                  .show_follow_up
                                                              )
                                                                ? _vm._i(
                                                                    _vm
                                                                      .chosenQaItem
                                                                      .show_follow_up,
                                                                    ""
                                                                  ) > -1
                                                                : _vm
                                                                    .chosenQaItem
                                                                    .show_follow_up,
                                                          },
                                                          on: {
                                                            change: function (
                                                              $event
                                                            ) {
                                                              var $$a =
                                                                  _vm
                                                                    .chosenQaItem
                                                                    .show_follow_up,
                                                                $$el =
                                                                  $event.target,
                                                                $$c =
                                                                  $$el.checked
                                                                    ? true
                                                                    : false
                                                              if (
                                                                Array.isArray(
                                                                  $$a
                                                                )
                                                              ) {
                                                                var $$v = "",
                                                                  $$i = _vm._i(
                                                                    $$a,
                                                                    $$v
                                                                  )
                                                                if (
                                                                  $$el.checked
                                                                ) {
                                                                  $$i < 0 &&
                                                                    _vm.$set(
                                                                      _vm.chosenQaItem,
                                                                      "show_follow_up",
                                                                      $$a.concat(
                                                                        [$$v]
                                                                      )
                                                                    )
                                                                } else {
                                                                  $$i > -1 &&
                                                                    _vm.$set(
                                                                      _vm.chosenQaItem,
                                                                      "show_follow_up",
                                                                      $$a
                                                                        .slice(
                                                                          0,
                                                                          $$i
                                                                        )
                                                                        .concat(
                                                                          $$a.slice(
                                                                            $$i +
                                                                              1
                                                                          )
                                                                        )
                                                                    )
                                                                }
                                                              } else {
                                                                _vm.$set(
                                                                  _vm.chosenQaItem,
                                                                  "show_follow_up",
                                                                  $$c
                                                                )
                                                              }
                                                            },
                                                          },
                                                        }),
                                                        _c(
                                                          "label",
                                                          {
                                                            staticClass:
                                                              "form-check-label",
                                                            attrs: {
                                                              for: "showFollowUps",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " Show Follow Up "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "row flex-column answers-container",
                                                  },
                                                  [
                                                    _c("h4", [
                                                      _vm._v(
                                                        "Role Play Options"
                                                      ),
                                                    ]),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "section-roleplay",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "form-group-roleplay",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "radio-group-container",
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "form-label-roleplay",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Generative AI Assessment Type"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "radio-group-roleplay",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "input",
                                                                      {
                                                                        directives:
                                                                          [
                                                                            {
                                                                              name: "model",
                                                                              rawName:
                                                                                "v-model",
                                                                              value:
                                                                                _vm
                                                                                  .chosenQaItem
                                                                                  .role_play,
                                                                              expression:
                                                                                "chosenQaItem.role_play",
                                                                            },
                                                                          ],
                                                                        staticClass:
                                                                          "radio-input-roleplay",
                                                                        attrs: {
                                                                          id: "Formative",
                                                                          type: "radio",
                                                                          value:
                                                                            "Formative",
                                                                          name: "conversation",
                                                                        },
                                                                        domProps:
                                                                          {
                                                                            checked:
                                                                              _vm._q(
                                                                                _vm
                                                                                  .chosenQaItem
                                                                                  .role_play,
                                                                                "Formative"
                                                                              ),
                                                                          },
                                                                        on: {
                                                                          change:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.$set(
                                                                                _vm.chosenQaItem,
                                                                                "role_play",
                                                                                "Formative"
                                                                              )
                                                                            },
                                                                        },
                                                                      }
                                                                    ),
                                                                    _c(
                                                                      "label",
                                                                      {
                                                                        staticClass:
                                                                          "radio-label-roleplay",
                                                                        attrs: {
                                                                          for: "Formative",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Formative"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "radio-group",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "input",
                                                                      {
                                                                        directives:
                                                                          [
                                                                            {
                                                                              name: "model",
                                                                              rawName:
                                                                                "v-model",
                                                                              value:
                                                                                _vm
                                                                                  .chosenQaItem
                                                                                  .role_play,
                                                                              expression:
                                                                                "chosenQaItem.role_play",
                                                                            },
                                                                          ],
                                                                        staticClass:
                                                                          "radio-input-roleplay",
                                                                        attrs: {
                                                                          id: "Roleplay",
                                                                          type: "radio",
                                                                          value:
                                                                            "Roleplay",
                                                                          name: "conversation",
                                                                        },
                                                                        domProps:
                                                                          {
                                                                            checked:
                                                                              _vm._q(
                                                                                _vm
                                                                                  .chosenQaItem
                                                                                  .role_play,
                                                                                "Roleplay"
                                                                              ),
                                                                          },
                                                                        on: {
                                                                          change:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.$set(
                                                                                _vm.chosenQaItem,
                                                                                "role_play",
                                                                                "Roleplay"
                                                                              )
                                                                            },
                                                                        },
                                                                      }
                                                                    ),
                                                                    _c(
                                                                      "label",
                                                                      {
                                                                        staticClass:
                                                                          "radio-label-roleplay",
                                                                        attrs: {
                                                                          for: "Roleplay",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Role Play"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "radio-group-container",
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "form-label-roleplay",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Generative AI Hint Type"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "radio-group-roleplay",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "input",
                                                                      {
                                                                        directives:
                                                                          [
                                                                            {
                                                                              name: "model",
                                                                              rawName:
                                                                                "v-model",
                                                                              value:
                                                                                _vm
                                                                                  .chosenQaItem
                                                                                  .hint_type,
                                                                              expression:
                                                                                "chosenQaItem.hint_type",
                                                                            },
                                                                          ],
                                                                        staticClass:
                                                                          "radio-input-roleplay",
                                                                        attrs: {
                                                                          id: "Feedback",
                                                                          type: "radio",
                                                                          value:
                                                                            "Feedback",
                                                                          name: "hint",
                                                                        },
                                                                        domProps:
                                                                          {
                                                                            checked:
                                                                              _vm._q(
                                                                                _vm
                                                                                  .chosenQaItem
                                                                                  .hint_type,
                                                                                "Feedback"
                                                                              ),
                                                                          },
                                                                        on: {
                                                                          change:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.$set(
                                                                                _vm.chosenQaItem,
                                                                                "hint_type",
                                                                                "Feedback"
                                                                              )
                                                                            },
                                                                        },
                                                                      }
                                                                    ),
                                                                    _c(
                                                                      "label",
                                                                      {
                                                                        staticClass:
                                                                          "radio-label-roleplay",
                                                                        attrs: {
                                                                          for: "Feedback",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Feedback"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "radio-group",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "input",
                                                                      {
                                                                        directives:
                                                                          [
                                                                            {
                                                                              name: "model",
                                                                              rawName:
                                                                                "v-model",
                                                                              value:
                                                                                _vm
                                                                                  .chosenQaItem
                                                                                  .hint_type,
                                                                              expression:
                                                                                "chosenQaItem.hint_type",
                                                                            },
                                                                          ],
                                                                        staticClass:
                                                                          "radio-input-roleplay",
                                                                        attrs: {
                                                                          id: "Answer",
                                                                          type: "radio",
                                                                          value:
                                                                            "Answer",
                                                                          name: "hint",
                                                                        },
                                                                        domProps:
                                                                          {
                                                                            checked:
                                                                              _vm._q(
                                                                                _vm
                                                                                  .chosenQaItem
                                                                                  .hint_type,
                                                                                "Answer"
                                                                              ),
                                                                          },
                                                                        on: {
                                                                          change:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.$set(
                                                                                _vm.chosenQaItem,
                                                                                "hint_type",
                                                                                "Answer"
                                                                              )
                                                                            },
                                                                        },
                                                                      }
                                                                    ),
                                                                    _c(
                                                                      "label",
                                                                      {
                                                                        staticClass:
                                                                          "radio-label-roleplay",
                                                                        attrs: {
                                                                          for: "Answer",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Answer"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "radio-group-container",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "form-group",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "label",
                                                                      [
                                                                        _vm._v(
                                                                          "Generative Feedback Type"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "d-flex",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "trigger-item",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "label",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    for: "static_generative_feedback_type",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "Static"
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "input",
                                                                              {
                                                                                directives:
                                                                                  [
                                                                                    {
                                                                                      name: "model",
                                                                                      rawName:
                                                                                        "v-model",
                                                                                      value:
                                                                                        _vm
                                                                                          .chosenQaItem
                                                                                          .generative_feedback_type,
                                                                                      expression:
                                                                                        "chosenQaItem.generative_feedback_type",
                                                                                    },
                                                                                  ],
                                                                                attrs:
                                                                                  {
                                                                                    type: "radio",
                                                                                    id: "static_generative_feedback_type",
                                                                                    name: "static_generative_feedback_type",
                                                                                  },
                                                                                domProps:
                                                                                  {
                                                                                    value:
                                                                                      "static",
                                                                                    checked:
                                                                                      _vm._q(
                                                                                        _vm
                                                                                          .chosenQaItem
                                                                                          .generative_feedback_type,
                                                                                        "static"
                                                                                      ),
                                                                                  },
                                                                                on: {
                                                                                  change:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.$set(
                                                                                        _vm.chosenQaItem,
                                                                                        "generative_feedback_type",
                                                                                        "static"
                                                                                      )
                                                                                    },
                                                                                },
                                                                              }
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "trigger-item",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "label",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    for: "dynamic_generative_feedback_type",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "Dynamic"
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "input",
                                                                              {
                                                                                directives:
                                                                                  [
                                                                                    {
                                                                                      name: "model",
                                                                                      rawName:
                                                                                        "v-model",
                                                                                      value:
                                                                                        _vm
                                                                                          .chosenQaItem
                                                                                          .generative_feedback_type,
                                                                                      expression:
                                                                                        "chosenQaItem.generative_feedback_type",
                                                                                    },
                                                                                  ],
                                                                                attrs:
                                                                                  {
                                                                                    type: "radio",
                                                                                    id: "dynamic_generative_feedback_type",
                                                                                    name: "dynamic_generative_feedback_type",
                                                                                  },
                                                                                domProps:
                                                                                  {
                                                                                    value:
                                                                                      "dynamic",
                                                                                    checked:
                                                                                      _vm._q(
                                                                                        _vm
                                                                                          .chosenQaItem
                                                                                          .generative_feedback_type,
                                                                                        "dynamic"
                                                                                      ),
                                                                                  },
                                                                                on: {
                                                                                  change:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.$set(
                                                                                        _vm.chosenQaItem,
                                                                                        "generative_feedback_type",
                                                                                        "dynamic"
                                                                                      )
                                                                                    },
                                                                                },
                                                                              }
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "label",
                                                              {
                                                                staticClass:
                                                                  "form-label-roleplay",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Penalty"
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._l(
                                                              _vm.chosenQaItem
                                                                .penalty,
                                                              function (
                                                                penalt,
                                                                index
                                                              ) {
                                                                return _c(
                                                                  "div",
                                                                  {
                                                                    key: index,
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "input",
                                                                      {
                                                                        directives:
                                                                          [
                                                                            {
                                                                              name: "model",
                                                                              rawName:
                                                                                "v-model",
                                                                              value:
                                                                                _vm
                                                                                  .chosenQaItem
                                                                                  .penalty[
                                                                                  index
                                                                                ],
                                                                              expression:
                                                                                "chosenQaItem.penalty[index]",
                                                                            },
                                                                          ],
                                                                        staticClass:
                                                                          "form-input-roleplay",
                                                                        attrs: {
                                                                          type: "text",
                                                                        },
                                                                        domProps:
                                                                          {
                                                                            value:
                                                                              _vm
                                                                                .chosenQaItem
                                                                                .penalty[
                                                                                index
                                                                              ],
                                                                          },
                                                                        on: {
                                                                          input:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              if (
                                                                                $event
                                                                                  .target
                                                                                  .composing
                                                                              )
                                                                                return
                                                                              _vm.$set(
                                                                                _vm
                                                                                  .chosenQaItem
                                                                                  .penalty,
                                                                                index,
                                                                                $event
                                                                                  .target
                                                                                  .value
                                                                              )
                                                                            },
                                                                        },
                                                                      }
                                                                    ),
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                          ],
                                                          2
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "form-group-roleplay",
                                                          },
                                                          [
                                                            _c(
                                                              "label",
                                                              {
                                                                staticClass:
                                                                  "form-label-roleplay",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Try Limitation"
                                                                ),
                                                              ]
                                                            ),
                                                            _c("input", {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    _vm
                                                                      .chosenQaItem
                                                                      .try_limitation,
                                                                  expression:
                                                                    "chosenQaItem.try_limitation",
                                                                },
                                                              ],
                                                              staticClass:
                                                                "form-input-roleplay",
                                                              attrs: {
                                                                type: "text",
                                                              },
                                                              domProps: {
                                                                value:
                                                                  _vm
                                                                    .chosenQaItem
                                                                    .try_limitation,
                                                              },
                                                              on: {
                                                                change:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.changePenaltyNumbers()
                                                                  },
                                                                input:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      $event
                                                                        .target
                                                                        .composing
                                                                    )
                                                                      return
                                                                    _vm.$set(
                                                                      _vm.chosenQaItem,
                                                                      "try_limitation",
                                                                      $event
                                                                        .target
                                                                        .value
                                                                    )
                                                                  },
                                                              },
                                                            }),
                                                            _vm.chosenQaItem
                                                              .hint_type ==
                                                            "Answer"
                                                              ? _c(
                                                                  "label",
                                                                  {
                                                                    staticClass:
                                                                      "form-label-roleplay",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Answer Hint "
                                                                    ),
                                                                    _vm._l(
                                                                      _vm
                                                                        .chosenQaItem
                                                                        .answer_hint,
                                                                      function (
                                                                        hint,
                                                                        index
                                                                      ) {
                                                                        return _c(
                                                                          "div",
                                                                          {
                                                                            key: index,
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "input",
                                                                              {
                                                                                directives:
                                                                                  [
                                                                                    {
                                                                                      name: "model",
                                                                                      rawName:
                                                                                        "v-model",
                                                                                      value:
                                                                                        _vm
                                                                                          .chosenQaItem
                                                                                          .answer_hint[
                                                                                          index
                                                                                        ],
                                                                                      expression:
                                                                                        "chosenQaItem.answer_hint[index]",
                                                                                    },
                                                                                  ],
                                                                                staticClass:
                                                                                  "form-input-roleplay",
                                                                                attrs:
                                                                                  {
                                                                                    type: "text",
                                                                                  },
                                                                                domProps:
                                                                                  {
                                                                                    value:
                                                                                      _vm
                                                                                        .chosenQaItem
                                                                                        .answer_hint[
                                                                                        index
                                                                                      ],
                                                                                  },
                                                                                on: {
                                                                                  input:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      if (
                                                                                        $event
                                                                                          .target
                                                                                          .composing
                                                                                      )
                                                                                        return
                                                                                      _vm.$set(
                                                                                        _vm
                                                                                          .chosenQaItem
                                                                                          .answer_hint,
                                                                                        index,
                                                                                        $event
                                                                                          .target
                                                                                          .value
                                                                                      )
                                                                                    },
                                                                                },
                                                                              }
                                                                            ),
                                                                          ]
                                                                        )
                                                                      }
                                                                    ),
                                                                  ],
                                                                  2
                                                                )
                                                              : _vm._e(),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "qa-items--main form-group col",
                                              },
                                              [
                                                _c("p", [
                                                  _vm._v(
                                                    "There are no Q/A Items. Please add one."
                                                  ),
                                                ]),
                                              ]
                                            ),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn primary",
                                    on: { click: _vm.saveQuestion },
                                  },
                                  [_vm._v(" Save Question ")]
                                ),
                              ]),
                            ]),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                ])
              : _vm._e(),
            !_vm.isMOAT && _vm.enableLlmAssessments
              ? _c("div", { staticClass: "prompts-body" }, [
                  _c("div", { staticClass: "prompts-container" }, [
                    _c("div", { staticClass: "prompts-body" }, [
                      _c(
                        "div",
                        { staticClass: "side-menu" },
                        [
                          _c(
                            "draggable",
                            {
                              on: {
                                start: function ($event) {
                                  _vm.isDragging = true
                                },
                                end: _vm.onEnd,
                              },
                              model: {
                                value: _vm.qaItems,
                                callback: function ($$v) {
                                  _vm.qaItems = $$v
                                },
                                expression: "qaItems",
                              },
                            },
                            _vm._l(_vm.qaItems, function (item, index) {
                              return _c(
                                "ul",
                                { key: index, staticClass: "side-prompt" },
                                [
                                  _c(
                                    "li",
                                    {
                                      staticClass: "d-flex align-items-center",
                                    },
                                    [
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn primary question-button",
                                          on: {
                                            click: function ($event) {
                                              return _vm.chooseQAItem(index)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                item.skill_name ||
                                                  "New Question"
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "button",
                                        {
                                          staticClass: "delete-button",
                                          on: {
                                            click: function ($event) {
                                              return _vm.deleteQuestion(index)
                                            },
                                          },
                                        },
                                        [_vm._v(" X ")]
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            }),
                            0
                          ),
                          _c("hr"),
                          _c(
                            "button",
                            {
                              staticClass: "btn add-question-btn",
                              on: { click: _vm.addQAItem },
                            },
                            [_vm._v(" Add Dialogic Question ")]
                          ),
                        ],
                        1
                      ),
                      _vm.showQuestion
                        ? _c("div", { staticClass: "prompts-edit" }, [
                            _c("div", { staticClass: "edit-body" }, [
                              _c("h4", [
                                _vm._v(
                                  "Editing: " +
                                    _vm._s(
                                      this.chosenQaItem.skill_name ||
                                        "New Question"
                                    )
                                ),
                              ]),
                              _c("form", { staticClass: "kb_form" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "kb_form--item qaitems-container",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        key: _vm.qaItemRefreshKey,
                                        staticClass: "row",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "qa-items--main form-group col",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex flex-column form-group",
                                              },
                                              [
                                                _c("label", [
                                                  _vm._v("Question Name"),
                                                ]),
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.chosenQaItem
                                                          .skill_name,
                                                      expression:
                                                        "chosenQaItem.skill_name",
                                                    },
                                                  ],
                                                  staticClass: "form-control",
                                                  attrs: {
                                                    type: "text",
                                                    placeholder:
                                                      "Question Name",
                                                  },
                                                  domProps: {
                                                    value:
                                                      _vm.chosenQaItem
                                                        .skill_name,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      )
                                                        return
                                                      _vm.$set(
                                                        _vm.chosenQaItem,
                                                        "skill_name",
                                                        $event.target.value
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex flex-column form-group",
                                              },
                                              [
                                                _c("label", [
                                                  _vm._v("The Actual Question"),
                                                ]),
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.chosenQaItem
                                                          .question,
                                                      expression:
                                                        "chosenQaItem.question",
                                                    },
                                                  ],
                                                  staticClass: "form-control",
                                                  attrs: {
                                                    type: "text",
                                                    placeholder:
                                                      "Enter The Actual Question",
                                                  },
                                                  domProps: {
                                                    value:
                                                      _vm.chosenQaItem.question,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      )
                                                        return
                                                      _vm.$set(
                                                        _vm.chosenQaItem,
                                                        "question",
                                                        $event.target.value
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "skillSets-body" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "side-menu" },
                                                  [
                                                    _c(
                                                      "draggable",
                                                      {
                                                        on: {
                                                          start: function (
                                                            $event
                                                          ) {
                                                            _vm.isDragging = true
                                                          },
                                                          end: _vm.onEnd,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.skillSetsData,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.skillSetsData =
                                                              $$v
                                                          },
                                                          expression:
                                                            "skillSetsData",
                                                        },
                                                      },
                                                      _vm._l(
                                                        _vm.chosenQaItem
                                                          .llm_skill_set_interaction,
                                                        function (skillItem) {
                                                          return _c(
                                                            "ul",
                                                            {
                                                              key: skillItem.id,
                                                              staticClass:
                                                                "side-skill-sets",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "skill-set-container",
                                                                },
                                                                [
                                                                  _c(
                                                                    "li",
                                                                    {
                                                                      staticClass:
                                                                        "menu-item",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            skillItem.name ||
                                                                              `skillSet: ${skillItem.id}`
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "button",
                                                                    {
                                                                      staticClass:
                                                                        "delete-button",
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            $event.preventDefault()
                                                                            return _vm.deleteSkillSetInteraction(
                                                                              skillItem.id
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " X "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "ul",
                                                                {
                                                                  staticClass:
                                                                    "sub-menu-item",
                                                                },
                                                                _vm._l(
                                                                  skillItem.llm_skill_set_items,
                                                                  function (
                                                                    skillSetItem,
                                                                    skillSetItemIndex
                                                                  ) {
                                                                    return _c(
                                                                      "li",
                                                                      {
                                                                        key: skillSetItemIndex,
                                                                        staticClass:
                                                                          "menu-item",
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.chooseLlmSkillSetItem(
                                                                                skillSetItem
                                                                                  .assessment
                                                                                  .id
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              skillSetItem.skill_name
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    )
                                                                  }
                                                                ),
                                                                0
                                                              ),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    ),
                                                    _c("hr"),
                                                    _c(
                                                      "select",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.skillSetAddId,
                                                            expression:
                                                              "skillSetAddId",
                                                          },
                                                        ],
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            var $$selectedVal =
                                                              Array.prototype.filter
                                                                .call(
                                                                  $event.target
                                                                    .options,
                                                                  function (o) {
                                                                    return o.selected
                                                                  }
                                                                )
                                                                .map(function (
                                                                  o
                                                                ) {
                                                                  var val =
                                                                    "_value" in
                                                                    o
                                                                      ? o._value
                                                                      : o.value
                                                                  return val
                                                                })
                                                            _vm.skillSetAddId =
                                                              $event.target
                                                                .multiple
                                                                ? $$selectedVal
                                                                : $$selectedVal[0]
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "option",
                                                          {
                                                            attrs: {
                                                              value: "",
                                                              default: "",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Select Skill Set"
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._l(
                                                          _vm.allSkillSets,
                                                          function (skillSet) {
                                                            return _c(
                                                              "option",
                                                              {
                                                                key: skillSet.id,
                                                                domProps: {
                                                                  value:
                                                                    skillSet.id,
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      skillSet
                                                                        .attributes
                                                                        .name
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                      ],
                                                      2
                                                    ),
                                                    _c(
                                                      "button",
                                                      {
                                                        staticClass:
                                                          "btn add-skill-sets-btn",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.preventDefault()
                                                            return _vm.addSkillSet.apply(
                                                              null,
                                                              arguments
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " Add Skill Set "
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _vm.showSkillSetAssessment ==
                                                true
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "skillSetss-edit",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "edit-body",
                                                          },
                                                          [
                                                            _c("h4", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  this
                                                                    .chosenskillSets
                                                                    .attributes
                                                                    .title
                                                                )
                                                              ),
                                                            ]),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "row",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "col-md-6",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "form-group",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "label",
                                                                          [
                                                                            _vm._v(
                                                                              "Independent / Dependent"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "d-flex",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "trigger-item",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "label",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        for: "independent",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "Independent"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "input",
                                                                                  {
                                                                                    directives:
                                                                                      [
                                                                                        {
                                                                                          name: "model",
                                                                                          rawName:
                                                                                            "v-model",
                                                                                          value:
                                                                                            _vm
                                                                                              .chosenskillSets
                                                                                              .attributes
                                                                                              .dependency_type,
                                                                                          expression:
                                                                                            "chosenskillSets.attributes.dependency_type",
                                                                                        },
                                                                                      ],
                                                                                    attrs:
                                                                                      {
                                                                                        type: "radio",
                                                                                        id: "independent",
                                                                                        name: "independent",
                                                                                      },
                                                                                    domProps:
                                                                                      {
                                                                                        value:
                                                                                          "independent",
                                                                                        checked:
                                                                                          _vm._q(
                                                                                            _vm
                                                                                              .chosenskillSets
                                                                                              .attributes
                                                                                              .dependency_type,
                                                                                            "independent"
                                                                                          ),
                                                                                      },
                                                                                    on: {
                                                                                      change:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.$set(
                                                                                            _vm
                                                                                              .chosenskillSets
                                                                                              .attributes,
                                                                                            "dependency_type",
                                                                                            "independent"
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                  }
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "trigger-item",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "label",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        for: "dependent",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "Dependent"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "input",
                                                                                  {
                                                                                    directives:
                                                                                      [
                                                                                        {
                                                                                          name: "model",
                                                                                          rawName:
                                                                                            "v-model",
                                                                                          value:
                                                                                            _vm
                                                                                              .chosenskillSets
                                                                                              .attributes
                                                                                              .dependency_type,
                                                                                          expression:
                                                                                            "chosenskillSets.attributes.dependency_type",
                                                                                        },
                                                                                      ],
                                                                                    attrs:
                                                                                      {
                                                                                        type: "radio",
                                                                                        id: "dependent",
                                                                                        name: "dependent",
                                                                                      },
                                                                                    domProps:
                                                                                      {
                                                                                        value:
                                                                                          "dependent",
                                                                                        checked:
                                                                                          _vm._q(
                                                                                            _vm
                                                                                              .chosenskillSets
                                                                                              .attributes
                                                                                              .dependency_type,
                                                                                            "dependent"
                                                                                          ),
                                                                                      },
                                                                                    on: {
                                                                                      change:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.$set(
                                                                                            _vm
                                                                                              .chosenskillSets
                                                                                              .attributes,
                                                                                            "dependency_type",
                                                                                            "dependent"
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                  }
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "col-md-6",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "form-group",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "label",
                                                                          [
                                                                            _vm._v(
                                                                              "Assessment Type"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "d-flex g-3",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "trigger-item",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "label",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        for: "pass/fail",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "Pass/Fail"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "input",
                                                                                  {
                                                                                    directives:
                                                                                      [
                                                                                        {
                                                                                          name: "model",
                                                                                          rawName:
                                                                                            "v-model",
                                                                                          value:
                                                                                            _vm
                                                                                              .chosenskillSets
                                                                                              .attributes
                                                                                              .assessment_type,
                                                                                          expression:
                                                                                            "chosenskillSets.attributes.assessment_type",
                                                                                        },
                                                                                      ],
                                                                                    attrs:
                                                                                      {
                                                                                        type: "radio",
                                                                                        id: "passfail",
                                                                                        name: "passfail",
                                                                                      },
                                                                                    domProps:
                                                                                      {
                                                                                        value:
                                                                                          "passfail",
                                                                                        checked:
                                                                                          _vm._q(
                                                                                            _vm
                                                                                              .chosenskillSets
                                                                                              .attributes
                                                                                              .assessment_type,
                                                                                            "passfail"
                                                                                          ),
                                                                                      },
                                                                                    on: {
                                                                                      change:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.$set(
                                                                                            _vm
                                                                                              .chosenskillSets
                                                                                              .attributes,
                                                                                            "assessment_type",
                                                                                            "passfail"
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                  }
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "trigger-item",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "label",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        for: "thresold",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "Threshold"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "input",
                                                                                  {
                                                                                    directives:
                                                                                      [
                                                                                        {
                                                                                          name: "model",
                                                                                          rawName:
                                                                                            "v-model",
                                                                                          value:
                                                                                            _vm
                                                                                              .chosenskillSets
                                                                                              .attributes
                                                                                              .assessment_type,
                                                                                          expression:
                                                                                            "chosenskillSets.attributes.assessment_type",
                                                                                        },
                                                                                      ],
                                                                                    attrs:
                                                                                      {
                                                                                        type: "radio",
                                                                                        id: "threshold",
                                                                                        name: "threshold",
                                                                                      },
                                                                                    domProps:
                                                                                      {
                                                                                        value:
                                                                                          "threshold",
                                                                                        checked:
                                                                                          _vm._q(
                                                                                            _vm
                                                                                              .chosenskillSets
                                                                                              .attributes
                                                                                              .assessment_type,
                                                                                            "threshold"
                                                                                          ),
                                                                                      },
                                                                                    on: {
                                                                                      change:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.$set(
                                                                                            _vm
                                                                                              .chosenskillSets
                                                                                              .attributes,
                                                                                            "assessment_type",
                                                                                            "threshold"
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                  }
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            _vm.chosenskillSets
                                                              .attributes
                                                              .assessment_type ===
                                                            "passfail"
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "row",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "col-md-6",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "form-group",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "label",
                                                                              [
                                                                                _vm._v(
                                                                                  "Assessment Text"
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "input",
                                                                              {
                                                                                directives:
                                                                                  [
                                                                                    {
                                                                                      name: "model",
                                                                                      rawName:
                                                                                        "v-model",
                                                                                      value:
                                                                                        _vm
                                                                                          .chosenskillSets
                                                                                          .attributes
                                                                                          .assessment_text,
                                                                                      expression:
                                                                                        "chosenskillSets.attributes.assessment_text",
                                                                                    },
                                                                                  ],
                                                                                staticClass:
                                                                                  "form-control",
                                                                                attrs:
                                                                                  {
                                                                                    type: "textarea",
                                                                                  },
                                                                                domProps:
                                                                                  {
                                                                                    value:
                                                                                      _vm
                                                                                        .chosenskillSets
                                                                                        .attributes
                                                                                        .assessment_text,
                                                                                  },
                                                                                on: {
                                                                                  input:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      if (
                                                                                        $event
                                                                                          .target
                                                                                          .composing
                                                                                      )
                                                                                        return
                                                                                      _vm.$set(
                                                                                        _vm
                                                                                          .chosenskillSets
                                                                                          .attributes,
                                                                                        "assessment_text",
                                                                                        $event
                                                                                          .target
                                                                                          .value
                                                                                      )
                                                                                    },
                                                                                },
                                                                              }
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "row",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "col-md-6",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "form-group",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "label",
                                                                          [
                                                                            _vm._v(
                                                                              "Trigger Response"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "d-flex justify-content-between",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "trigger-item",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "label",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "Hint"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "input",
                                                                                  {
                                                                                    directives:
                                                                                      [
                                                                                        {
                                                                                          name: "model",
                                                                                          rawName:
                                                                                            "v-model",
                                                                                          value:
                                                                                            _vm
                                                                                              .chosenskillSets
                                                                                              .attributes
                                                                                              .trigger_response,
                                                                                          expression:
                                                                                            "chosenskillSets.attributes.trigger_response",
                                                                                        },
                                                                                      ],
                                                                                    attrs:
                                                                                      {
                                                                                        type: "radio",
                                                                                      },
                                                                                    domProps:
                                                                                      {
                                                                                        value:
                                                                                          "hint",
                                                                                        checked:
                                                                                          _vm._q(
                                                                                            _vm
                                                                                              .chosenskillSets
                                                                                              .attributes
                                                                                              .trigger_response,
                                                                                            "hint"
                                                                                          ),
                                                                                      },
                                                                                    on: {
                                                                                      change:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.$set(
                                                                                            _vm
                                                                                              .chosenskillSets
                                                                                              .attributes,
                                                                                            "trigger_response",
                                                                                            "hint"
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                  }
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "trigger-item",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "label",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "Answer"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "input",
                                                                                  {
                                                                                    directives:
                                                                                      [
                                                                                        {
                                                                                          name: "model",
                                                                                          rawName:
                                                                                            "v-model",
                                                                                          value:
                                                                                            _vm
                                                                                              .chosenskillSets
                                                                                              .attributes
                                                                                              .trigger_response,
                                                                                          expression:
                                                                                            "chosenskillSets.attributes.trigger_response",
                                                                                        },
                                                                                      ],
                                                                                    attrs:
                                                                                      {
                                                                                        type: "radio",
                                                                                      },
                                                                                    domProps:
                                                                                      {
                                                                                        value:
                                                                                          "answer",
                                                                                        checked:
                                                                                          _vm._q(
                                                                                            _vm
                                                                                              .chosenskillSets
                                                                                              .attributes
                                                                                              .trigger_response,
                                                                                            "answer"
                                                                                          ),
                                                                                      },
                                                                                    on: {
                                                                                      change:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.$set(
                                                                                            _vm
                                                                                              .chosenskillSets
                                                                                              .attributes,
                                                                                            "trigger_response",
                                                                                            "answer"
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                  }
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "trigger-item",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "label",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "None"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "input",
                                                                                  {
                                                                                    directives:
                                                                                      [
                                                                                        {
                                                                                          name: "model",
                                                                                          rawName:
                                                                                            "v-model",
                                                                                          value:
                                                                                            _vm
                                                                                              .chosenskillSets
                                                                                              .attributes
                                                                                              .trigger_response,
                                                                                          expression:
                                                                                            "chosenskillSets.attributes.trigger_response",
                                                                                        },
                                                                                      ],
                                                                                    attrs:
                                                                                      {
                                                                                        type: "radio",
                                                                                      },
                                                                                    domProps:
                                                                                      {
                                                                                        value:
                                                                                          "none",
                                                                                        checked:
                                                                                          _vm._q(
                                                                                            _vm
                                                                                              .chosenskillSets
                                                                                              .attributes
                                                                                              .trigger_response,
                                                                                            "none"
                                                                                          ),
                                                                                      },
                                                                                    on: {
                                                                                      change:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.$set(
                                                                                            _vm
                                                                                              .chosenskillSets
                                                                                              .attributes,
                                                                                            "trigger_response",
                                                                                            "none"
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                  }
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "row",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "col-md-6",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "form-group",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "label",
                                                                          [
                                                                            _vm._v(
                                                                              "Trigger Pass"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "d-flex justify-content-between",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "trigger-item",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "label",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "Sadness"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "input",
                                                                                  {
                                                                                    directives:
                                                                                      [
                                                                                        {
                                                                                          name: "model",
                                                                                          rawName:
                                                                                            "v-model",
                                                                                          value:
                                                                                            _vm
                                                                                              .chosenskillSets
                                                                                              .attributes
                                                                                              .trigger_pass,
                                                                                          expression:
                                                                                            "chosenskillSets.attributes.trigger_pass",
                                                                                        },
                                                                                      ],
                                                                                    attrs:
                                                                                      {
                                                                                        type: "radio",
                                                                                      },
                                                                                    domProps:
                                                                                      {
                                                                                        value:
                                                                                          "sadness",
                                                                                        checked:
                                                                                          _vm._q(
                                                                                            _vm
                                                                                              .chosenskillSets
                                                                                              .attributes
                                                                                              .trigger_pass,
                                                                                            "sadness"
                                                                                          ),
                                                                                      },
                                                                                    on: {
                                                                                      change:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.$set(
                                                                                            _vm
                                                                                              .chosenskillSets
                                                                                              .attributes,
                                                                                            "trigger_pass",
                                                                                            "sadness"
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                  }
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "trigger-item",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "label",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "Happiness"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "input",
                                                                                  {
                                                                                    directives:
                                                                                      [
                                                                                        {
                                                                                          name: "model",
                                                                                          rawName:
                                                                                            "v-model",
                                                                                          value:
                                                                                            _vm
                                                                                              .chosenskillSets
                                                                                              .attributes
                                                                                              .trigger_pass,
                                                                                          expression:
                                                                                            "chosenskillSets.attributes.trigger_pass",
                                                                                        },
                                                                                      ],
                                                                                    attrs:
                                                                                      {
                                                                                        type: "radio",
                                                                                      },
                                                                                    domProps:
                                                                                      {
                                                                                        value:
                                                                                          "happiness",
                                                                                        checked:
                                                                                          _vm._q(
                                                                                            _vm
                                                                                              .chosenskillSets
                                                                                              .attributes
                                                                                              .trigger_pass,
                                                                                            "happiness"
                                                                                          ),
                                                                                      },
                                                                                    on: {
                                                                                      change:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.$set(
                                                                                            _vm
                                                                                              .chosenskillSets
                                                                                              .attributes,
                                                                                            "trigger_pass",
                                                                                            "happiness"
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                  }
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "trigger-item",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "label",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "Fear"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "input",
                                                                                  {
                                                                                    directives:
                                                                                      [
                                                                                        {
                                                                                          name: "model",
                                                                                          rawName:
                                                                                            "v-model",
                                                                                          value:
                                                                                            _vm
                                                                                              .chosenskillSets
                                                                                              .attributes
                                                                                              .trigger_pass,
                                                                                          expression:
                                                                                            "chosenskillSets.attributes.trigger_pass",
                                                                                        },
                                                                                      ],
                                                                                    attrs:
                                                                                      {
                                                                                        type: "radio",
                                                                                      },
                                                                                    domProps:
                                                                                      {
                                                                                        value:
                                                                                          "fear",
                                                                                        checked:
                                                                                          _vm._q(
                                                                                            _vm
                                                                                              .chosenskillSets
                                                                                              .attributes
                                                                                              .trigger_pass,
                                                                                            "fear"
                                                                                          ),
                                                                                      },
                                                                                    on: {
                                                                                      change:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.$set(
                                                                                            _vm
                                                                                              .chosenskillSets
                                                                                              .attributes,
                                                                                            "trigger_pass",
                                                                                            "fear"
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                  }
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "trigger-item",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "label",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "Surprise"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "input",
                                                                                  {
                                                                                    directives:
                                                                                      [
                                                                                        {
                                                                                          name: "model",
                                                                                          rawName:
                                                                                            "v-model",
                                                                                          value:
                                                                                            _vm
                                                                                              .chosenskillSets
                                                                                              .attributes
                                                                                              .trigger_pass,
                                                                                          expression:
                                                                                            "chosenskillSets.attributes.trigger_pass",
                                                                                        },
                                                                                      ],
                                                                                    attrs:
                                                                                      {
                                                                                        type: "radio",
                                                                                      },
                                                                                    domProps:
                                                                                      {
                                                                                        value:
                                                                                          "surprise",
                                                                                        checked:
                                                                                          _vm._q(
                                                                                            _vm
                                                                                              .chosenskillSets
                                                                                              .attributes
                                                                                              .trigger_pass,
                                                                                            "surprise"
                                                                                          ),
                                                                                      },
                                                                                    on: {
                                                                                      change:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.$set(
                                                                                            _vm
                                                                                              .chosenskillSets
                                                                                              .attributes,
                                                                                            "trigger_pass",
                                                                                            "surprise"
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                  }
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "trigger-item",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "label",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "None"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "input",
                                                                                  {
                                                                                    directives:
                                                                                      [
                                                                                        {
                                                                                          name: "model",
                                                                                          rawName:
                                                                                            "v-model",
                                                                                          value:
                                                                                            _vm
                                                                                              .chosenskillSets
                                                                                              .attributes
                                                                                              .trigger_pass,
                                                                                          expression:
                                                                                            "chosenskillSets.attributes.trigger_pass",
                                                                                        },
                                                                                      ],
                                                                                    attrs:
                                                                                      {
                                                                                        type: "radio",
                                                                                      },
                                                                                    domProps:
                                                                                      {
                                                                                        value:
                                                                                          "none",
                                                                                        checked:
                                                                                          _vm._q(
                                                                                            _vm
                                                                                              .chosenskillSets
                                                                                              .attributes
                                                                                              .trigger_pass,
                                                                                            "none"
                                                                                          ),
                                                                                      },
                                                                                    on: {
                                                                                      change:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.$set(
                                                                                            _vm
                                                                                              .chosenskillSets
                                                                                              .attributes,
                                                                                            "trigger_pass",
                                                                                            "none"
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                  }
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "row",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "col-md-6",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "form-group",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "label",
                                                                          [
                                                                            _vm._v(
                                                                              "Trigger Fail"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "d-flex justify-content-between",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "trigger-item",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "label",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "Sadness"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "input",
                                                                                  {
                                                                                    directives:
                                                                                      [
                                                                                        {
                                                                                          name: "model",
                                                                                          rawName:
                                                                                            "v-model",
                                                                                          value:
                                                                                            _vm
                                                                                              .chosenskillSets
                                                                                              .attributes
                                                                                              .trigger_fail,
                                                                                          expression:
                                                                                            "chosenskillSets.attributes.trigger_fail",
                                                                                        },
                                                                                      ],
                                                                                    attrs:
                                                                                      {
                                                                                        type: "radio",
                                                                                      },
                                                                                    domProps:
                                                                                      {
                                                                                        value:
                                                                                          "sadness",
                                                                                        checked:
                                                                                          _vm._q(
                                                                                            _vm
                                                                                              .chosenskillSets
                                                                                              .attributes
                                                                                              .trigger_fail,
                                                                                            "sadness"
                                                                                          ),
                                                                                      },
                                                                                    on: {
                                                                                      change:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.$set(
                                                                                            _vm
                                                                                              .chosenskillSets
                                                                                              .attributes,
                                                                                            "trigger_fail",
                                                                                            "sadness"
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                  }
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "trigger-item",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "label",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "Happiness"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "input",
                                                                                  {
                                                                                    directives:
                                                                                      [
                                                                                        {
                                                                                          name: "model",
                                                                                          rawName:
                                                                                            "v-model",
                                                                                          value:
                                                                                            _vm
                                                                                              .chosenskillSets
                                                                                              .attributes
                                                                                              .trigger_fail,
                                                                                          expression:
                                                                                            "chosenskillSets.attributes.trigger_fail",
                                                                                        },
                                                                                      ],
                                                                                    attrs:
                                                                                      {
                                                                                        type: "radio",
                                                                                      },
                                                                                    domProps:
                                                                                      {
                                                                                        value:
                                                                                          "happiness",
                                                                                        checked:
                                                                                          _vm._q(
                                                                                            _vm
                                                                                              .chosenskillSets
                                                                                              .attributes
                                                                                              .trigger_fail,
                                                                                            "happiness"
                                                                                          ),
                                                                                      },
                                                                                    on: {
                                                                                      change:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.$set(
                                                                                            _vm
                                                                                              .chosenskillSets
                                                                                              .attributes,
                                                                                            "trigger_fail",
                                                                                            "happiness"
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                  }
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "trigger-item",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "label",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "Fear"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "input",
                                                                                  {
                                                                                    directives:
                                                                                      [
                                                                                        {
                                                                                          name: "model",
                                                                                          rawName:
                                                                                            "v-model",
                                                                                          value:
                                                                                            _vm
                                                                                              .chosenskillSets
                                                                                              .attributes
                                                                                              .trigger_fail,
                                                                                          expression:
                                                                                            "chosenskillSets.attributes.trigger_fail",
                                                                                        },
                                                                                      ],
                                                                                    attrs:
                                                                                      {
                                                                                        type: "radio",
                                                                                      },
                                                                                    domProps:
                                                                                      {
                                                                                        value:
                                                                                          "fear",
                                                                                        checked:
                                                                                          _vm._q(
                                                                                            _vm
                                                                                              .chosenskillSets
                                                                                              .attributes
                                                                                              .trigger_fail,
                                                                                            "fear"
                                                                                          ),
                                                                                      },
                                                                                    on: {
                                                                                      change:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.$set(
                                                                                            _vm
                                                                                              .chosenskillSets
                                                                                              .attributes,
                                                                                            "trigger_fail",
                                                                                            "fear"
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                  }
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "trigger-item",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "label",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "Surprise"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "input",
                                                                                  {
                                                                                    directives:
                                                                                      [
                                                                                        {
                                                                                          name: "model",
                                                                                          rawName:
                                                                                            "v-model",
                                                                                          value:
                                                                                            _vm
                                                                                              .chosenskillSets
                                                                                              .attributes
                                                                                              .trigger_fail,
                                                                                          expression:
                                                                                            "chosenskillSets.attributes.trigger_fail",
                                                                                        },
                                                                                      ],
                                                                                    attrs:
                                                                                      {
                                                                                        type: "radio",
                                                                                      },
                                                                                    domProps:
                                                                                      {
                                                                                        value:
                                                                                          "surprise",
                                                                                        checked:
                                                                                          _vm._q(
                                                                                            _vm
                                                                                              .chosenskillSets
                                                                                              .attributes
                                                                                              .trigger_fail,
                                                                                            "surprise"
                                                                                          ),
                                                                                      },
                                                                                    on: {
                                                                                      change:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.$set(
                                                                                            _vm
                                                                                              .chosenskillSets
                                                                                              .attributes,
                                                                                            "trigger_fail",
                                                                                            "surprise"
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                  }
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "trigger-item",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "label",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "None"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "input",
                                                                                  {
                                                                                    directives:
                                                                                      [
                                                                                        {
                                                                                          name: "model",
                                                                                          rawName:
                                                                                            "v-model",
                                                                                          value:
                                                                                            _vm
                                                                                              .chosenskillSets
                                                                                              .attributes
                                                                                              .trigger_fail,
                                                                                          expression:
                                                                                            "chosenskillSets.attributes.trigger_fail",
                                                                                        },
                                                                                      ],
                                                                                    attrs:
                                                                                      {
                                                                                        type: "radio",
                                                                                      },
                                                                                    domProps:
                                                                                      {
                                                                                        value:
                                                                                          "none",
                                                                                        checked:
                                                                                          _vm._q(
                                                                                            _vm
                                                                                              .chosenskillSets
                                                                                              .attributes
                                                                                              .trigger_fail,
                                                                                            "none"
                                                                                          ),
                                                                                      },
                                                                                    on: {
                                                                                      change:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.$set(
                                                                                            _vm
                                                                                              .chosenskillSets
                                                                                              .attributes,
                                                                                            "trigger_fail",
                                                                                            "none"
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                  }
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "row",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "col-md-12",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "form-group",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "label",
                                                                          [
                                                                            _vm._v(
                                                                              "Assessment Narrative Knowledge Base"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "textarea",
                                                                          {
                                                                            directives:
                                                                              [
                                                                                {
                                                                                  name: "model",
                                                                                  rawName:
                                                                                    "v-model",
                                                                                  value:
                                                                                    _vm
                                                                                      .chosenskillSets
                                                                                      .attributes
                                                                                      .narrative_base,
                                                                                  expression:
                                                                                    "chosenskillSets.attributes.narrative_base",
                                                                                },
                                                                              ],
                                                                            staticClass:
                                                                              "form-control",
                                                                            attrs:
                                                                              {
                                                                                rows: "3",
                                                                              },
                                                                            domProps:
                                                                              {
                                                                                value:
                                                                                  _vm
                                                                                    .chosenskillSets
                                                                                    .attributes
                                                                                    .narrative_base,
                                                                              },
                                                                            on: {
                                                                              input:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  if (
                                                                                    $event
                                                                                      .target
                                                                                      .composing
                                                                                  )
                                                                                    return
                                                                                  _vm.$set(
                                                                                    _vm
                                                                                      .chosenskillSets
                                                                                      .attributes,
                                                                                    "narrative_base",
                                                                                    $event
                                                                                      .target
                                                                                      .value
                                                                                  )
                                                                                },
                                                                            },
                                                                          }
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "row",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "col-md-12",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "form-group",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "label",
                                                                          [
                                                                            _vm._v(
                                                                              "Include this Assessment in interaction"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "label",
                                                                          [
                                                                            _vm._v(
                                                                              "Checking this option will include this Assessent when assessing the answer during the interaction"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "d-flex",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "trigger-item",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "label",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        for: "yes",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "Yes"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "input",
                                                                                  {
                                                                                    directives:
                                                                                      [
                                                                                        {
                                                                                          name: "model",
                                                                                          rawName:
                                                                                            "v-model",
                                                                                          value:
                                                                                            _vm
                                                                                              .chosenskillSets
                                                                                              .attributes
                                                                                              .include_in_assess,
                                                                                          expression:
                                                                                            "chosenskillSets.attributes.include_in_assess",
                                                                                        },
                                                                                      ],
                                                                                    attrs:
                                                                                      {
                                                                                        type: "radio",
                                                                                        id: "yes",
                                                                                        name: "include_in_interaction",
                                                                                      },
                                                                                    domProps:
                                                                                      {
                                                                                        value: true,
                                                                                        checked:
                                                                                          _vm._q(
                                                                                            _vm
                                                                                              .chosenskillSets
                                                                                              .attributes
                                                                                              .include_in_assess,
                                                                                            true
                                                                                          ),
                                                                                      },
                                                                                    on: {
                                                                                      change:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.$set(
                                                                                            _vm
                                                                                              .chosenskillSets
                                                                                              .attributes,
                                                                                            "include_in_assess",
                                                                                            true
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                  }
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "div",
                                                                              [
                                                                                _c(
                                                                                  "label",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        for: "no",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "No"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "input",
                                                                                  {
                                                                                    directives:
                                                                                      [
                                                                                        {
                                                                                          name: "model",
                                                                                          rawName:
                                                                                            "v-model",
                                                                                          value:
                                                                                            _vm
                                                                                              .chosenskillSets
                                                                                              .attributes
                                                                                              .include_in_assess,
                                                                                          expression:
                                                                                            "chosenskillSets.attributes.include_in_assess",
                                                                                        },
                                                                                      ],
                                                                                    attrs:
                                                                                      {
                                                                                        type: "radio",
                                                                                        id: "no",
                                                                                        name: "include_in_interaction",
                                                                                      },
                                                                                    domProps:
                                                                                      {
                                                                                        value: false,
                                                                                        checked:
                                                                                          _vm._q(
                                                                                            _vm
                                                                                              .chosenskillSets
                                                                                              .attributes
                                                                                              .include_in_assess,
                                                                                            false
                                                                                          ),
                                                                                      },
                                                                                    on: {
                                                                                      change:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.$set(
                                                                                            _vm
                                                                                              .chosenskillSets
                                                                                              .attributes,
                                                                                            "include_in_assess",
                                                                                            false
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                  }
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "row",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "col-md-4",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "form-group",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "label",
                                                                          [
                                                                            _vm._v(
                                                                              "Include this Assessment in the feedback?"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "label",
                                                                          [
                                                                            _vm._v(
                                                                              "Checking this option will include this Assessment in the feedback"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "d-flex",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "trigger-item",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "label",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        for: "yes",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "Yes"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "input",
                                                                                  {
                                                                                    directives:
                                                                                      [
                                                                                        {
                                                                                          name: "model",
                                                                                          rawName:
                                                                                            "v-model",
                                                                                          value:
                                                                                            _vm
                                                                                              .chosenskillSets
                                                                                              .attributes
                                                                                              .mandatory,
                                                                                          expression:
                                                                                            "chosenskillSets.attributes.mandatory",
                                                                                        },
                                                                                      ],
                                                                                    attrs:
                                                                                      {
                                                                                        type: "radio",
                                                                                        id: "yes",
                                                                                        name: "mandatory",
                                                                                      },
                                                                                    domProps:
                                                                                      {
                                                                                        value: true,
                                                                                        checked:
                                                                                          _vm._q(
                                                                                            _vm
                                                                                              .chosenskillSets
                                                                                              .attributes
                                                                                              .mandatory,
                                                                                            true
                                                                                          ),
                                                                                      },
                                                                                    on: {
                                                                                      change:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.$set(
                                                                                            _vm
                                                                                              .chosenskillSets
                                                                                              .attributes,
                                                                                            "mandatory",
                                                                                            true
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                  }
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "trigger-item",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "label",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        for: "no",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "No"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "input",
                                                                                  {
                                                                                    directives:
                                                                                      [
                                                                                        {
                                                                                          name: "model",
                                                                                          rawName:
                                                                                            "v-model",
                                                                                          value:
                                                                                            _vm
                                                                                              .chosenskillSets
                                                                                              .attributes
                                                                                              .mandatory,
                                                                                          expression:
                                                                                            "chosenskillSets.attributes.mandatory",
                                                                                        },
                                                                                      ],
                                                                                    attrs:
                                                                                      {
                                                                                        type: "radio",
                                                                                        id: "no",
                                                                                        name: "mandatory",
                                                                                      },
                                                                                    domProps:
                                                                                      {
                                                                                        value: false,
                                                                                        checked:
                                                                                          _vm._q(
                                                                                            _vm
                                                                                              .chosenskillSets
                                                                                              .attributes
                                                                                              .mandatory,
                                                                                            false
                                                                                          ),
                                                                                      },
                                                                                    on: {
                                                                                      change:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.$set(
                                                                                            _vm
                                                                                              .chosenskillSets
                                                                                              .attributes,
                                                                                            "mandatory",
                                                                                            false
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                  }
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "col-md-4",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "form-group",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "label",
                                                                          [
                                                                            _vm._v(
                                                                              "Show points on assessment"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "d-flex",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "trigger-item",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "label",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        for: "yes",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "Yes"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "input",
                                                                                  {
                                                                                    directives:
                                                                                      [
                                                                                        {
                                                                                          name: "model",
                                                                                          rawName:
                                                                                            "v-model",
                                                                                          value:
                                                                                            _vm
                                                                                              .chosenskillSets
                                                                                              .attributes
                                                                                              .show_points,
                                                                                          expression:
                                                                                            "chosenskillSets.attributes.show_points",
                                                                                        },
                                                                                      ],
                                                                                    attrs:
                                                                                      {
                                                                                        type: "radio",
                                                                                        id: "yes",
                                                                                        name: "show_points",
                                                                                      },
                                                                                    domProps:
                                                                                      {
                                                                                        value: true,
                                                                                        checked:
                                                                                          _vm._q(
                                                                                            _vm
                                                                                              .chosenskillSets
                                                                                              .attributes
                                                                                              .show_points,
                                                                                            true
                                                                                          ),
                                                                                      },
                                                                                    on: {
                                                                                      change:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.$set(
                                                                                            _vm
                                                                                              .chosenskillSets
                                                                                              .attributes,
                                                                                            "show_points",
                                                                                            true
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                  }
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "trigger-item",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "label",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        for: "no",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "No"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "input",
                                                                                  {
                                                                                    directives:
                                                                                      [
                                                                                        {
                                                                                          name: "model",
                                                                                          rawName:
                                                                                            "v-model",
                                                                                          value:
                                                                                            _vm
                                                                                              .chosenskillSets
                                                                                              .attributes
                                                                                              .show_points,
                                                                                          expression:
                                                                                            "chosenskillSets.attributes.show_points",
                                                                                        },
                                                                                      ],
                                                                                    attrs:
                                                                                      {
                                                                                        type: "radio",
                                                                                        id: "no",
                                                                                        name: "show_points",
                                                                                      },
                                                                                    domProps:
                                                                                      {
                                                                                        value: false,
                                                                                        checked:
                                                                                          _vm._q(
                                                                                            _vm
                                                                                              .chosenskillSets
                                                                                              .attributes
                                                                                              .show_points,
                                                                                            false
                                                                                          ),
                                                                                      },
                                                                                    on: {
                                                                                      change:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.$set(
                                                                                            _vm
                                                                                              .chosenskillSets
                                                                                              .attributes,
                                                                                            "show_points",
                                                                                            false
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                  }
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "col-md-4",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "form-group",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "label",
                                                                          [
                                                                            _vm._v(
                                                                              "Question Needed?"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "d-flex",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "trigger-item",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "label",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        for: "yes",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "Yes"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "input",
                                                                                  {
                                                                                    directives:
                                                                                      [
                                                                                        {
                                                                                          name: "model",
                                                                                          rawName:
                                                                                            "v-model",
                                                                                          value:
                                                                                            _vm
                                                                                              .chosenskillSets
                                                                                              .attributes
                                                                                              .question_needed,
                                                                                          expression:
                                                                                            "chosenskillSets.attributes.question_needed",
                                                                                        },
                                                                                      ],
                                                                                    attrs:
                                                                                      {
                                                                                        type: "radio",
                                                                                        id: "yes",
                                                                                        name: "question_needed",
                                                                                      },
                                                                                    domProps:
                                                                                      {
                                                                                        value: true,
                                                                                        checked:
                                                                                          _vm._q(
                                                                                            _vm
                                                                                              .chosenskillSets
                                                                                              .attributes
                                                                                              .question_needed,
                                                                                            true
                                                                                          ),
                                                                                      },
                                                                                    on: {
                                                                                      change:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.$set(
                                                                                            _vm
                                                                                              .chosenskillSets
                                                                                              .attributes,
                                                                                            "question_needed",
                                                                                            true
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                  }
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "trigger-item",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "label",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        for: "no",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "No"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "input",
                                                                                  {
                                                                                    directives:
                                                                                      [
                                                                                        {
                                                                                          name: "model",
                                                                                          rawName:
                                                                                            "v-model",
                                                                                          value:
                                                                                            _vm
                                                                                              .chosenskillSets
                                                                                              .attributes
                                                                                              .question_needed,
                                                                                          expression:
                                                                                            "chosenskillSets.attributes.question_needed",
                                                                                        },
                                                                                      ],
                                                                                    attrs:
                                                                                      {
                                                                                        type: "radio",
                                                                                        id: "no",
                                                                                        name: "question_needed",
                                                                                      },
                                                                                    domProps:
                                                                                      {
                                                                                        value: false,
                                                                                        checked:
                                                                                          _vm._q(
                                                                                            _vm
                                                                                              .chosenskillSets
                                                                                              .attributes
                                                                                              .question_needed,
                                                                                            false
                                                                                          ),
                                                                                      },
                                                                                    on: {
                                                                                      change:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.$set(
                                                                                            _vm
                                                                                              .chosenskillSets
                                                                                              .attributes,
                                                                                            "question_needed",
                                                                                            false
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                  }
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "row",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "col-md-4",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "form-group",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "label",
                                                                          [
                                                                            _vm._v(
                                                                              "Feedback Request Type"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "d-flex",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "trigger-item",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "label",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        for: "feedback_request_type",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "All"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "input",
                                                                                  {
                                                                                    directives:
                                                                                      [
                                                                                        {
                                                                                          name: "model",
                                                                                          rawName:
                                                                                            "v-model",
                                                                                          value:
                                                                                            _vm
                                                                                              .chosenskillSets
                                                                                              .attributes
                                                                                              .feedback_request_type,
                                                                                          expression:
                                                                                            "chosenskillSets.attributes.feedback_request_type",
                                                                                        },
                                                                                      ],
                                                                                    attrs:
                                                                                      {
                                                                                        type: "radio",
                                                                                        id: "feedback_request_type",
                                                                                        name: "feedback_request_type",
                                                                                      },
                                                                                    domProps:
                                                                                      {
                                                                                        value:
                                                                                          "all",
                                                                                        checked:
                                                                                          _vm._q(
                                                                                            _vm
                                                                                              .chosenskillSets
                                                                                              .attributes
                                                                                              .feedback_request_type,
                                                                                            "all"
                                                                                          ),
                                                                                      },
                                                                                    on: {
                                                                                      change:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.$set(
                                                                                            _vm
                                                                                              .chosenskillSets
                                                                                              .attributes,
                                                                                            "feedback_request_type",
                                                                                            "all"
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                  }
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "trigger-item",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "label",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        for: "feedback_request_type",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "Hit"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "input",
                                                                                  {
                                                                                    directives:
                                                                                      [
                                                                                        {
                                                                                          name: "model",
                                                                                          rawName:
                                                                                            "v-model",
                                                                                          value:
                                                                                            _vm
                                                                                              .chosenskillSets
                                                                                              .attributes
                                                                                              .feedback_request_type,
                                                                                          expression:
                                                                                            "chosenskillSets.attributes.feedback_request_type",
                                                                                        },
                                                                                      ],
                                                                                    attrs:
                                                                                      {
                                                                                        type: "radio",
                                                                                        id: "feedback_request_type",
                                                                                        name: "feedback_request_type",
                                                                                      },
                                                                                    domProps:
                                                                                      {
                                                                                        value:
                                                                                          "hit",
                                                                                        checked:
                                                                                          _vm._q(
                                                                                            _vm
                                                                                              .chosenskillSets
                                                                                              .attributes
                                                                                              .feedback_request_type,
                                                                                            "hit"
                                                                                          ),
                                                                                      },
                                                                                    on: {
                                                                                      change:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.$set(
                                                                                            _vm
                                                                                              .chosenskillSets
                                                                                              .attributes,
                                                                                            "feedback_request_type",
                                                                                            "hit"
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                  }
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "trigger-item",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "label",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        for: "feedback_request_type",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "None"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "input",
                                                                                  {
                                                                                    directives:
                                                                                      [
                                                                                        {
                                                                                          name: "model",
                                                                                          rawName:
                                                                                            "v-model",
                                                                                          value:
                                                                                            _vm
                                                                                              .chosenskillSets
                                                                                              .attributes
                                                                                              .feedback_request_type,
                                                                                          expression:
                                                                                            "chosenskillSets.attributes.feedback_request_type",
                                                                                        },
                                                                                      ],
                                                                                    attrs:
                                                                                      {
                                                                                        type: "radio",
                                                                                        id: "feedback_request_type",
                                                                                        name: "feedback_request_type",
                                                                                      },
                                                                                    domProps:
                                                                                      {
                                                                                        value:
                                                                                          "none",
                                                                                        checked:
                                                                                          _vm._q(
                                                                                            _vm
                                                                                              .chosenskillSets
                                                                                              .attributes
                                                                                              .feedback_request_type,
                                                                                            "none"
                                                                                          ),
                                                                                      },
                                                                                    on: {
                                                                                      change:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.$set(
                                                                                            _vm
                                                                                              .chosenskillSets
                                                                                              .attributes,
                                                                                            "feedback_request_type",
                                                                                            "none"
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                  }
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "col-md-4",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "form-group",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "label",
                                                                          [
                                                                            _vm._v(
                                                                              "Show Pass/Fail Labels"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "d-flex",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "trigger-item",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "label",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        for: "feedback_request_type",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "Show"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "input",
                                                                                  {
                                                                                    directives:
                                                                                      [
                                                                                        {
                                                                                          name: "model",
                                                                                          rawName:
                                                                                            "v-model",
                                                                                          value:
                                                                                            _vm
                                                                                              .chosenskillSets
                                                                                              .attributes
                                                                                              .show_pass_fail_labels,
                                                                                          expression:
                                                                                            "chosenskillSets.attributes.show_pass_fail_labels",
                                                                                        },
                                                                                      ],
                                                                                    attrs:
                                                                                      {
                                                                                        type: "radio",
                                                                                        id: "show_pass_fail_labels",
                                                                                        name: "show_pass_fail_labels",
                                                                                      },
                                                                                    domProps:
                                                                                      {
                                                                                        value: true,
                                                                                        checked:
                                                                                          _vm._q(
                                                                                            _vm
                                                                                              .chosenskillSets
                                                                                              .attributes
                                                                                              .show_pass_fail_labels,
                                                                                            true
                                                                                          ),
                                                                                      },
                                                                                    on: {
                                                                                      change:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.$set(
                                                                                            _vm
                                                                                              .chosenskillSets
                                                                                              .attributes,
                                                                                            "show_pass_fail_labels",
                                                                                            true
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                  }
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "trigger-item",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "label",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        for: "feedback_request_type",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "Hide"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "input",
                                                                                  {
                                                                                    directives:
                                                                                      [
                                                                                        {
                                                                                          name: "model",
                                                                                          rawName:
                                                                                            "v-model",
                                                                                          value:
                                                                                            _vm
                                                                                              .chosenskillSets
                                                                                              .attributes
                                                                                              .show_pass_fail_labels,
                                                                                          expression:
                                                                                            "chosenskillSets.attributes.show_pass_fail_labels",
                                                                                        },
                                                                                      ],
                                                                                    attrs:
                                                                                      {
                                                                                        type: "radio",
                                                                                        id: "show_pass_fail_labels",
                                                                                        name: "show_pass_fail_labels",
                                                                                      },
                                                                                    domProps:
                                                                                      {
                                                                                        value: false,
                                                                                        checked:
                                                                                          _vm._q(
                                                                                            _vm
                                                                                              .chosenskillSets
                                                                                              .attributes
                                                                                              .show_pass_fail_labels,
                                                                                            false
                                                                                          ),
                                                                                      },
                                                                                    on: {
                                                                                      change:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.$set(
                                                                                            _vm
                                                                                              .chosenskillSets
                                                                                              .attributes,
                                                                                            "show_pass_fail_labels",
                                                                                            false
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                  }
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            _c("hr"),
                                                            _vm._m(3),
                                                            _vm.chosenskillSets
                                                              .attributes
                                                              .feedback_request_items
                                                              ? _c(
                                                                  "div",
                                                                  _vm._l(
                                                                    _vm
                                                                      .chosenskillSets
                                                                      .attributes
                                                                      .feedback_request_items
                                                                      .data,
                                                                    function (
                                                                      feedback_item,
                                                                      index
                                                                    ) {
                                                                      return _c(
                                                                        "div",
                                                                        {
                                                                          key: index,
                                                                          staticClass:
                                                                            "row",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "col-md-6",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "ul",
                                                                                {
                                                                                  staticClass:
                                                                                    "p-0 m-0 w-full",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "li",
                                                                                    {
                                                                                      staticClass:
                                                                                        "p-0 mb-8",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "div",
                                                                                        {
                                                                                          staticClass:
                                                                                            "d-block mb-5",
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "div",
                                                                                            {
                                                                                              staticClass:
                                                                                                "d-block mb-5",
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "label",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "mb-2",
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "Assessment item label"
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                              _c(
                                                                                                "input",
                                                                                                {
                                                                                                  directives:
                                                                                                    [
                                                                                                      {
                                                                                                        name: "model",
                                                                                                        rawName:
                                                                                                          "v-model",
                                                                                                        value:
                                                                                                          feedback_item
                                                                                                            .attributes
                                                                                                            .label,
                                                                                                        expression:
                                                                                                          "feedback_item.attributes.label",
                                                                                                      },
                                                                                                    ],
                                                                                                  staticClass:
                                                                                                    "form-control mb-5",
                                                                                                  attrs:
                                                                                                    {
                                                                                                      type: "text",
                                                                                                    },
                                                                                                  domProps:
                                                                                                    {
                                                                                                      value:
                                                                                                        feedback_item
                                                                                                          .attributes
                                                                                                          .label,
                                                                                                    },
                                                                                                  on: {
                                                                                                    input:
                                                                                                      function (
                                                                                                        $event
                                                                                                      ) {
                                                                                                        if (
                                                                                                          $event
                                                                                                            .target
                                                                                                            .composing
                                                                                                        )
                                                                                                          return
                                                                                                        _vm.$set(
                                                                                                          feedback_item.attributes,
                                                                                                          "label",
                                                                                                          $event
                                                                                                            .target
                                                                                                            .value
                                                                                                        )
                                                                                                      },
                                                                                                  },
                                                                                                }
                                                                                              ),
                                                                                              _vm
                                                                                                .chosenskillSets
                                                                                                .attributes
                                                                                                .assessment_type ==
                                                                                              "threshold"
                                                                                                ? _c(
                                                                                                    "label",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "d-block mb-2",
                                                                                                    },
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        " Level "
                                                                                                      ),
                                                                                                    ]
                                                                                                  )
                                                                                                : _vm._e(),
                                                                                              _vm
                                                                                                .chosenskillSets
                                                                                                .attributes
                                                                                                .assessment_type ==
                                                                                              "threshold"
                                                                                                ? _c(
                                                                                                    "input",
                                                                                                    {
                                                                                                      directives:
                                                                                                        [
                                                                                                          {
                                                                                                            name: "model",
                                                                                                            rawName:
                                                                                                              "v-model",
                                                                                                            value:
                                                                                                              feedback_item
                                                                                                                .attributes
                                                                                                                .level,
                                                                                                            expression:
                                                                                                              "feedback_item.attributes.level",
                                                                                                          },
                                                                                                        ],
                                                                                                      staticClass:
                                                                                                        "form-control mb-5",
                                                                                                      attrs:
                                                                                                        {
                                                                                                          type: "text",
                                                                                                        },
                                                                                                      domProps:
                                                                                                        {
                                                                                                          value:
                                                                                                            feedback_item
                                                                                                              .attributes
                                                                                                              .level,
                                                                                                        },
                                                                                                      on: {
                                                                                                        input:
                                                                                                          function (
                                                                                                            $event
                                                                                                          ) {
                                                                                                            if (
                                                                                                              $event
                                                                                                                .target
                                                                                                                .composing
                                                                                                            )
                                                                                                              return
                                                                                                            _vm.$set(
                                                                                                              feedback_item.attributes,
                                                                                                              "level",
                                                                                                              $event
                                                                                                                .target
                                                                                                                .value
                                                                                                            )
                                                                                                          },
                                                                                                      },
                                                                                                    }
                                                                                                  )
                                                                                                : _vm._e(),
                                                                                              _c(
                                                                                                "label",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "mb-2",
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "Points"
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                              _c(
                                                                                                "input",
                                                                                                {
                                                                                                  directives:
                                                                                                    [
                                                                                                      {
                                                                                                        name: "model",
                                                                                                        rawName:
                                                                                                          "v-model",
                                                                                                        value:
                                                                                                          feedback_item
                                                                                                            .attributes
                                                                                                            .points,
                                                                                                        expression:
                                                                                                          "feedback_item.attributes.points",
                                                                                                      },
                                                                                                    ],
                                                                                                  staticClass:
                                                                                                    "form-control mb-5",
                                                                                                  attrs:
                                                                                                    {
                                                                                                      type: "text",
                                                                                                    },
                                                                                                  domProps:
                                                                                                    {
                                                                                                      value:
                                                                                                        feedback_item
                                                                                                          .attributes
                                                                                                          .points,
                                                                                                    },
                                                                                                  on: {
                                                                                                    input:
                                                                                                      function (
                                                                                                        $event
                                                                                                      ) {
                                                                                                        if (
                                                                                                          $event
                                                                                                            .target
                                                                                                            .composing
                                                                                                        )
                                                                                                          return
                                                                                                        _vm.$set(
                                                                                                          feedback_item.attributes,
                                                                                                          "points",
                                                                                                          $event
                                                                                                            .target
                                                                                                            .value
                                                                                                        )
                                                                                                      },
                                                                                                  },
                                                                                                }
                                                                                              ),
                                                                                              _vm
                                                                                                .chosenskillSets
                                                                                                .attributes
                                                                                                .assessment_type ==
                                                                                              "threshold"
                                                                                                ? _c(
                                                                                                    "button",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "btn btn-info",
                                                                                                      on: {
                                                                                                        click:
                                                                                                          function (
                                                                                                            $event
                                                                                                          ) {
                                                                                                            $event.preventDefault()
                                                                                                            return _vm.deleteFeedbackRequestItem(
                                                                                                              feedback_item.id
                                                                                                            )
                                                                                                          },
                                                                                                      },
                                                                                                    },
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        " Delete "
                                                                                                      ),
                                                                                                    ]
                                                                                                  )
                                                                                                : _vm._e(),
                                                                                            ]
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "col-md-6",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "d-block mb-5",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "label",
                                                                                    {
                                                                                      staticClass:
                                                                                        "mb-2",
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Assessment Item Description"
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                  _c(
                                                                                    "textarea",
                                                                                    {
                                                                                      directives:
                                                                                        [
                                                                                          {
                                                                                            name: "model",
                                                                                            rawName:
                                                                                              "v-model",
                                                                                            value:
                                                                                              feedback_item
                                                                                                .attributes
                                                                                                .description,
                                                                                            expression:
                                                                                              "feedback_item.attributes.description",
                                                                                          },
                                                                                        ],
                                                                                      staticClass:
                                                                                        "form-control",
                                                                                      attrs:
                                                                                        {
                                                                                          type: "textarea",
                                                                                        },
                                                                                      domProps:
                                                                                        {
                                                                                          value:
                                                                                            feedback_item
                                                                                              .attributes
                                                                                              .description,
                                                                                        },
                                                                                      on: {
                                                                                        input:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            if (
                                                                                              $event
                                                                                                .target
                                                                                                .composing
                                                                                            )
                                                                                              return
                                                                                            _vm.$set(
                                                                                              feedback_item.attributes,
                                                                                              "description",
                                                                                              $event
                                                                                                .target
                                                                                                .value
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                    }
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      )
                                                                    }
                                                                  ),
                                                                  0
                                                                )
                                                              : _vm._e(),
                                                            _vm.chosenskillSets
                                                              .attributes
                                                              .assessment_type ==
                                                            "threshold"
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "d-flex mb-5",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "button",
                                                                      {
                                                                        staticClass:
                                                                          "btn",
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              $event.preventDefault()
                                                                              return _vm.createFeedbackRequestItem()
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "mx-auto text-sm font-bold",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "Add new feedback request item"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                            _c("hr"),
                                                            _vm.chosenskillSets
                                                              .attributes
                                                              .assessment_type ==
                                                            "threshold"
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "mb-5",
                                                                  },
                                                                  [
                                                                    _vm._m(4),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "d-flex pb-4 border-b-4",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "d-block mb-5",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "label",
                                                                              {
                                                                                staticClass:
                                                                                  "block mb-2",
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "Name"
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "input",
                                                                              {
                                                                                directives:
                                                                                  [
                                                                                    {
                                                                                      name: "model",
                                                                                      rawName:
                                                                                        "v-model",
                                                                                      value:
                                                                                        _vm
                                                                                          .chosenskillSets
                                                                                          .attributes
                                                                                          .knowbase_name,
                                                                                      expression:
                                                                                        "chosenskillSets.attributes.knowbase_name",
                                                                                    },
                                                                                  ],
                                                                                staticClass:
                                                                                  "form-control",
                                                                                attrs:
                                                                                  {
                                                                                    type: "text",
                                                                                  },
                                                                                domProps:
                                                                                  {
                                                                                    value:
                                                                                      _vm
                                                                                        .chosenskillSets
                                                                                        .attributes
                                                                                        .knowbase_name,
                                                                                  },
                                                                                on: {
                                                                                  input:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      if (
                                                                                        $event
                                                                                          .target
                                                                                          .composing
                                                                                      )
                                                                                        return
                                                                                      _vm.$set(
                                                                                        _vm
                                                                                          .chosenskillSets
                                                                                          .attributes,
                                                                                        "knowbase_name",
                                                                                        $event
                                                                                          .target
                                                                                          .value
                                                                                      )
                                                                                    },
                                                                                },
                                                                              }
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._m(5),
                                                                    _vm
                                                                      .chosenskillSets
                                                                      .attributes
                                                                      .assessment_knowledge_items
                                                                      ? _c(
                                                                          "div",
                                                                          _vm._l(
                                                                            _vm
                                                                              .chosenskillSets
                                                                              .attributes
                                                                              .assessment_knowledge_items
                                                                              .data,
                                                                            function (
                                                                              knowledge_item,
                                                                              index
                                                                            ) {
                                                                              return _c(
                                                                                "ul",
                                                                                {
                                                                                  key: index,
                                                                                  staticClass:
                                                                                    "row mb-5",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "li",
                                                                                    {
                                                                                      staticClass:
                                                                                        "d-flex col-md-12 justify-content-between",
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        " " +
                                                                                          _vm._s(
                                                                                            knowledge_item
                                                                                              .attributes
                                                                                              .body
                                                                                          ) +
                                                                                          " "
                                                                                      ),
                                                                                      _c(
                                                                                        "button",
                                                                                        {
                                                                                          staticClass:
                                                                                            "btn",
                                                                                          on: {
                                                                                            click:
                                                                                              function (
                                                                                                $event
                                                                                              ) {
                                                                                                return _vm.deleteKnowledgeBaseItem(
                                                                                                  knowledge_item.id
                                                                                                )
                                                                                              },
                                                                                          },
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            "Delete"
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            }
                                                                          ),
                                                                          0
                                                                        )
                                                                      : _vm._e(),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "d-flex pb-10 mb-10 border-b-4 flex-column",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "label",
                                                                          [
                                                                            _vm._v(
                                                                              "New Knowledge Base Item"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "d-flex",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "input",
                                                                              {
                                                                                directives:
                                                                                  [
                                                                                    {
                                                                                      name: "model",
                                                                                      rawName:
                                                                                        "v-model",
                                                                                      value:
                                                                                        _vm.new_know_base_item_text,
                                                                                      expression:
                                                                                        "new_know_base_item_text",
                                                                                    },
                                                                                  ],
                                                                                staticClass:
                                                                                  "form-control mr-5",
                                                                                attrs:
                                                                                  {
                                                                                    type: "text",
                                                                                    placeholder:
                                                                                      "Add new knowledge base item here",
                                                                                  },
                                                                                domProps:
                                                                                  {
                                                                                    value:
                                                                                      _vm.new_know_base_item_text,
                                                                                  },
                                                                                on: {
                                                                                  input:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      if (
                                                                                        $event
                                                                                          .target
                                                                                          .composing
                                                                                      )
                                                                                        return
                                                                                      _vm.new_know_base_item_text =
                                                                                        $event.target.value
                                                                                    },
                                                                                },
                                                                              }
                                                                            ),
                                                                            _c(
                                                                              "button",
                                                                              {
                                                                                staticClass:
                                                                                  "btn",
                                                                                on: {
                                                                                  click:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.addNewKnowledgeBaseItem()
                                                                                    },
                                                                                },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "Add "
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-flex",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "block mb-5 w-1/3",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "label",
                                                                      {
                                                                        staticClass:
                                                                          "mb-2",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Task"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "input",
                                                                      {
                                                                        directives:
                                                                          [
                                                                            {
                                                                              name: "model",
                                                                              rawName:
                                                                                "v-model",
                                                                              value:
                                                                                _vm
                                                                                  .chosenskillSets
                                                                                  .attributes
                                                                                  .task,
                                                                              expression:
                                                                                "chosenskillSets.attributes.task",
                                                                            },
                                                                          ],
                                                                        staticClass:
                                                                          "form-control",
                                                                        attrs: {
                                                                          type: "text",
                                                                        },
                                                                        domProps:
                                                                          {
                                                                            value:
                                                                              _vm
                                                                                .chosenskillSets
                                                                                .attributes
                                                                                .task,
                                                                          },
                                                                        on: {
                                                                          input:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              if (
                                                                                $event
                                                                                  .target
                                                                                  .composing
                                                                              )
                                                                                return
                                                                              _vm.$set(
                                                                                _vm
                                                                                  .chosenskillSets
                                                                                  .attributes,
                                                                                "task",
                                                                                $event
                                                                                  .target
                                                                                  .value
                                                                              )
                                                                            },
                                                                        },
                                                                      }
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "row mb-5",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "col-md-12",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "label",
                                                                      {
                                                                        staticClass:
                                                                          "mb-2",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Use custom skill label?"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "d-flex",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "trigger-item",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "input",
                                                                              {
                                                                                directives:
                                                                                  [
                                                                                    {
                                                                                      name: "model",
                                                                                      rawName:
                                                                                        "v-model",
                                                                                      value:
                                                                                        _vm
                                                                                          .chosenskillSets
                                                                                          .attributes
                                                                                          .use_skill_label,
                                                                                      expression:
                                                                                        "chosenskillSets.attributes.use_skill_label",
                                                                                    },
                                                                                  ],
                                                                                staticClass:
                                                                                  "form-control",
                                                                                attrs:
                                                                                  {
                                                                                    id: "use_skill_label-yes",
                                                                                    type: "radio",
                                                                                    value:
                                                                                      "true",
                                                                                    name: "use_skill_label-yes",
                                                                                  },
                                                                                domProps:
                                                                                  {
                                                                                    checked:
                                                                                      _vm._q(
                                                                                        _vm
                                                                                          .chosenskillSets
                                                                                          .attributes
                                                                                          .use_skill_label,
                                                                                        "true"
                                                                                      ),
                                                                                  },
                                                                                on: {
                                                                                  change:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.$set(
                                                                                        _vm
                                                                                          .chosenskillSets
                                                                                          .attributes,
                                                                                        "use_skill_label",
                                                                                        "true"
                                                                                      )
                                                                                    },
                                                                                },
                                                                              }
                                                                            ),
                                                                            _c(
                                                                              "label",
                                                                              {
                                                                                staticClass:
                                                                                  "ml-2 text-sm font-medium text-gray-900 dark:text-gray-300",
                                                                                attrs:
                                                                                  {
                                                                                    for: "use_skill_label-yes",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "Yes"
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "trigger-item",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "input",
                                                                              {
                                                                                directives:
                                                                                  [
                                                                                    {
                                                                                      name: "model",
                                                                                      rawName:
                                                                                        "v-model",
                                                                                      value:
                                                                                        _vm
                                                                                          .chosenskillSets
                                                                                          .attributes
                                                                                          .use_skill_label,
                                                                                      expression:
                                                                                        "chosenskillSets.attributes.use_skill_label",
                                                                                    },
                                                                                  ],
                                                                                staticClass:
                                                                                  "form-control",
                                                                                attrs:
                                                                                  {
                                                                                    id: "use_skill_label-no",
                                                                                    type: "radio",
                                                                                    value:
                                                                                      "false",
                                                                                    name: "use_skill_label-no",
                                                                                  },
                                                                                domProps:
                                                                                  {
                                                                                    checked:
                                                                                      _vm._q(
                                                                                        _vm
                                                                                          .chosenskillSets
                                                                                          .attributes
                                                                                          .use_skill_label,
                                                                                        "false"
                                                                                      ),
                                                                                  },
                                                                                on: {
                                                                                  change:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.$set(
                                                                                        _vm
                                                                                          .chosenskillSets
                                                                                          .attributes,
                                                                                        "use_skill_label",
                                                                                        "false"
                                                                                      )
                                                                                    },
                                                                                },
                                                                              }
                                                                            ),
                                                                            _c(
                                                                              "label",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    for: "use_skill_label-no",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "No"
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "row mb-5",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "col-md-12",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "label",
                                                                      {
                                                                        staticClass:
                                                                          "mb-2",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Custom skill label"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "d-flex",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "block mr-5",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "input",
                                                                              {
                                                                                directives:
                                                                                  [
                                                                                    {
                                                                                      name: "model",
                                                                                      rawName:
                                                                                        "v-model",
                                                                                      value:
                                                                                        _vm
                                                                                          .chosenskillSets
                                                                                          .attributes
                                                                                          .skill_label,
                                                                                      expression:
                                                                                        "chosenskillSets.attributes.skill_label",
                                                                                    },
                                                                                  ],
                                                                                staticClass:
                                                                                  "form-control",
                                                                                attrs:
                                                                                  {
                                                                                    type: "text",
                                                                                  },
                                                                                domProps:
                                                                                  {
                                                                                    value:
                                                                                      _vm
                                                                                        .chosenskillSets
                                                                                        .attributes
                                                                                        .skill_label,
                                                                                  },
                                                                                on: {
                                                                                  input:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      if (
                                                                                        $event
                                                                                          .target
                                                                                          .composing
                                                                                      )
                                                                                        return
                                                                                      _vm.$set(
                                                                                        _vm
                                                                                          .chosenskillSets
                                                                                          .attributes,
                                                                                        "skill_label",
                                                                                        $event
                                                                                          .target
                                                                                          .value
                                                                                      )
                                                                                    },
                                                                                },
                                                                              }
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "row mb-5",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "col-md-12 d-flex flex-column",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "label",
                                                                      {
                                                                        staticClass:
                                                                          "block text-sm font-medium text-slate-700",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Admin Notes"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "textarea",
                                                                      {
                                                                        directives:
                                                                          [
                                                                            {
                                                                              name: "model",
                                                                              rawName:
                                                                                "v-model",
                                                                              value:
                                                                                _vm
                                                                                  .chosenskillSets
                                                                                  .attributes
                                                                                  .admin_notes,
                                                                              expression:
                                                                                "chosenskillSets.attributes.admin_notes",
                                                                            },
                                                                          ],
                                                                        staticClass:
                                                                          "form-control",
                                                                        attrs: {
                                                                          type: "text",
                                                                        },
                                                                        domProps:
                                                                          {
                                                                            value:
                                                                              _vm
                                                                                .chosenskillSets
                                                                                .attributes
                                                                                .admin_notes,
                                                                          },
                                                                        on: {
                                                                          input:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              if (
                                                                                $event
                                                                                  .target
                                                                                  .composing
                                                                              )
                                                                                return
                                                                              _vm.$set(
                                                                                _vm
                                                                                  .chosenskillSets
                                                                                  .attributes,
                                                                                "admin_notes",
                                                                                $event
                                                                                  .target
                                                                                  .value
                                                                              )
                                                                            },
                                                                        },
                                                                      }
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "row",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "col-md-12",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "form-group",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "button",
                                                                          {
                                                                            staticClass:
                                                                              "btn",
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  $event.preventDefault()
                                                                                  return _vm.saveAssessment.apply(
                                                                                    null,
                                                                                    arguments
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "Save Assessment"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex row flex-column form-group answers-container",
                                              },
                                              [
                                                _c("label", [
                                                  _vm._v(
                                                    "Question Assessments"
                                                  ),
                                                ]),
                                                _c("div", [
                                                  _c("label", [
                                                    _vm._v("Assessment Type"),
                                                  ]),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-column",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass: "d-flex",
                                                        },
                                                        [
                                                          _c("label", [
                                                            _vm._v("Knowledge"),
                                                          ]),
                                                          _c("input", {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  _vm
                                                                    .chosenQaItem
                                                                    .assessment_type,
                                                                expression:
                                                                  "chosenQaItem.assessment_type",
                                                              },
                                                            ],
                                                            attrs: {
                                                              type: "radio",
                                                            },
                                                            domProps: {
                                                              value:
                                                                "knowledge",
                                                              checked: _vm._q(
                                                                _vm.chosenQaItem
                                                                  .assessment_type,
                                                                "knowledge"
                                                              ),
                                                            },
                                                            on: {
                                                              change: function (
                                                                $event
                                                              ) {
                                                                return _vm.$set(
                                                                  _vm.chosenQaItem,
                                                                  "assessment_type",
                                                                  "knowledge"
                                                                )
                                                              },
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass: "d-flex",
                                                        },
                                                        [
                                                          _c("label", [
                                                            _vm._v("Skill"),
                                                          ]),
                                                          _c("input", {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  _vm
                                                                    .chosenQaItem
                                                                    .assessment_type,
                                                                expression:
                                                                  "chosenQaItem.assessment_type",
                                                              },
                                                            ],
                                                            attrs: {
                                                              type: "radio",
                                                            },
                                                            domProps: {
                                                              value: "skill",
                                                              checked: _vm._q(
                                                                _vm.chosenQaItem
                                                                  .assessment_type,
                                                                "skill"
                                                              ),
                                                            },
                                                            on: {
                                                              change: function (
                                                                $event
                                                              ) {
                                                                return _vm.$set(
                                                                  _vm.chosenQaItem,
                                                                  "assessment_type",
                                                                  "skill"
                                                                )
                                                              },
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]),
                                                _c(
                                                  "ul",
                                                  _vm._l(
                                                    _vm.chosenQaItem
                                                      .llm_dialogic_question_assessments_attributes,
                                                    function (
                                                      assessmentItem,
                                                      index
                                                    ) {
                                                      return _c(
                                                        "li",
                                                        { key: index },
                                                        [
                                                          _c("div", [
                                                            _c("label", [
                                                              _vm._v("Label"),
                                                            ]),
                                                            _c("input", {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    assessmentItem.label,
                                                                  expression:
                                                                    "assessmentItem.label",
                                                                },
                                                              ],
                                                              staticClass:
                                                                "form-control",
                                                              attrs: {
                                                                type: "text",
                                                                placeholder:
                                                                  "Enter Label",
                                                              },
                                                              domProps: {
                                                                value:
                                                                  assessmentItem.label,
                                                              },
                                                              on: {
                                                                input:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      $event
                                                                        .target
                                                                        .composing
                                                                    )
                                                                      return
                                                                    _vm.$set(
                                                                      assessmentItem,
                                                                      "label",
                                                                      $event
                                                                        .target
                                                                        .value
                                                                    )
                                                                  },
                                                              },
                                                            }),
                                                            _c("label", [
                                                              _vm._v(
                                                                "Response"
                                                              ),
                                                            ]),
                                                            _c("input", {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    assessmentItem.response,
                                                                  expression:
                                                                    "assessmentItem.response",
                                                                },
                                                              ],
                                                              staticClass:
                                                                "form-control",
                                                              attrs: {
                                                                type: "text",
                                                                placeholder:
                                                                  "Enter Response",
                                                              },
                                                              domProps: {
                                                                value:
                                                                  assessmentItem.response,
                                                              },
                                                              on: {
                                                                input:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      $event
                                                                        .target
                                                                        .composing
                                                                    )
                                                                      return
                                                                    _vm.$set(
                                                                      assessmentItem,
                                                                      "response",
                                                                      $event
                                                                        .target
                                                                        .value
                                                                    )
                                                                  },
                                                              },
                                                            }),
                                                            _c("label", [
                                                              _vm._v("Debrief"),
                                                            ]),
                                                            _c("input", {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    assessmentItem.debrief,
                                                                  expression:
                                                                    "assessmentItem.debrief",
                                                                },
                                                              ],
                                                              staticClass:
                                                                "form-control",
                                                              attrs: {
                                                                type: "text",
                                                                placeholder:
                                                                  "Enter Debrief",
                                                              },
                                                              domProps: {
                                                                value:
                                                                  assessmentItem.debrief,
                                                              },
                                                              on: {
                                                                input:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      $event
                                                                        .target
                                                                        .composing
                                                                    )
                                                                      return
                                                                    _vm.$set(
                                                                      assessmentItem,
                                                                      "debrief",
                                                                      $event
                                                                        .target
                                                                        .value
                                                                    )
                                                                  },
                                                              },
                                                            }),
                                                            _c("label", [
                                                              _vm._v(
                                                                "Follow Up"
                                                              ),
                                                            ]),
                                                            _c("input", {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    assessmentItem.follow_up,
                                                                  expression:
                                                                    "assessmentItem.follow_up",
                                                                },
                                                              ],
                                                              staticClass:
                                                                "form-control",
                                                              attrs: {
                                                                type: "text",
                                                                placeholder:
                                                                  "Enter Follow up",
                                                              },
                                                              domProps: {
                                                                value:
                                                                  assessmentItem.follow_up,
                                                              },
                                                              on: {
                                                                input:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      $event
                                                                        .target
                                                                        .composing
                                                                    )
                                                                      return
                                                                    _vm.$set(
                                                                      assessmentItem,
                                                                      "follow_up",
                                                                      $event
                                                                        .target
                                                                        .value
                                                                    )
                                                                  },
                                                              },
                                                            }),
                                                            _c("label", [
                                                              _vm._v(
                                                                "Threshold"
                                                              ),
                                                            ]),
                                                            _c("input", {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    assessmentItem.threshold,
                                                                  expression:
                                                                    "assessmentItem.threshold",
                                                                },
                                                              ],
                                                              staticClass:
                                                                "form-control",
                                                              attrs: {
                                                                type: "text",
                                                                placeholder:
                                                                  "Enter Threshold",
                                                              },
                                                              domProps: {
                                                                value:
                                                                  assessmentItem.threshold,
                                                              },
                                                              on: {
                                                                input:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      $event
                                                                        .target
                                                                        .composing
                                                                    )
                                                                      return
                                                                    _vm.$set(
                                                                      assessmentItem,
                                                                      "threshold",
                                                                      $event
                                                                        .target
                                                                        .value
                                                                    )
                                                                  },
                                                              },
                                                            }),
                                                            _vm.chosenQaItem
                                                              .assessment_type ===
                                                            "knowledge"
                                                              ? _c("div", [
                                                                  _c("label", [
                                                                    _vm._v(
                                                                      "Points"
                                                                    ),
                                                                  ]),
                                                                  _c("input", {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "model",
                                                                          rawName:
                                                                            "v-model",
                                                                          value:
                                                                            assessmentItem.points,
                                                                          expression:
                                                                            "assessmentItem.points",
                                                                        },
                                                                      ],
                                                                    staticClass:
                                                                      "form-control",
                                                                    attrs: {
                                                                      type: "text",
                                                                      placeholder:
                                                                        "Enter Points",
                                                                    },
                                                                    domProps: {
                                                                      value:
                                                                        assessmentItem.points,
                                                                    },
                                                                    on: {
                                                                      input:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          if (
                                                                            $event
                                                                              .target
                                                                              .composing
                                                                          )
                                                                            return
                                                                          _vm.$set(
                                                                            assessmentItem,
                                                                            "points",
                                                                            $event
                                                                              .target
                                                                              .value
                                                                          )
                                                                        },
                                                                    },
                                                                  }),
                                                                ])
                                                              : _vm._e(),
                                                          ]),
                                                          _c("div", [
                                                            _c(
                                                              "button",
                                                              {
                                                                staticClass:
                                                                  "btn",
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      $event.preventDefault()
                                                                      return _vm.saveAssessmentItem(
                                                                        index
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [_vm._v("Save")]
                                                            ),
                                                            _c(
                                                              "button",
                                                              {
                                                                staticClass:
                                                                  "btn",
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      $event.preventDefault()
                                                                      return _vm.deleteAssessmentItem(
                                                                        index
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [_vm._v("Delete")]
                                                            ),
                                                          ]),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                ),
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass: "btn",
                                                    on: {
                                                      click:
                                                        _vm.addAssessmentItem,
                                                    },
                                                  },
                                                  [_vm._v("Add Assessment")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "row flex-column answers-container",
                                              },
                                              [
                                                _c("h4", [
                                                  _vm._v("Show/Hide Options"),
                                                ]),
                                                _c(
                                                  "div",
                                                  { staticClass: "ml-3" },
                                                  [
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm.chosenQaItem
                                                              .show_labels,
                                                          expression:
                                                            "chosenQaItem.show_labels",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "form-check-input",
                                                      attrs: {
                                                        type: "checkbox",
                                                        value: "",
                                                        id: "showLabels",
                                                      },
                                                      domProps: {
                                                        checked: Array.isArray(
                                                          _vm.chosenQaItem
                                                            .show_labels
                                                        )
                                                          ? _vm._i(
                                                              _vm.chosenQaItem
                                                                .show_labels,
                                                              ""
                                                            ) > -1
                                                          : _vm.chosenQaItem
                                                              .show_labels,
                                                      },
                                                      on: {
                                                        change: function (
                                                          $event
                                                        ) {
                                                          var $$a =
                                                              _vm.chosenQaItem
                                                                .show_labels,
                                                            $$el =
                                                              $event.target,
                                                            $$c = $$el.checked
                                                              ? true
                                                              : false
                                                          if (
                                                            Array.isArray($$a)
                                                          ) {
                                                            var $$v = "",
                                                              $$i = _vm._i(
                                                                $$a,
                                                                $$v
                                                              )
                                                            if ($$el.checked) {
                                                              $$i < 0 &&
                                                                _vm.$set(
                                                                  _vm.chosenQaItem,
                                                                  "show_labels",
                                                                  $$a.concat([
                                                                    $$v,
                                                                  ])
                                                                )
                                                            } else {
                                                              $$i > -1 &&
                                                                _vm.$set(
                                                                  _vm.chosenQaItem,
                                                                  "show_labels",
                                                                  $$a
                                                                    .slice(
                                                                      0,
                                                                      $$i
                                                                    )
                                                                    .concat(
                                                                      $$a.slice(
                                                                        $$i + 1
                                                                      )
                                                                    )
                                                                )
                                                            }
                                                          } else {
                                                            _vm.$set(
                                                              _vm.chosenQaItem,
                                                              "show_labels",
                                                              $$c
                                                            )
                                                          }
                                                        },
                                                      },
                                                    }),
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "form-check-label",
                                                        attrs: {
                                                          for: "showLabels",
                                                        },
                                                      },
                                                      [_vm._v(" Show Labels ")]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "ml-3" },
                                                  [
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm.chosenQaItem
                                                              .show_responses,
                                                          expression:
                                                            "chosenQaItem.show_responses",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "form-check-input",
                                                      attrs: {
                                                        type: "checkbox",
                                                        value: "",
                                                        id: "showResponses",
                                                      },
                                                      domProps: {
                                                        checked: Array.isArray(
                                                          _vm.chosenQaItem
                                                            .show_responses
                                                        )
                                                          ? _vm._i(
                                                              _vm.chosenQaItem
                                                                .show_responses,
                                                              ""
                                                            ) > -1
                                                          : _vm.chosenQaItem
                                                              .show_responses,
                                                      },
                                                      on: {
                                                        change: function (
                                                          $event
                                                        ) {
                                                          var $$a =
                                                              _vm.chosenQaItem
                                                                .show_responses,
                                                            $$el =
                                                              $event.target,
                                                            $$c = $$el.checked
                                                              ? true
                                                              : false
                                                          if (
                                                            Array.isArray($$a)
                                                          ) {
                                                            var $$v = "",
                                                              $$i = _vm._i(
                                                                $$a,
                                                                $$v
                                                              )
                                                            if ($$el.checked) {
                                                              $$i < 0 &&
                                                                _vm.$set(
                                                                  _vm.chosenQaItem,
                                                                  "show_responses",
                                                                  $$a.concat([
                                                                    $$v,
                                                                  ])
                                                                )
                                                            } else {
                                                              $$i > -1 &&
                                                                _vm.$set(
                                                                  _vm.chosenQaItem,
                                                                  "show_responses",
                                                                  $$a
                                                                    .slice(
                                                                      0,
                                                                      $$i
                                                                    )
                                                                    .concat(
                                                                      $$a.slice(
                                                                        $$i + 1
                                                                      )
                                                                    )
                                                                )
                                                            }
                                                          } else {
                                                            _vm.$set(
                                                              _vm.chosenQaItem,
                                                              "show_responses",
                                                              $$c
                                                            )
                                                          }
                                                        },
                                                      },
                                                    }),
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "form-check-label",
                                                        attrs: {
                                                          for: "showResponses",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " Show Responses "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "ml-3" },
                                                  [
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm.chosenQaItem
                                                              .show_debriefs,
                                                          expression:
                                                            "chosenQaItem.show_debriefs",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "form-check-input",
                                                      attrs: {
                                                        type: "checkbox",
                                                        value: "",
                                                        id: "showDebriefs",
                                                      },
                                                      domProps: {
                                                        checked: Array.isArray(
                                                          _vm.chosenQaItem
                                                            .show_debriefs
                                                        )
                                                          ? _vm._i(
                                                              _vm.chosenQaItem
                                                                .show_debriefs,
                                                              ""
                                                            ) > -1
                                                          : _vm.chosenQaItem
                                                              .show_debriefs,
                                                      },
                                                      on: {
                                                        change: function (
                                                          $event
                                                        ) {
                                                          var $$a =
                                                              _vm.chosenQaItem
                                                                .show_debriefs,
                                                            $$el =
                                                              $event.target,
                                                            $$c = $$el.checked
                                                              ? true
                                                              : false
                                                          if (
                                                            Array.isArray($$a)
                                                          ) {
                                                            var $$v = "",
                                                              $$i = _vm._i(
                                                                $$a,
                                                                $$v
                                                              )
                                                            if ($$el.checked) {
                                                              $$i < 0 &&
                                                                _vm.$set(
                                                                  _vm.chosenQaItem,
                                                                  "show_debriefs",
                                                                  $$a.concat([
                                                                    $$v,
                                                                  ])
                                                                )
                                                            } else {
                                                              $$i > -1 &&
                                                                _vm.$set(
                                                                  _vm.chosenQaItem,
                                                                  "show_debriefs",
                                                                  $$a
                                                                    .slice(
                                                                      0,
                                                                      $$i
                                                                    )
                                                                    .concat(
                                                                      $$a.slice(
                                                                        $$i + 1
                                                                      )
                                                                    )
                                                                )
                                                            }
                                                          } else {
                                                            _vm.$set(
                                                              _vm.chosenQaItem,
                                                              "show_debriefs",
                                                              $$c
                                                            )
                                                          }
                                                        },
                                                      },
                                                    }),
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "form-check-label",
                                                        attrs: {
                                                          for: "showDebriefs",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " Show Debriefs "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "ml-3" },
                                                  [
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm.chosenQaItem
                                                              .show_follow_up,
                                                          expression:
                                                            "chosenQaItem.show_follow_up",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "form-check-input",
                                                      attrs: {
                                                        type: "checkbox",
                                                        value: "",
                                                        id: "showFollowUps",
                                                      },
                                                      domProps: {
                                                        checked: Array.isArray(
                                                          _vm.chosenQaItem
                                                            .show_follow_up
                                                        )
                                                          ? _vm._i(
                                                              _vm.chosenQaItem
                                                                .show_follow_up,
                                                              ""
                                                            ) > -1
                                                          : _vm.chosenQaItem
                                                              .show_follow_up,
                                                      },
                                                      on: {
                                                        change: function (
                                                          $event
                                                        ) {
                                                          var $$a =
                                                              _vm.chosenQaItem
                                                                .show_follow_up,
                                                            $$el =
                                                              $event.target,
                                                            $$c = $$el.checked
                                                              ? true
                                                              : false
                                                          if (
                                                            Array.isArray($$a)
                                                          ) {
                                                            var $$v = "",
                                                              $$i = _vm._i(
                                                                $$a,
                                                                $$v
                                                              )
                                                            if ($$el.checked) {
                                                              $$i < 0 &&
                                                                _vm.$set(
                                                                  _vm.chosenQaItem,
                                                                  "show_follow_up",
                                                                  $$a.concat([
                                                                    $$v,
                                                                  ])
                                                                )
                                                            } else {
                                                              $$i > -1 &&
                                                                _vm.$set(
                                                                  _vm.chosenQaItem,
                                                                  "show_follow_up",
                                                                  $$a
                                                                    .slice(
                                                                      0,
                                                                      $$i
                                                                    )
                                                                    .concat(
                                                                      $$a.slice(
                                                                        $$i + 1
                                                                      )
                                                                    )
                                                                )
                                                            }
                                                          } else {
                                                            _vm.$set(
                                                              _vm.chosenQaItem,
                                                              "show_follow_up",
                                                              $$c
                                                            )
                                                          }
                                                        },
                                                      },
                                                    }),
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "form-check-label",
                                                        attrs: {
                                                          for: "showFollowUps",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " Show Follow Up "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "row flex-column answers-container",
                                              },
                                              [
                                                _c("h4", [
                                                  _vm._v("Role Play Options"),
                                                ]),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "section-roleplay",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "form-group-roleplay",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "radio-group-container",
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "form-label-roleplay",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Generative AI Assessment Type"
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "radio-group-roleplay",
                                                              },
                                                              [
                                                                _c("input", {
                                                                  directives: [
                                                                    {
                                                                      name: "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        _vm
                                                                          .chosenQaItem
                                                                          .role_play,
                                                                      expression:
                                                                        "chosenQaItem.role_play",
                                                                    },
                                                                  ],
                                                                  staticClass:
                                                                    "radio-input-roleplay",
                                                                  attrs: {
                                                                    id: "Formative",
                                                                    type: "radio",
                                                                    value:
                                                                      "Formative",
                                                                    name: "conversation",
                                                                  },
                                                                  domProps: {
                                                                    checked:
                                                                      _vm._q(
                                                                        _vm
                                                                          .chosenQaItem
                                                                          .role_play,
                                                                        "Formative"
                                                                      ),
                                                                  },
                                                                  on: {
                                                                    change:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.$set(
                                                                          _vm.chosenQaItem,
                                                                          "role_play",
                                                                          "Formative"
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                                _c(
                                                                  "label",
                                                                  {
                                                                    staticClass:
                                                                      "radio-label-roleplay",
                                                                    attrs: {
                                                                      for: "Formative",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Formative"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "radio-group",
                                                              },
                                                              [
                                                                _c("input", {
                                                                  directives: [
                                                                    {
                                                                      name: "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        _vm
                                                                          .chosenQaItem
                                                                          .role_play,
                                                                      expression:
                                                                        "chosenQaItem.role_play",
                                                                    },
                                                                  ],
                                                                  staticClass:
                                                                    "radio-input-roleplay",
                                                                  attrs: {
                                                                    id: "Roleplay",
                                                                    type: "radio",
                                                                    value:
                                                                      "Roleplay",
                                                                    name: "conversation",
                                                                  },
                                                                  domProps: {
                                                                    checked:
                                                                      _vm._q(
                                                                        _vm
                                                                          .chosenQaItem
                                                                          .role_play,
                                                                        "Roleplay"
                                                                      ),
                                                                  },
                                                                  on: {
                                                                    change:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.$set(
                                                                          _vm.chosenQaItem,
                                                                          "role_play",
                                                                          "Roleplay"
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                                _c(
                                                                  "label",
                                                                  {
                                                                    staticClass:
                                                                      "radio-label-roleplay",
                                                                    attrs: {
                                                                      for: "Roleplay",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Role Play"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "radio-group",
                                                              },
                                                              [
                                                                _c("input", {
                                                                  directives: [
                                                                    {
                                                                      name: "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        _vm
                                                                          .chosenQaItem
                                                                          .role_play,
                                                                      expression:
                                                                        "chosenQaItem.role_play",
                                                                    },
                                                                  ],
                                                                  staticClass:
                                                                    "radio-input-roleplay",
                                                                  attrs: {
                                                                    id: "Summative",
                                                                    type: "radio",
                                                                    value:
                                                                      "Summative",
                                                                    name: "conversation",
                                                                  },
                                                                  domProps: {
                                                                    checked:
                                                                      _vm._q(
                                                                        _vm
                                                                          .chosenQaItem
                                                                          .role_play,
                                                                        "Summative"
                                                                      ),
                                                                  },
                                                                  on: {
                                                                    change:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.$set(
                                                                          _vm.chosenQaItem,
                                                                          "role_play",
                                                                          "Summative"
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                                _c(
                                                                  "label",
                                                                  {
                                                                    staticClass:
                                                                      "radio-label-roleplay",
                                                                    attrs: {
                                                                      for: "Summative",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Summative"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "radio-group",
                                                              },
                                                              [
                                                                _c("input", {
                                                                  directives: [
                                                                    {
                                                                      name: "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        _vm
                                                                          .chosenQaItem
                                                                          .role_play,
                                                                      expression:
                                                                        "chosenQaItem.role_play",
                                                                    },
                                                                  ],
                                                                  staticClass:
                                                                    "radio-input-roleplay",
                                                                  attrs: {
                                                                    id: "Discussion",
                                                                    type: "radio",
                                                                    value:
                                                                      "Discussion",
                                                                    name: "conversation",
                                                                  },
                                                                  domProps: {
                                                                    checked:
                                                                      _vm._q(
                                                                        _vm
                                                                          .chosenQaItem
                                                                          .role_play,
                                                                        "Discussion"
                                                                      ),
                                                                  },
                                                                  on: {
                                                                    change:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.$set(
                                                                          _vm.chosenQaItem,
                                                                          "role_play",
                                                                          "Discussion"
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                                _c(
                                                                  "label",
                                                                  {
                                                                    staticClass:
                                                                      "radio-label-roleplay",
                                                                    attrs: {
                                                                      for: "Discussion",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Discussion"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "radio-group-container",
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "form-label-roleplay",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Generative AI Hint Type"
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "radio-group-roleplay",
                                                              },
                                                              [
                                                                _c("input", {
                                                                  directives: [
                                                                    {
                                                                      name: "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        _vm
                                                                          .chosenQaItem
                                                                          .hint_type,
                                                                      expression:
                                                                        "chosenQaItem.hint_type",
                                                                    },
                                                                  ],
                                                                  staticClass:
                                                                    "radio-input-roleplay",
                                                                  attrs: {
                                                                    id: "Feedback",
                                                                    type: "radio",
                                                                    value:
                                                                      "Feedback",
                                                                    name: "hint",
                                                                  },
                                                                  domProps: {
                                                                    checked:
                                                                      _vm._q(
                                                                        _vm
                                                                          .chosenQaItem
                                                                          .hint_type,
                                                                        "Feedback"
                                                                      ),
                                                                  },
                                                                  on: {
                                                                    change:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.$set(
                                                                          _vm.chosenQaItem,
                                                                          "hint_type",
                                                                          "Feedback"
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                                _c(
                                                                  "label",
                                                                  {
                                                                    staticClass:
                                                                      "radio-label-roleplay",
                                                                    attrs: {
                                                                      for: "Feedback",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Feedback"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "radio-group",
                                                              },
                                                              [
                                                                _c("input", {
                                                                  directives: [
                                                                    {
                                                                      name: "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        _vm
                                                                          .chosenQaItem
                                                                          .hint_type,
                                                                      expression:
                                                                        "chosenQaItem.hint_type",
                                                                    },
                                                                  ],
                                                                  staticClass:
                                                                    "radio-input-roleplay",
                                                                  attrs: {
                                                                    id: "Answer",
                                                                    type: "radio",
                                                                    value:
                                                                      "Answer",
                                                                    name: "hint",
                                                                  },
                                                                  domProps: {
                                                                    checked:
                                                                      _vm._q(
                                                                        _vm
                                                                          .chosenQaItem
                                                                          .hint_type,
                                                                        "Answer"
                                                                      ),
                                                                  },
                                                                  on: {
                                                                    change:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.$set(
                                                                          _vm.chosenQaItem,
                                                                          "hint_type",
                                                                          "Answer"
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                                _c(
                                                                  "label",
                                                                  {
                                                                    staticClass:
                                                                      "radio-label-roleplay",
                                                                    attrs: {
                                                                      for: "Answer",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Answer"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "radio-group-container",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "form-group",
                                                              },
                                                              [
                                                                _c("label", [
                                                                  _vm._v(
                                                                    "Generative Feedback Type"
                                                                  ),
                                                                ]),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "d-flex",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "trigger-item",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "label",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                for: "static_generative_feedback_type",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "Static"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "input",
                                                                          {
                                                                            directives:
                                                                              [
                                                                                {
                                                                                  name: "model",
                                                                                  rawName:
                                                                                    "v-model",
                                                                                  value:
                                                                                    _vm
                                                                                      .chosenQaItem
                                                                                      .generative_feedback_type,
                                                                                  expression:
                                                                                    "chosenQaItem.generative_feedback_type",
                                                                                },
                                                                              ],
                                                                            attrs:
                                                                              {
                                                                                type: "radio",
                                                                                id: "static_generative_feedback_type",
                                                                                name: "static_generative_feedback_type",
                                                                              },
                                                                            domProps:
                                                                              {
                                                                                value:
                                                                                  "static",
                                                                                checked:
                                                                                  _vm._q(
                                                                                    _vm
                                                                                      .chosenQaItem
                                                                                      .generative_feedback_type,
                                                                                    "static"
                                                                                  ),
                                                                              },
                                                                            on: {
                                                                              change:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.$set(
                                                                                    _vm.chosenQaItem,
                                                                                    "generative_feedback_type",
                                                                                    "static"
                                                                                  )
                                                                                },
                                                                            },
                                                                          }
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "trigger-item",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "label",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                for: "dynamic_generative_feedback_type",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "Dynamic"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "input",
                                                                          {
                                                                            directives:
                                                                              [
                                                                                {
                                                                                  name: "model",
                                                                                  rawName:
                                                                                    "v-model",
                                                                                  value:
                                                                                    _vm
                                                                                      .chosenQaItem
                                                                                      .generative_feedback_type,
                                                                                  expression:
                                                                                    "chosenQaItem.generative_feedback_type",
                                                                                },
                                                                              ],
                                                                            attrs:
                                                                              {
                                                                                type: "radio",
                                                                                id: "dynamic_generative_feedback_type",
                                                                                name: "dynamic_generative_feedback_type",
                                                                              },
                                                                            domProps:
                                                                              {
                                                                                value:
                                                                                  "dynamic",
                                                                                checked:
                                                                                  _vm._q(
                                                                                    _vm
                                                                                      .chosenQaItem
                                                                                      .generative_feedback_type,
                                                                                    "dynamic"
                                                                                  ),
                                                                              },
                                                                            on: {
                                                                              change:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.$set(
                                                                                    _vm.chosenQaItem,
                                                                                    "generative_feedback_type",
                                                                                    "dynamic"
                                                                                  )
                                                                                },
                                                                            },
                                                                          }
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "label",
                                                          {
                                                            staticClass:
                                                              "form-label-roleplay",
                                                          },
                                                          [_vm._v("Penalty")]
                                                        ),
                                                        _vm._l(
                                                          _vm.chosenQaItem
                                                            .penalty,
                                                          function (
                                                            penalt,
                                                            index
                                                          ) {
                                                            return _c(
                                                              "div",
                                                              { key: index },
                                                              [
                                                                _c("input", {
                                                                  directives: [
                                                                    {
                                                                      name: "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        _vm
                                                                          .chosenQaItem
                                                                          .penalty[
                                                                          index
                                                                        ],
                                                                      expression:
                                                                        "chosenQaItem.penalty[index]",
                                                                    },
                                                                  ],
                                                                  staticClass:
                                                                    "form-input-roleplay",
                                                                  attrs: {
                                                                    type: "text",
                                                                  },
                                                                  domProps: {
                                                                    value:
                                                                      _vm
                                                                        .chosenQaItem
                                                                        .penalty[
                                                                        index
                                                                      ],
                                                                  },
                                                                  on: {
                                                                    input:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        if (
                                                                          $event
                                                                            .target
                                                                            .composing
                                                                        )
                                                                          return
                                                                        _vm.$set(
                                                                          _vm
                                                                            .chosenQaItem
                                                                            .penalty,
                                                                          index,
                                                                          $event
                                                                            .target
                                                                            .value
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                      ],
                                                      2
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "form-group-roleplay",
                                                      },
                                                      [
                                                        _c(
                                                          "label",
                                                          {
                                                            staticClass:
                                                              "form-label-roleplay",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Try Limitation"
                                                            ),
                                                          ]
                                                        ),
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                _vm.chosenQaItem
                                                                  .try_limitation,
                                                              expression:
                                                                "chosenQaItem.try_limitation",
                                                            },
                                                          ],
                                                          staticClass:
                                                            "form-input-roleplay",
                                                          attrs: {
                                                            type: "text",
                                                          },
                                                          domProps: {
                                                            value:
                                                              _vm.chosenQaItem
                                                                .try_limitation,
                                                          },
                                                          on: {
                                                            change: function (
                                                              $event
                                                            ) {
                                                              return _vm.changePenaltyNumbers()
                                                            },
                                                            input: function (
                                                              $event
                                                            ) {
                                                              if (
                                                                $event.target
                                                                  .composing
                                                              )
                                                                return
                                                              _vm.$set(
                                                                _vm.chosenQaItem,
                                                                "try_limitation",
                                                                $event.target
                                                                  .value
                                                              )
                                                            },
                                                          },
                                                        }),
                                                        _vm.chosenQaItem
                                                          .hint_type == "Answer"
                                                          ? _c(
                                                              "label",
                                                              {
                                                                staticClass:
                                                                  "form-label-roleplay",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Answer Hint"
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        _vm._l(
                                                          _vm.chosenQaItem
                                                            .answer_hint,
                                                          function (
                                                            hint,
                                                            index
                                                          ) {
                                                            return _c(
                                                              "div",
                                                              { key: index },
                                                              [
                                                                _c("input", {
                                                                  directives: [
                                                                    {
                                                                      name: "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        _vm
                                                                          .chosenQaItem
                                                                          .answer_hint[
                                                                          index
                                                                        ],
                                                                      expression:
                                                                        "chosenQaItem.answer_hint[index]",
                                                                    },
                                                                  ],
                                                                  staticClass:
                                                                    "form-input-roleplay",
                                                                  attrs: {
                                                                    type: "text",
                                                                  },
                                                                  domProps: {
                                                                    value:
                                                                      _vm
                                                                        .chosenQaItem
                                                                        .answer_hint[
                                                                        index
                                                                      ],
                                                                  },
                                                                  on: {
                                                                    input:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        if (
                                                                          $event
                                                                            .target
                                                                            .composing
                                                                        )
                                                                          return
                                                                        _vm.$set(
                                                                          _vm
                                                                            .chosenQaItem
                                                                            .answer_hint,
                                                                          index,
                                                                          $event
                                                                            .target
                                                                            .value
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                        _vm.chosenQaItem
                                                          .answer_hint
                                                          ? _c("div", [
                                                              _c("input", {
                                                                directives: [
                                                                  {
                                                                    name: "model",
                                                                    rawName:
                                                                      "v-model",
                                                                    value:
                                                                      _vm
                                                                        .chosenQaItem
                                                                        .answer_hint[0],
                                                                    expression:
                                                                      "chosenQaItem.answer_hint[0]",
                                                                  },
                                                                ],
                                                                staticClass:
                                                                  "form-input-roleplay",
                                                                attrs: {
                                                                  type: "text",
                                                                },
                                                                domProps: {
                                                                  value:
                                                                    _vm
                                                                      .chosenQaItem
                                                                      .answer_hint[0],
                                                                },
                                                                on: {
                                                                  input:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      if (
                                                                        $event
                                                                          .target
                                                                          .composing
                                                                      )
                                                                        return
                                                                      _vm.$set(
                                                                        _vm
                                                                          .chosenQaItem
                                                                          .answer_hint,
                                                                        0,
                                                                        $event
                                                                          .target
                                                                          .value
                                                                      )
                                                                    },
                                                                },
                                                              }),
                                                            ])
                                                          : _vm._e(),
                                                      ],
                                                      2
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn primary",
                                    on: { click: _vm.saveQuestion },
                                  },
                                  [_vm._v(" Save Question ")]
                                ),
                              ]),
                            ]),
                          ])
                        : _c("div", { staticClass: "prompts-edit" }, [
                            _vm._m(6),
                          ]),
                    ]),
                  ]),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
      _vm.isActiveTab == "chat-testing"
        ? _c("div", { staticClass: "setup-content" }, [
            _c("div", { staticClass: "testing-header" }, [
              _c("h4", { staticClass: "head-title" }, [
                _vm._v(_vm._s(_vm.name)),
              ]),
              _c("h5", { domProps: { innerHTML: _vm._s(_vm.description) } }),
            ]),
            _c(
              "div",
              { staticClass: "learner-container" },
              [
                _vm._l(_vm.messages, function (message) {
                  return _c(
                    "div",
                    { key: message.id, staticClass: "messages-area" },
                    [
                      _c("transition", { attrs: { name: "up" } }, [
                        message.question
                          ? _c("div", { staticClass: "bubble robot-bubble" }, [
                              _vm._v(" " + _vm._s(message.question) + " "),
                            ])
                          : _vm._e(),
                      ]),
                      _c("transition", { attrs: { name: "up" } }, [
                        message.answer
                          ? _c(
                              "div",
                              { staticClass: "bubble learner-bubble" },
                              [_vm._v(" " + _vm._s(message.answer) + " ")]
                            )
                          : _vm._e(),
                      ]),
                      _c("transition", { attrs: { name: "up" } }, [
                        message.response
                          ? _c("div", { staticClass: "bubble robot-bubble" }, [
                              _vm._v(" " + _vm._s(message.response) + " "),
                            ])
                          : _vm._e(),
                      ]),
                    ],
                    1
                  )
                }),
                _c("transition", { attrs: { name: "fade" } }, [
                  _vm.hasStarted
                    ? _c("div", { staticClass: "spacer" })
                    : _vm._e(),
                ]),
                _c(
                  "div",
                  { staticClass: "chat" },
                  [
                    _c("transition", { attrs: { name: "fade" } }, [
                      _vm.loading
                        ? _c("div", [_vm._v("Your answer is being processed.")])
                        : _vm._e(),
                    ]),
                    _c("transition", { attrs: { name: "fade" } }, [
                      _vm.assessmentLoading
                        ? _c("div", [_vm._v("Your assessment is processing.")])
                        : _vm._e(),
                    ]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.userAnswer,
                          expression: "userAnswer",
                        },
                      ],
                      staticClass: "input-area",
                      attrs: {
                        disabled: _vm.loading || _vm.assessmentLoading,
                        type: "text",
                      },
                      domProps: { value: _vm.userAnswer },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.userAnswer = $event.target.value
                        },
                      },
                    }),
                    _c("div", { staticClass: "button-container" }, [
                      _c(
                        "button",
                        {
                          staticClass: "button",
                          attrs: {
                            disabled: _vm.loading || _vm.assessmentLoading,
                          },
                          on: { click: _vm.submitMessage },
                        },
                        [_vm._v(" Submit your message ")]
                      ),
                      _vm.hasStarted
                        ? _c(
                            "button",
                            {
                              staticClass: "button",
                              attrs: {
                                disabled: _vm.loading || _vm.assessmentLoading,
                              },
                              on: { click: _vm.submitAssessmentRequest },
                            },
                            [_vm._v(" Get Conversation Assessment ")]
                          )
                        : _vm._e(),
                    ]),
                  ],
                  1
                ),
                _vm.feedback
                  ? _c(
                      "div",
                      { staticClass: "feedback" },
                      [
                        _c("transition", { attrs: { name: "fade" } }, [
                          _c("h2", [_vm._v("Your Feedback")]),
                        ]),
                        _c("transition", { attrs: { name: "fade" } }, [
                          _c("p", [_vm._v(_vm._s(_vm.feedback))]),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              2
            ),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "d-flex justify-content-between align-items-center" },
      [_c("h4", { staticClass: "head-title" }, [_vm._v("Basic Information")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "d-flex justify-content-between align-items-center" },
      [
        _c("h4", { staticClass: "head-title" }, [
          _vm._v("Knowledge Base Setup"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "feedback-request-help-container" }, [
      _c("p", { staticClass: "feedback-request-help" }, [
        _vm._v(
          " This option determines how much feedback is provided to the user "
        ),
      ]),
      _c("p", { staticClass: "feedback-request-help" }, [
        _vm._v(
          ' If "All" is selected, both the prompt text and the appropriate feedback request item will be provided. '
        ),
      ]),
      _c("p", { staticClass: "feedback-request-help" }, [
        _vm._v(
          ' If "Hit" is selected, the appropriate feedback request item will be provided along with any part of the prompt text that the user successfully hit on. '
        ),
      ]),
      _c("p", { staticClass: "feedback-request-help" }, [
        _vm._v(
          ' If "None" is selected, only the appropriate feedback request item will be provided. '
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _c("h4", { staticClass: "mb-5" }, [_vm._v("Feedback Request Items")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "d-flex" }, [
      _c("h4", { staticClass: "mb-5" }, [_vm._v("Assessment Knowledge Base")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "d-flex border-b-2 mb-6 pb-2" }, [
      _c("p", [_vm._v("Knowledge base entries")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "edit-body" }, [
      _c("h4", [_vm._v("No Dialogic Questions. Please add one.")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }