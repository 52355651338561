var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "login-container", attrs: { id: "login" } }, [
    _c("img", {
      staticClass: "login-logo",
      attrs: {
        src: require("../assets/images/ametros-learning-logo.svg"),
        alt: "ametros logo",
      },
    }),
    _c("div", { staticClass: "login-form" }, [
      _c("h1", [_vm._v("Login")]),
      _c("div", { staticClass: "form-group" }, [
        _c("label", [_vm._v("Username")]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.input.username,
              expression: "input.username",
            },
          ],
          staticClass: "form-control",
          attrs: { type: "text", name: "username" },
          domProps: { value: _vm.input.username },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.input, "username", $event.target.value)
            },
          },
        }),
      ]),
      _c("div", { staticClass: "form-group" }, [
        _c("label", [_vm._v("Password")]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.input.password,
              expression: "input.password",
            },
          ],
          staticClass: "form-control",
          attrs: { type: "password", name: "password" },
          domProps: { value: _vm.input.password },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.input, "password", $event.target.value)
            },
          },
        }),
      ]),
      _c("div", { staticClass: "text-right" }, [
        _c(
          "button",
          {
            staticClass: "btn primary",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                return _vm.login()
              },
            },
          },
          [_vm._v("Login")]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }