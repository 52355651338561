import { render, staticRenderFns } from "./Note.vue?vue&type=template&id=c6579b78"
import script from "./Note.vue?vue&type=script&lang=js"
export * from "./Note.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/tmp/build_fad2616a/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('c6579b78')) {
      api.createRecord('c6579b78', component.options)
    } else {
      api.reload('c6579b78', component.options)
    }
    module.hot.accept("./Note.vue?vue&type=template&id=c6579b78", function () {
      api.rerender('c6579b78', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/modal/Note.vue"
export default component.exports