var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: {
        name: "createCourse",
        height: "auto",
        width: 750,
        scrollable: true,
      },
      on: { "before-open": _vm.beforeOpen },
    },
    [
      _c("div", { staticClass: "edit-courses-dialog" }, [
        _c("div", { staticClass: "modal-content" }, [
          _c("div", { staticClass: "edit-courses-page" }, [
            _c("div", { staticClass: "modal-header" }, [
              _c(
                "div",
                {
                  staticClass: "modal-title",
                  attrs: { id: "exampleModalLabel" },
                },
                [
                  _vm.customer_id
                    ? _c("h5", [_vm._v("Edit Course")])
                    : _c("h5", [_vm._v("Add New Course")]),
                ]
              ),
              _c(
                "button",
                {
                  staticClass: "close",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.hide("createCourse")
                    },
                  },
                },
                [_c("em", { staticClass: "icon-close" })]
              ),
            ]),
            _c(
              "div",
              { staticClass: "modal-body" },
              [
                _c("ValidationObserver", { ref: "form" }, [
                  _c("form", { staticClass: "modal-form" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", { staticClass: "form-label" }, [
                          _vm._v("Course Name"),
                        ]),
                        _c("ValidationProvider", {
                          attrs: { name: "Name", rules: "required" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({ errors }) {
                                return [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.courseName,
                                        expression: "courseName",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    class: [
                                      _vm.isActive && errors[0]
                                        ? "invalid"
                                        : "",
                                    ],
                                    attrs: {
                                      type: "text",
                                      id: "courseName",
                                      "aria-describedby": "emailHelp",
                                    },
                                    domProps: { value: _vm.courseName },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.courseName = $event.target.value
                                      },
                                    },
                                  }),
                                  errors[0]
                                    ? _c(
                                        "span",
                                        {
                                          class: [
                                            _vm.isActive ? "invalid" : "",
                                          ],
                                          style: [
                                            _vm.isActive
                                              ? { display: "block" }
                                              : { display: "none" },
                                          ],
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              errors[0].replace("The ", "")
                                            )
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "form-group" }, [
                      _c(
                        "label",
                        {
                          staticClass: "form-label",
                          attrs: { for: "exampleInputEmail1" },
                        },
                        [_vm._v("Search Customer")]
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.searchCharacters,
                            expression: "searchCharacters",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          placeholder: "Search Customer...",
                        },
                        domProps: { value: _vm.searchCharacters },
                        on: {
                          input: [
                            function ($event) {
                              if ($event.target.composing) return
                              _vm.searchCharacters = $event.target.value
                            },
                            _vm.getCharacters,
                          ],
                        },
                      }),
                      _vm.charactersList.length
                        ? _c("div", [
                            _c(
                              "div",
                              {
                                staticClass: "dropdown-menu",
                                class: [
                                  _vm.charactersList.length !== 0 ? "show" : "",
                                ],
                              },
                              [
                                _c(
                                  "ul",
                                  { staticClass: "dropdown-list" },
                                  _vm._l(
                                    _vm.charactersList,
                                    function (characters) {
                                      return _c(
                                        "li",
                                        {
                                          key: characters.id,
                                          staticClass: "dropdown-items",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "dropdown-content" },
                                            [
                                              _c("h4", [
                                                _vm._v(
                                                  _vm._s(
                                                    characters.attributes.name
                                                  )
                                                ),
                                              ]),
                                            ]
                                          ),
                                          !characters.status
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "dropdown-action",
                                                },
                                                [
                                                  _c(
                                                    "a",
                                                    {
                                                      staticClass: "add-action",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.addCharacters(
                                                            characters
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("+")]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          characters.status
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "dropdown-action",
                                                },
                                                [
                                                  _c("em", {
                                                    staticClass: "icon-check",
                                                  }),
                                                  _c("span", [_vm._v("Added")]),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                    ]),
                    _vm.addedCharacters.name
                      ? _c("div", { staticClass: "contact-list-section" }, [
                          _c("div", { staticClass: "list-container" }, [
                            _c("div", { staticClass: "list-box" }, [
                              _c("div", { staticClass: "left-section" }, [
                                _vm.addCharacters.photo_url != null
                                  ? _c("div", { staticClass: "image-box" }, [
                                      _c("img", {
                                        staticClass: "module-img",
                                        attrs: {
                                          alt: "images",
                                          src: _vm.addCharacters.photo_url,
                                        },
                                      }),
                                    ])
                                  : _vm._e(),
                                _c("h3", { staticClass: "contact-text" }, [
                                  _vm._v(_vm._s(_vm.addedCharacters.name)),
                                ]),
                              ]),
                              _c("div", { staticClass: "right-section" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "remove-link",
                                    on: {
                                      click: function ($event) {
                                        return _vm.removeCharcter()
                                      },
                                    },
                                  },
                                  [_vm._v("Remove")]
                                ),
                              ]),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "courses-tab-section" }, [
                      _c("ul", { staticClass: "tab-section" }, [
                        _c(
                          "li",
                          {
                            class: _vm.tab === "address" ? "tab active" : "tab",
                            on: {
                              click: function ($event) {
                                return _vm.setTab("address")
                              },
                            },
                          },
                          [
                            _c(
                              "a",
                              {
                                class:
                                  _vm.tab === "address"
                                    ? "link link-active"
                                    : "link",
                              },
                              [_vm._v("Module(s)")]
                            ),
                          ]
                        ),
                        _c(
                          "li",
                          {
                            class: _vm.tab === "payment" ? "tab active" : "tab",
                            on: {
                              click: function ($event) {
                                return _vm.setTab("payment")
                              },
                            },
                          },
                          [
                            _c(
                              "a",
                              {
                                class:
                                  _vm.tab === "payment"
                                    ? "link link-active"
                                    : "link",
                              },
                              [_vm._v("Payment")]
                            ),
                          ]
                        ),
                        _c(
                          "li",
                          {
                            class: _vm.tab === "lti" ? "tab active" : "tab",
                            on: {
                              click: function ($event) {
                                return _vm.setTab("lti")
                              },
                            },
                          },
                          [
                            _c(
                              "a",
                              {
                                class:
                                  _vm.tab === "lti"
                                    ? "link link-active"
                                    : "link",
                              },
                              [_vm._v("LTI")]
                            ),
                          ]
                        ),
                        _c(
                          "li",
                          {
                            class: _vm.tab === "terms" ? "tab active" : "tab",
                            on: {
                              click: function ($event) {
                                return _vm.setTab("terms")
                              },
                            },
                          },
                          [
                            _c(
                              "a",
                              {
                                class:
                                  _vm.tab === "terms"
                                    ? "link link-active"
                                    : "link",
                              },
                              [_vm._v("Terms")]
                            ),
                          ]
                        ),
                        _c(
                          "li",
                          {
                            class:
                              _vm.tab === "instructor" ? "tab active" : "tab",
                            on: {
                              click: function ($event) {
                                return _vm.setTab("instructor")
                              },
                            },
                          },
                          [
                            _c(
                              "a",
                              {
                                class:
                                  _vm.tab === "instructor"
                                    ? "link link-active"
                                    : "link",
                              },
                              [_vm._v("Instructor(s)")]
                            ),
                          ]
                        ),
                        _c(
                          "li",
                          {
                            class:
                              _vm.tab === "plagiarism" ? "tab active" : "tab",
                            on: {
                              click: function ($event) {
                                return _vm.setTab("plagiarism")
                              },
                            },
                          },
                          [
                            _c(
                              "a",
                              {
                                class:
                                  _vm.tab === "plagiarism"
                                    ? "link link-active"
                                    : "link",
                              },
                              [_vm._v("Plagiarism")]
                            ),
                          ]
                        ),
                      ]),
                      _vm.tab === "address"
                        ? _c(
                            "div",
                            {
                              staticClass: "tab-detail-show",
                              attrs: { id: "address" },
                            },
                            [
                              _c("div", { staticClass: "add-module-section" }, [
                                _c("div", { staticClass: "module-input" }, [
                                  _c("div", { staticClass: "form-group" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.searchModule,
                                          expression: "searchModule",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        type: "text",
                                        placeholder: "Search Module...",
                                      },
                                      domProps: { value: _vm.searchModule },
                                      on: {
                                        input: [
                                          function ($event) {
                                            if ($event.target.composing) return
                                            _vm.searchModule =
                                              $event.target.value
                                          },
                                          _vm.getModules,
                                        ],
                                      },
                                    }),
                                    _vm.modulesList.length
                                      ? _c("div", [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "dropdown-menu",
                                              class: [
                                                _vm.modulesList.length !== 0
                                                  ? "show"
                                                  : "",
                                              ],
                                            },
                                            [
                                              _c(
                                                "ul",
                                                {
                                                  staticClass: "dropdown-list",
                                                },
                                                _vm._l(
                                                  _vm.modulesList,
                                                  function (module) {
                                                    return _c(
                                                      "li",
                                                      {
                                                        key: module.id,
                                                        staticClass:
                                                          "dropdown-items",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "dropdown-content",
                                                          },
                                                          [
                                                            _c("h4", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  module
                                                                    .attributes
                                                                    .name
                                                                )
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                        !module.status
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "dropdown-action",
                                                              },
                                                              [
                                                                _c(
                                                                  "a",
                                                                  {
                                                                    staticClass:
                                                                      "add-action",
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.addModules(
                                                                            module
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [_vm._v("+")]
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        module.status
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "dropdown-action",
                                                              },
                                                              [
                                                                _c("em", {
                                                                  staticClass:
                                                                    "icon-check",
                                                                }),
                                                                _c("span", [
                                                                  _vm._v(
                                                                    "Added"
                                                                  ),
                                                                ]),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              ),
                                            ]
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]),
                                ]),
                              ]),
                              _vm.addedModules.length > 0
                                ? _c(
                                    "div",
                                    { staticClass: "module-box-section" },
                                    [
                                      _c(
                                        "draggable",
                                        {
                                          model: {
                                            value: _vm.addedModules,
                                            callback: function ($$v) {
                                              _vm.addedModules = $$v
                                            },
                                            expression: "addedModules",
                                          },
                                        },
                                        _vm._l(
                                          _vm.addedModules,
                                          function (module, index) {
                                            return _c(
                                              "div",
                                              {
                                                key: module.module_id,
                                                staticClass: "module-box",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "detail-left",
                                                  },
                                                  [
                                                    module.photoUrl != false
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "image-box",
                                                          },
                                                          [
                                                            _c("img", {
                                                              staticClass:
                                                                "module-img",
                                                              attrs: {
                                                                alt: "images",
                                                                src: module.photoUrl,
                                                              },
                                                            }),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "detail-text",
                                                      },
                                                      [
                                                        _c(
                                                          "h3",
                                                          {
                                                            staticClass:
                                                              "title-module",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  module.moduleName
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "pg-module",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  module.worldName
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "remove-link",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.removeModule(
                                                          index
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "a",
                                                      {
                                                        staticClass:
                                                          "remove-text",
                                                      },
                                                      [_vm._v("Remove")]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c("div", { staticClass: "checkbox-section" }, [
                                _c("div", { staticClass: "form-checkboxes" }, [
                                  _c(
                                    "label",
                                    { staticClass: "custom-checkbox" },
                                    [
                                      _vm._v(
                                        " Learner must complete modules in order "
                                      ),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.sequential_mod_access,
                                            expression: "sequential_mod_access",
                                          },
                                        ],
                                        attrs: { type: "checkbox" },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.sequential_mod_access
                                          )
                                            ? _vm._i(
                                                _vm.sequential_mod_access,
                                                null
                                              ) > -1
                                            : _vm.sequential_mod_access,
                                        },
                                        on: {
                                          change: function ($event) {
                                            var $$a = _vm.sequential_mod_access,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  (_vm.sequential_mod_access =
                                                    $$a.concat([$$v]))
                                              } else {
                                                $$i > -1 &&
                                                  (_vm.sequential_mod_access =
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      ))
                                              }
                                            } else {
                                              _vm.sequential_mod_access = $$c
                                            }
                                          },
                                        },
                                      }),
                                      _c("span", { staticClass: "checkmark" }),
                                    ]
                                  ),
                                ]),
                              ]),
                              _c("div", { staticClass: "checkbox-section" }, [
                                _c("div", { staticClass: "form-checkboxes" }, [
                                  _c(
                                    "label",
                                    { staticClass: "custom-checkbox" },
                                    [
                                      _vm._v(
                                        " Learner is delayed 12 hours from accessing the next module. This option can only be selected if the learner must complete modules in order. "
                                      ),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.sequential_24_hour_delay,
                                            expression:
                                              "sequential_24_hour_delay",
                                          },
                                        ],
                                        attrs: {
                                          type: "checkbox",
                                          disabled: !_vm.sequential_mod_access,
                                        },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.sequential_24_hour_delay
                                          )
                                            ? _vm._i(
                                                _vm.sequential_24_hour_delay,
                                                null
                                              ) > -1
                                            : _vm.sequential_24_hour_delay,
                                        },
                                        on: {
                                          change: function ($event) {
                                            var $$a =
                                                _vm.sequential_24_hour_delay,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  (_vm.sequential_24_hour_delay =
                                                    $$a.concat([$$v]))
                                              } else {
                                                $$i > -1 &&
                                                  (_vm.sequential_24_hour_delay =
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      ))
                                              }
                                            } else {
                                              _vm.sequential_24_hour_delay = $$c
                                            }
                                          },
                                        },
                                      }),
                                      _c("span", { staticClass: "checkmark" }),
                                    ]
                                  ),
                                ]),
                              ]),
                              _c("div", { staticClass: "checkbox-section" }, [
                                _c("div", { staticClass: "form-checkboxes" }, [
                                  _c(
                                    "label",
                                    { staticClass: "custom-checkbox" },
                                    [
                                      _vm._v(" Disable learner retries "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.disableLearnerRetries,
                                            expression: "disableLearnerRetries",
                                          },
                                        ],
                                        attrs: { type: "checkbox" },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.disableLearnerRetries
                                          )
                                            ? _vm._i(
                                                _vm.disableLearnerRetries,
                                                null
                                              ) > -1
                                            : _vm.disableLearnerRetries,
                                        },
                                        on: {
                                          change: function ($event) {
                                            var $$a = _vm.disableLearnerRetries,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  (_vm.disableLearnerRetries =
                                                    $$a.concat([$$v]))
                                              } else {
                                                $$i > -1 &&
                                                  (_vm.disableLearnerRetries =
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      ))
                                              }
                                            } else {
                                              _vm.disableLearnerRetries = $$c
                                            }
                                          },
                                        },
                                      }),
                                      _c("span", { staticClass: "checkmark" }),
                                    ]
                                  ),
                                ]),
                              ]),
                              _c("div", { staticClass: "checkbox-section" }, [
                                _c("div", { staticClass: "form-checkboxes" }, [
                                  _c(
                                    "label",
                                    { staticClass: "custom-checkbox" },
                                    [
                                      _vm._v(
                                        " Enable certificate of completion "
                                      ),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.enableCertificateOfCompletion,
                                            expression:
                                              "enableCertificateOfCompletion",
                                          },
                                        ],
                                        attrs: { type: "checkbox" },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.enableCertificateOfCompletion
                                          )
                                            ? _vm._i(
                                                _vm.enableCertificateOfCompletion,
                                                null
                                              ) > -1
                                            : _vm.enableCertificateOfCompletion,
                                        },
                                        on: {
                                          change: function ($event) {
                                            var $$a =
                                                _vm.enableCertificateOfCompletion,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  (_vm.enableCertificateOfCompletion =
                                                    $$a.concat([$$v]))
                                              } else {
                                                $$i > -1 &&
                                                  (_vm.enableCertificateOfCompletion =
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      ))
                                              }
                                            } else {
                                              _vm.enableCertificateOfCompletion =
                                                $$c
                                            }
                                          },
                                        },
                                      }),
                                      _c("span", { staticClass: "checkmark" }),
                                    ]
                                  ),
                                  _vm.enableCertificateOfCompletion
                                    ? _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.customCertificateTitle,
                                            expression:
                                              "customCertificateTitle",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          placeholder:
                                            "Type customer certificate title here",
                                          disabled:
                                            !_vm.enableCertificateOfCompletion,
                                        },
                                        domProps: {
                                          value: _vm.customCertificateTitle,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.customCertificateTitle =
                                              $event.target.value
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                ]),
                              ]),
                              _c("div", { staticClass: "checkbox-section" }, [
                                _c("div", { staticClass: "form-checkboxes" }, [
                                  _c(
                                    "label",
                                    { staticClass: "custom-checkbox" },
                                    [
                                      _vm._v(
                                        " Enable reporting interface in instructor application "
                                      ),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.enable_reporting,
                                            expression: "enable_reporting",
                                          },
                                        ],
                                        attrs: { type: "checkbox" },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.enable_reporting
                                          )
                                            ? _vm._i(
                                                _vm.enable_reporting,
                                                null
                                              ) > -1
                                            : _vm.enable_reporting,
                                        },
                                        on: {
                                          change: function ($event) {
                                            var $$a = _vm.enable_reporting,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  (_vm.enable_reporting =
                                                    $$a.concat([$$v]))
                                              } else {
                                                $$i > -1 &&
                                                  (_vm.enable_reporting = $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1)))
                                              }
                                            } else {
                                              _vm.enable_reporting = $$c
                                            }
                                          },
                                        },
                                      }),
                                      _c("span", { staticClass: "checkmark" }),
                                    ]
                                  ),
                                ]),
                              ]),
                              _vm.enable_reporting
                                ? _c(
                                    "div",
                                    { staticClass: "reporting-indent" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "checkbox-section" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "form-checkboxes" },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "custom-checkbox",
                                                },
                                                [
                                                  _vm._v(
                                                    " Enable grading report "
                                                  ),
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.enable_grading_report,
                                                        expression:
                                                          "enable_grading_report",
                                                      },
                                                    ],
                                                    attrs: { type: "checkbox" },
                                                    domProps: {
                                                      checked: Array.isArray(
                                                        _vm.enable_grading_report
                                                      )
                                                        ? _vm._i(
                                                            _vm.enable_grading_report,
                                                            null
                                                          ) > -1
                                                        : _vm.enable_grading_report,
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        var $$a =
                                                            _vm.enable_grading_report,
                                                          $$el = $event.target,
                                                          $$c = $$el.checked
                                                            ? true
                                                            : false
                                                        if (
                                                          Array.isArray($$a)
                                                        ) {
                                                          var $$v = null,
                                                            $$i = _vm._i(
                                                              $$a,
                                                              $$v
                                                            )
                                                          if ($$el.checked) {
                                                            $$i < 0 &&
                                                              (_vm.enable_grading_report =
                                                                $$a.concat([
                                                                  $$v,
                                                                ]))
                                                          } else {
                                                            $$i > -1 &&
                                                              (_vm.enable_grading_report =
                                                                $$a
                                                                  .slice(0, $$i)
                                                                  .concat(
                                                                    $$a.slice(
                                                                      $$i + 1
                                                                    )
                                                                  ))
                                                          }
                                                        } else {
                                                          _vm.enable_grading_report =
                                                            $$c
                                                        }
                                                      },
                                                    },
                                                  }),
                                                  _c("span", {
                                                    staticClass: "checkmark",
                                                  }),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "checkbox-section" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "form-checkboxes" },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "custom-checkbox",
                                                },
                                                [
                                                  _vm._v(
                                                    " Enable completion report "
                                                  ),
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.enable_completion_report,
                                                        expression:
                                                          "enable_completion_report",
                                                      },
                                                    ],
                                                    attrs: { type: "checkbox" },
                                                    domProps: {
                                                      checked: Array.isArray(
                                                        _vm.enable_completion_report
                                                      )
                                                        ? _vm._i(
                                                            _vm.enable_completion_report,
                                                            null
                                                          ) > -1
                                                        : _vm.enable_completion_report,
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        var $$a =
                                                            _vm.enable_completion_report,
                                                          $$el = $event.target,
                                                          $$c = $$el.checked
                                                            ? true
                                                            : false
                                                        if (
                                                          Array.isArray($$a)
                                                        ) {
                                                          var $$v = null,
                                                            $$i = _vm._i(
                                                              $$a,
                                                              $$v
                                                            )
                                                          if ($$el.checked) {
                                                            $$i < 0 &&
                                                              (_vm.enable_completion_report =
                                                                $$a.concat([
                                                                  $$v,
                                                                ]))
                                                          } else {
                                                            $$i > -1 &&
                                                              (_vm.enable_completion_report =
                                                                $$a
                                                                  .slice(0, $$i)
                                                                  .concat(
                                                                    $$a.slice(
                                                                      $$i + 1
                                                                    )
                                                                  ))
                                                          }
                                                        } else {
                                                          _vm.enable_completion_report =
                                                            $$c
                                                        }
                                                      },
                                                    },
                                                  }),
                                                  _c("span", {
                                                    staticClass: "checkmark",
                                                  }),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "checkbox-section" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "form-checkboxes" },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "custom-checkbox",
                                                },
                                                [
                                                  _vm._v(
                                                    " Enable assessment report "
                                                  ),
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.enable_assessment_report,
                                                        expression:
                                                          "enable_assessment_report",
                                                      },
                                                    ],
                                                    attrs: { type: "checkbox" },
                                                    domProps: {
                                                      checked: Array.isArray(
                                                        _vm.enable_assessment_report
                                                      )
                                                        ? _vm._i(
                                                            _vm.enable_assessment_report,
                                                            null
                                                          ) > -1
                                                        : _vm.enable_assessment_report,
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        var $$a =
                                                            _vm.enable_assessment_report,
                                                          $$el = $event.target,
                                                          $$c = $$el.checked
                                                            ? true
                                                            : false
                                                        if (
                                                          Array.isArray($$a)
                                                        ) {
                                                          var $$v = null,
                                                            $$i = _vm._i(
                                                              $$a,
                                                              $$v
                                                            )
                                                          if ($$el.checked) {
                                                            $$i < 0 &&
                                                              (_vm.enable_assessment_report =
                                                                $$a.concat([
                                                                  $$v,
                                                                ]))
                                                          } else {
                                                            $$i > -1 &&
                                                              (_vm.enable_assessment_report =
                                                                $$a
                                                                  .slice(0, $$i)
                                                                  .concat(
                                                                    $$a.slice(
                                                                      $$i + 1
                                                                    )
                                                                  ))
                                                          }
                                                        } else {
                                                          _vm.enable_assessment_report =
                                                            $$c
                                                        }
                                                      },
                                                    },
                                                  }),
                                                  _c("span", {
                                                    staticClass: "checkmark",
                                                  }),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _c("div", { staticClass: "checkbox-section" }, [
                                _c("div", { staticClass: "form-checkboxes" }, [
                                  _c(
                                    "label",
                                    { staticClass: "custom-checkbox" },
                                    [
                                      _vm._v(
                                        " Display section name on learner dashboard cards "
                                      ),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.learner_display_section,
                                            expression:
                                              "learner_display_section",
                                          },
                                        ],
                                        attrs: { type: "checkbox" },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.learner_display_section
                                          )
                                            ? _vm._i(
                                                _vm.learner_display_section,
                                                null
                                              ) > -1
                                            : _vm.learner_display_section,
                                        },
                                        on: {
                                          change: function ($event) {
                                            var $$a =
                                                _vm.learner_display_section,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  (_vm.learner_display_section =
                                                    $$a.concat([$$v]))
                                              } else {
                                                $$i > -1 &&
                                                  (_vm.learner_display_section =
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      ))
                                              }
                                            } else {
                                              _vm.learner_display_section = $$c
                                            }
                                          },
                                        },
                                      }),
                                      _c("span", { staticClass: "checkmark" }),
                                    ]
                                  ),
                                ]),
                              ]),
                              _c("div", { staticClass: "checkbox-section" }, [
                                _c("div", { staticClass: "form-checkboxes" }, [
                                  _c(
                                    "label",
                                    { staticClass: "custom-checkbox" },
                                    [
                                      _vm._v(
                                        " Auto-deploy sections (Campus Support LTI integration) "
                                      ),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.auto_create_sections,
                                            expression: "auto_create_sections",
                                          },
                                        ],
                                        attrs: { type: "checkbox" },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.auto_create_sections
                                          )
                                            ? _vm._i(
                                                _vm.auto_create_sections,
                                                null
                                              ) > -1
                                            : _vm.auto_create_sections,
                                        },
                                        on: {
                                          change: function ($event) {
                                            var $$a = _vm.auto_create_sections,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  (_vm.auto_create_sections =
                                                    $$a.concat([$$v]))
                                              } else {
                                                $$i > -1 &&
                                                  (_vm.auto_create_sections =
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      ))
                                              }
                                            } else {
                                              _vm.auto_create_sections = $$c
                                            }
                                          },
                                        },
                                      }),
                                      _c("span", { staticClass: "checkmark" }),
                                    ]
                                  ),
                                ]),
                              ]),
                              _c("div", { staticClass: "checkbox-section" }, [
                                _c("div", { staticClass: "form-checkboxes" }, [
                                  _c(
                                    "label",
                                    { staticClass: "custom-checkbox" },
                                    [
                                      _vm._v(" Enable LLM LO Retries "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.enable_llm_retries,
                                            expression: "enable_llm_retries",
                                          },
                                        ],
                                        attrs: { type: "checkbox" },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.enable_llm_retries
                                          )
                                            ? _vm._i(
                                                _vm.enable_llm_retries,
                                                null
                                              ) > -1
                                            : _vm.enable_llm_retries,
                                        },
                                        on: {
                                          change: function ($event) {
                                            var $$a = _vm.enable_llm_retries,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  (_vm.enable_llm_retries =
                                                    $$a.concat([$$v]))
                                              } else {
                                                $$i > -1 &&
                                                  (_vm.enable_llm_retries = $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1)))
                                              }
                                            } else {
                                              _vm.enable_llm_retries = $$c
                                            }
                                          },
                                        },
                                      }),
                                      _c("span", { staticClass: "checkmark" }),
                                    ]
                                  ),
                                ]),
                              ]),
                              _vm.enable_llm_retries
                                ? _c(
                                    "div",
                                    { staticClass: "checkbox-section" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "form-checkboxes" },
                                        [
                                          _c("label", [
                                            _vm._v(" LLM LO Retries Limit "),
                                          ]),
                                        ]
                                      ),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.llm_retries_limit,
                                            expression: "llm_retries_limit",
                                          },
                                        ],
                                        attrs: { type: "number" },
                                        domProps: {
                                          value: _vm.llm_retries_limit,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.llm_retries_limit =
                                              $event.target.value
                                          },
                                        },
                                      }),
                                    ]
                                  )
                                : _vm._e(),
                              _c("div", { staticClass: "modal-footer" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn default",
                                    attrs: { type: "button" },
                                    on: { click: _vm.hide },
                                  },
                                  [_vm._v(" Cancel ")]
                                ),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn primary",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.setTab("payment")
                                      },
                                    },
                                  },
                                  [_vm._v(" Next ")]
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                    ]),
                    _vm.tab === "payment"
                      ? _c("div", { staticClass: "courses-tab-section" }, [
                          _c(
                            "div",
                            {
                              staticClass: "tab-detail-show",
                              attrs: { id: "payment" },
                            },
                            [
                              _c("div", { staticClass: "checkbox-section" }, [
                                _c("div", { staticClass: "form-checkboxes" }, [
                                  _c(
                                    "label",
                                    { staticClass: "custom-checkbox" },
                                    [
                                      _vm._v(" No Charges "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.noCharge,
                                            expression: "noCharge",
                                          },
                                        ],
                                        attrs: { type: "checkbox" },
                                        domProps: {
                                          checked: Array.isArray(_vm.noCharge)
                                            ? _vm._i(_vm.noCharge, null) > -1
                                            : _vm.noCharge,
                                        },
                                        on: {
                                          change: function ($event) {
                                            var $$a = _vm.noCharge,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  (_vm.noCharge = $$a.concat([
                                                    $$v,
                                                  ]))
                                              } else {
                                                $$i > -1 &&
                                                  (_vm.noCharge = $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1)))
                                              }
                                            } else {
                                              _vm.noCharge = $$c
                                            }
                                          },
                                        },
                                      }),
                                      _c("span", { staticClass: "checkmark" }),
                                    ]
                                  ),
                                ]),
                              ]),
                              !_vm.noCharge
                                ? _c("div", { staticClass: "form-group" }, [
                                    _c("label", { staticClass: "form-label" }, [
                                      _vm._v("Payment Type"),
                                    ]),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "quiz-dropdown customer-quiz-dropdown",
                                      },
                                      [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-secondary dropdown-toggle",
                                            attrs: {
                                              type: "button",
                                              id: "entity",
                                              "data-toggle": "dropdown",
                                              "aria-haspopup": "true",
                                              "aria-expanded": "false",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.paymentType.name) +
                                                " "
                                            ),
                                            _c("em", {
                                              staticClass: "icon-carrot-down",
                                            }),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "dropdown-menu",
                                            attrs: {
                                              "aria-labelledby": "entity",
                                            },
                                          },
                                          _vm._l(
                                            _vm.paymentTypes,
                                            function (type) {
                                              return _c(
                                                "option",
                                                {
                                                  key: type.key,
                                                  staticClass: "dropdown-item",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.selectPaymentType(
                                                        type
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(type.name) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                              !_vm.noCharge
                                ? _c("div", { staticClass: "row" }, [
                                    _c("div", { staticClass: "col" }, [
                                      _c("div", { staticClass: "form-group" }, [
                                        _c(
                                          "label",
                                          { staticClass: "form-label" },
                                          [_vm._v("Cost Per Learner")]
                                        ),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.costPerLearner,
                                              expression: "costPerLearner",
                                            },
                                          ],
                                          staticClass: "form-control",
                                          attrs: {
                                            type: "number",
                                            id: "courseName",
                                            "aria-describedby": "emailHelp",
                                          },
                                          domProps: {
                                            value: _vm.costPerLearner,
                                          },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.costPerLearner =
                                                $event.target.value
                                            },
                                          },
                                        }),
                                      ]),
                                    ]),
                                    _c("div", { staticClass: "col" }, [
                                      _c("div", { staticClass: "form-group" }, [
                                        _c(
                                          "label",
                                          { staticClass: "form-label" },
                                          [_vm._v("Invoice Frequency")]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "quiz-dropdown customer-quiz-dropdown",
                                          },
                                          [
                                            _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn btn-secondary dropdown-toggle",
                                                attrs: {
                                                  type: "button",
                                                  id: "entity",
                                                  "data-toggle": "dropdown",
                                                  "aria-haspopup": "true",
                                                  "aria-expanded": "false",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.invoiceType.name
                                                    ) +
                                                    " "
                                                ),
                                                _c("em", {
                                                  staticClass:
                                                    "icon-carrot-down",
                                                }),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "dropdown-menu",
                                                attrs: {
                                                  "aria-labelledby": "entity",
                                                },
                                              },
                                              _vm._l(
                                                _vm.invoiceFrequency,
                                                function (type) {
                                                  return _c(
                                                    "option",
                                                    {
                                                      key: type.id,
                                                      staticClass:
                                                        "dropdown-item",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.selectInvoiceFrequency(
                                                            type
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(type.name) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]),
                                  ])
                                : _vm._e(),
                              _c("p", { staticClass: "annual-support-text" }, [
                                _vm._v(
                                  " Annual support & maintenance fee section "
                                ),
                              ]),
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col" }, [
                                  _c("div", { staticClass: "form-group" }, [
                                    _c("label", { staticClass: "form-label" }, [
                                      _vm._v("Cost"),
                                    ]),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.cost,
                                          expression: "cost",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        type: "number",
                                        id: "cost",
                                        "aria-describedby": "emailHelp",
                                      },
                                      domProps: { value: _vm.cost },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.cost = $event.target.value
                                        },
                                      },
                                    }),
                                  ]),
                                ]),
                                _c("div", { staticClass: "col" }, [
                                  _c("div", { staticClass: "form-group" }, [
                                    _c("label", { staticClass: "form-label" }, [
                                      _vm._v("Number of Learners"),
                                    ]),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.numberOfLearners,
                                          expression: "numberOfLearners",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        type: "number",
                                        id: "numberOfLearners",
                                        "aria-describedby": "emailHelp",
                                      },
                                      domProps: { value: _vm.numberOfLearners },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.numberOfLearners =
                                            $event.target.value
                                        },
                                      },
                                    }),
                                  ]),
                                ]),
                              ]),
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col" }, [
                                  _c(
                                    "div",
                                    { staticClass: "form-group" },
                                    [
                                      _c(
                                        "label",
                                        { staticClass: "form-label" },
                                        [_vm._v("Start Date")]
                                      ),
                                      _c("datepicker", {
                                        attrs: { name: "startDate" },
                                        model: {
                                          value: _vm.startDate,
                                          callback: function ($$v) {
                                            _vm.startDate = $$v
                                          },
                                          expression: "startDate",
                                        },
                                      }),
                                      _c(
                                        "div",
                                        { staticClass: "calendar-icon" },
                                        [
                                          _c("img", {
                                            staticClass: "calendar-img",
                                            attrs: {
                                              alt: "images",
                                              src: require("../../assets/images/svg/calendar-icon.svg"),
                                            },
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _c("div", { staticClass: "col" }, [
                                  _c(
                                    "div",
                                    { staticClass: "form-group" },
                                    [
                                      _c(
                                        "label",
                                        { staticClass: "form-label" },
                                        [_vm._v("End Date")]
                                      ),
                                      _c("datepicker", {
                                        attrs: { name: "endDate" },
                                        model: {
                                          value: _vm.endDate,
                                          callback: function ($$v) {
                                            _vm.endDate = $$v
                                          },
                                          expression: "endDate",
                                        },
                                      }),
                                      _c(
                                        "div",
                                        { staticClass: "calendar-icon" },
                                        [
                                          _c("img", {
                                            staticClass: "calendar-img",
                                            attrs: {
                                              alt: "images",
                                              src: require("../../assets/images/svg/calendar-icon.svg"),
                                            },
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ]),
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col" }, [
                                  _c("div", { staticClass: "form-group" }, [
                                    _c("label", { staticClass: "form-label" }, [
                                      _vm._v("Reconciled"),
                                    ]),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "quiz-dropdown customer-quiz-dropdown",
                                      },
                                      [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-secondary dropdown-toggle",
                                            attrs: {
                                              type: "button",
                                              id: "entity",
                                              "data-toggle": "dropdown",
                                              "aria-haspopup": "true",
                                              "aria-expanded": "false",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.reconciled.name) +
                                                " "
                                            ),
                                            _c("em", {
                                              staticClass: "icon-carrot-down",
                                            }),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "dropdown-menu",
                                            attrs: {
                                              "aria-labelledby": "entity",
                                            },
                                          },
                                          _vm._l(
                                            _vm.invoiceFrequency,
                                            function (type) {
                                              return _c(
                                                "option",
                                                {
                                                  key: type.id,
                                                  staticClass: "dropdown-item",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.selectReconciled(
                                                        type
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(type.name) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]),
                                _c("div", { staticClass: "col" }, [
                                  _c("div", { staticClass: "form-group" }, [
                                    _c("label", { staticClass: "form-label" }, [
                                      _vm._v("Overage Cost (per learner)"),
                                    ]),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.averageCost,
                                          expression: "averageCost",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        type: "text",
                                        id: "averageCost",
                                        "aria-describedby": "emailHelp",
                                      },
                                      domProps: { value: _vm.averageCost },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.averageCost = $event.target.value
                                        },
                                      },
                                    }),
                                  ]),
                                ]),
                              ]),
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col" }, [
                                  _c("div", { staticClass: "form-group" }, [
                                    _c("label", { staticClass: "form-label" }, [
                                      _vm._v("Currency"),
                                    ]),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "quiz-dropdown customer-quiz-dropdown",
                                      },
                                      [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-secondary dropdown-toggle",
                                            attrs: {
                                              type: "button",
                                              id: "entity",
                                              "data-toggle": "dropdown",
                                              "aria-haspopup": "true",
                                              "aria-expanded": "false",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.currency.name) +
                                                " "
                                            ),
                                            _c("em", {
                                              staticClass: "icon-carrot-down",
                                            }),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "dropdown-menu",
                                            attrs: {
                                              "aria-labelledby": "entity",
                                            },
                                          },
                                          _vm._l(
                                            _vm.currentType,
                                            function (type) {
                                              return _c(
                                                "option",
                                                {
                                                  key: type.name,
                                                  staticClass: "dropdown-item",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.selectCurrencyType(
                                                        type
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(type.name) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]),
                                _c("div", { staticClass: "col tax-rate-col" }, [
                                  _c(
                                    "div",
                                    { staticClass: "checkbox-section" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "form-checkboxes" },
                                        [
                                          _c(
                                            "label",
                                            { staticClass: "custom-checkbox" },
                                            [
                                              _vm._v(" Taxable "),
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: _vm.taxStatus,
                                                    expression: "taxStatus",
                                                  },
                                                ],
                                                attrs: { type: "checkbox" },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    _vm.taxStatus
                                                  )
                                                    ? _vm._i(
                                                        _vm.taxStatus,
                                                        null
                                                      ) > -1
                                                    : _vm.taxStatus,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    var $$a = _vm.taxStatus,
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          (_vm.taxStatus =
                                                            $$a.concat([$$v]))
                                                      } else {
                                                        $$i > -1 &&
                                                          (_vm.taxStatus = $$a
                                                            .slice(0, $$i)
                                                            .concat(
                                                              $$a.slice($$i + 1)
                                                            ))
                                                      }
                                                    } else {
                                                      _vm.taxStatus = $$c
                                                    }
                                                  },
                                                },
                                              }),
                                              _c("span", {
                                                staticClass: "checkmark",
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm.taxStatus
                                    ? _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c(
                                            "label",
                                            { staticClass: "form-label" },
                                            [_vm._v("Tax Rate")]
                                          ),
                                          _c("ValidationProvider", {
                                            attrs: {
                                              name: "Name",
                                              rules: "required",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function ({ errors }) {
                                                    return [
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value: _vm.tax,
                                                            expression: "tax",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "form-control",
                                                        class: [
                                                          _vm.isActive &&
                                                          errors[0]
                                                            ? "invalid"
                                                            : "",
                                                        ],
                                                        attrs: {
                                                          type: "number",
                                                          id: "courseName",
                                                          "aria-describedby":
                                                            "emailHelp",
                                                          max: "10",
                                                        },
                                                        domProps: {
                                                          value: _vm.tax,
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            )
                                                              return
                                                            _vm.tax =
                                                              $event.target.value
                                                          },
                                                        },
                                                      }),
                                                      errors[0]
                                                        ? _c(
                                                            "span",
                                                            {
                                                              class: [
                                                                _vm.isActive
                                                                  ? "invalid"
                                                                  : "",
                                                              ],
                                                              style: [
                                                                _vm.isActive
                                                                  ? {
                                                                      display:
                                                                        "block",
                                                                    }
                                                                  : {
                                                                      display:
                                                                        "none",
                                                                    },
                                                              ],
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0].replace(
                                                                    "The ",
                                                                    ""
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              1936422637
                                            ),
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]),
                              ]),
                            ]
                          ),
                          _c("div", { staticClass: "modal-footer" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn default",
                                attrs: { type: "button" },
                                on: { click: _vm.hide },
                              },
                              [_vm._v(" Cancel ")]
                            ),
                            _c(
                              "button",
                              {
                                staticClass: "btn primary",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.setTab("lti")
                                  },
                                },
                              },
                              [_vm._v(" Next ")]
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _vm.tab === "lti"
                      ? _c("div", { staticClass: "courses-tab-section" }, [
                          _c(
                            "div",
                            {
                              staticClass: "tab-detail-show",
                              attrs: { id: "lti" },
                            },
                            [
                              _c("div", { staticClass: "checkbox-section" }, [
                                _c("div", { staticClass: "form-checkboxes" }, [
                                  _c(
                                    "label",
                                    { staticClass: "custom-checkbox" },
                                    [
                                      _vm._v(" LTI Integration "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.ltiIntegrationStatus,
                                            expression: "ltiIntegrationStatus",
                                          },
                                        ],
                                        attrs: { type: "checkbox" },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.ltiIntegrationStatus
                                          )
                                            ? _vm._i(
                                                _vm.ltiIntegrationStatus,
                                                null
                                              ) > -1
                                            : _vm.ltiIntegrationStatus,
                                        },
                                        on: {
                                          change: function ($event) {
                                            var $$a = _vm.ltiIntegrationStatus,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  (_vm.ltiIntegrationStatus =
                                                    $$a.concat([$$v]))
                                              } else {
                                                $$i > -1 &&
                                                  (_vm.ltiIntegrationStatus =
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      ))
                                              }
                                            } else {
                                              _vm.ltiIntegrationStatus = $$c
                                            }
                                          },
                                        },
                                      }),
                                      _c("span", { staticClass: "checkmark" }),
                                    ]
                                  ),
                                ]),
                              ]),
                              _vm.ltiIntegrationStatus
                                ? _c("div", { staticClass: "form-group" }, [
                                    _c("label", { staticClass: "form-label" }, [
                                      _vm._v("LMS"),
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "add-module-section" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "quiz-dropdown customer-quiz-dropdown",
                                          },
                                          [
                                            _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn btn-secondary dropdown-toggle",
                                                attrs: {
                                                  type: "button",
                                                  id: "entity",
                                                  "data-toggle": "dropdown",
                                                  "aria-haspopup": "true",
                                                  "aria-expanded": "false",
                                                },
                                              },
                                              [
                                                _c("em", {
                                                  staticClass:
                                                    "icon-carrot-down",
                                                }),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "dropdown-menu",
                                                attrs: {
                                                  "aria-labelledby": "entity",
                                                },
                                              },
                                              _vm._l(
                                                _vm.ltiList,
                                                function (module) {
                                                  return _c(
                                                    "option",
                                                    {
                                                      key: module.id,
                                                      staticClass:
                                                        "dropdown-item",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.addLtiModule(
                                                            module
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            module.attributes
                                                              .name
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm.ltiModule && _vm.ltiModule.attributes
                                      ? _c(
                                          "div",
                                          { staticClass: "module-box-section" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "module-box" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "detail-left",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "detail-text",
                                                      },
                                                      [
                                                        _c(
                                                          "h3",
                                                          {
                                                            staticClass:
                                                              "title-module",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.ltiModule
                                                                    .attributes
                                                                    .name
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "remove-link",
                                                    on: {
                                                      click: _vm.removeLti,
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "a",
                                                      {
                                                        staticClass:
                                                          "remove-text",
                                                      },
                                                      [_vm._v("Remove")]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ])
                                : _vm._e(),
                              _vm.ltiModule && _vm.ltiModule.attributes
                                ? _c("div", { staticClass: "multi-checkbox" }, [
                                    _c(
                                      "div",
                                      { staticClass: "checkbox-section" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "form-checkboxes" },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass: "custom-checkbox",
                                              },
                                              [
                                                _vm._v(" SSO "),
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: _vm.ssdStatus,
                                                      expression: "ssdStatus",
                                                    },
                                                  ],
                                                  attrs: { type: "checkbox" },
                                                  domProps: {
                                                    checked: Array.isArray(
                                                      _vm.ssdStatus
                                                    )
                                                      ? _vm._i(
                                                          _vm.ssdStatus,
                                                          null
                                                        ) > -1
                                                      : _vm.ssdStatus,
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      var $$a = _vm.ssdStatus,
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = null,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            (_vm.ssdStatus =
                                                              $$a.concat([$$v]))
                                                        } else {
                                                          $$i > -1 &&
                                                            (_vm.ssdStatus = $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              ))
                                                        }
                                                      } else {
                                                        _vm.ssdStatus = $$c
                                                      }
                                                    },
                                                  },
                                                }),
                                                _c("span", {
                                                  staticClass: "checkmark",
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "checkbox-section" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "form-checkboxes" },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass: "custom-checkbox",
                                              },
                                              [
                                                _vm._v(" Grading "),
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: _vm.gradingStatus,
                                                      expression:
                                                        "gradingStatus",
                                                    },
                                                  ],
                                                  attrs: { type: "checkbox" },
                                                  domProps: {
                                                    checked: Array.isArray(
                                                      _vm.gradingStatus
                                                    )
                                                      ? _vm._i(
                                                          _vm.gradingStatus,
                                                          null
                                                        ) > -1
                                                      : _vm.gradingStatus,
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      var $$a =
                                                          _vm.gradingStatus,
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = null,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            (_vm.gradingStatus =
                                                              $$a.concat([$$v]))
                                                        } else {
                                                          $$i > -1 &&
                                                            (_vm.gradingStatus =
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                ))
                                                        }
                                                      } else {
                                                        _vm.gradingStatus = $$c
                                                      }
                                                    },
                                                  },
                                                }),
                                                _c("span", {
                                                  staticClass: "checkmark",
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                          _c("div", { staticClass: "modal-footer" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn default",
                                attrs: { type: "button" },
                                on: { click: _vm.hide },
                              },
                              [_vm._v(" Cancel ")]
                            ),
                            _c(
                              "button",
                              {
                                staticClass: "btn primary",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.setTab("terms")
                                  },
                                },
                              },
                              [_vm._v(" Next ")]
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _vm.tab === "terms"
                      ? _c("div", { staticClass: "courses-tab-section" }, [
                          _c(
                            "div",
                            {
                              staticClass: "tab-detail-show",
                              attrs: { id: "terms" },
                            },
                            [
                              _c("div", { staticClass: "checkbox-section" }, [
                                _c("div", { staticClass: "form-checkboxes" }, [
                                  _c(
                                    "label",
                                    { staticClass: "custom-checkbox" },
                                    [
                                      _vm._v(" Use Default "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.useDefault,
                                            expression: "useDefault",
                                          },
                                        ],
                                        attrs: { type: "checkbox" },
                                        domProps: {
                                          checked: Array.isArray(_vm.useDefault)
                                            ? _vm._i(_vm.useDefault, null) > -1
                                            : _vm.useDefault,
                                        },
                                        on: {
                                          change: function ($event) {
                                            var $$a = _vm.useDefault,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  (_vm.useDefault = $$a.concat([
                                                    $$v,
                                                  ]))
                                              } else {
                                                $$i > -1 &&
                                                  (_vm.useDefault = $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1)))
                                              }
                                            } else {
                                              _vm.useDefault = $$c
                                            }
                                          },
                                        },
                                      }),
                                      _c("span", { staticClass: "checkmark" }),
                                    ]
                                  ),
                                ]),
                              ]),
                              _c(
                                "div",
                                [
                                  _c("p", { staticClass: "custom-term-text" }, [
                                    _vm._v("Custom Terms & Conditions"),
                                  ]),
                                  _c("vue-editor", {
                                    staticClass: "form-control",
                                    attrs: { editorToolbar: _vm.fullToolbar },
                                    model: {
                                      value: _vm.terms,
                                      callback: function ($$v) {
                                        _vm.terms = $$v
                                      },
                                      expression: "terms",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c("div", { staticClass: "modal-footer" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn default",
                                attrs: { type: "button" },
                                on: { click: _vm.hide },
                              },
                              [_vm._v(" Cancel ")]
                            ),
                            _c(
                              "button",
                              {
                                staticClass: "btn primary",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.setTab("instructor")
                                  },
                                },
                              },
                              [_vm._v(" Next ")]
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _vm.tab === "instructor"
                      ? _c("div", { staticClass: "courses-tab-section" }, [
                          _c(
                            "div",
                            {
                              staticClass: "tab-detail-show",
                              attrs: { id: "instructor" },
                            },
                            [
                              _c("div", { staticClass: "checkbox-section" }, [
                                _c("div", { staticClass: "form-checkboxes" }, [
                                  _c(
                                    "label",
                                    { staticClass: "custom-checkbox" },
                                    [
                                      _vm._v(" Instructor Required? "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.instructorReqStatus,
                                            expression: "instructorReqStatus",
                                          },
                                        ],
                                        attrs: { type: "checkbox" },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.instructorReqStatus
                                          )
                                            ? _vm._i(
                                                _vm.instructorReqStatus,
                                                null
                                              ) > -1
                                            : _vm.instructorReqStatus,
                                        },
                                        on: {
                                          change: function ($event) {
                                            var $$a = _vm.instructorReqStatus,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  (_vm.instructorReqStatus =
                                                    $$a.concat([$$v]))
                                              } else {
                                                $$i > -1 &&
                                                  (_vm.instructorReqStatus = $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1)))
                                              }
                                            } else {
                                              _vm.instructorReqStatus = $$c
                                            }
                                          },
                                        },
                                      }),
                                      _c("span", { staticClass: "checkmark" }),
                                    ]
                                  ),
                                ]),
                              ]),
                              _vm.instructorReqStatus
                                ? _c("div", { staticClass: "form-group" }, [
                                    _c("label", { staticClass: "form-label" }, [
                                      _vm._v("Maximum Numbers of Instructors"),
                                    ]),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.totalInstructor,
                                          expression: "totalInstructor",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        type: "number",
                                        placeholder: "Numbers of Instructors",
                                        "aria-describedby": "emailHelp",
                                      },
                                      domProps: { value: _vm.totalInstructor },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.totalInstructor =
                                            $event.target.value
                                        },
                                      },
                                    }),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                          _c("div", { staticClass: "modal-footer" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn default",
                                attrs: { type: "button" },
                                on: { click: _vm.hide },
                              },
                              [_vm._v(" Cancel ")]
                            ),
                            _c(
                              "button",
                              {
                                staticClass: "btn primary",
                                attrs: { type: "button" },
                                on: { click: _vm.createCourses },
                              },
                              [_vm._v(" Save ")]
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _vm.tab === "plagiarism"
                      ? _c("div", { staticClass: "courses-tab-section" }, [
                          _c(
                            "div",
                            {
                              staticClass: "tab-detail-show",
                              attrs: { id: "instructor" },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "plagiarism-container" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "elm-view" },
                                    _vm._l(this.addedModules, function (elm) {
                                      return _c(
                                        "button",
                                        {
                                          key: elm.id,
                                          staticClass: "btn elm-btn",
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.getElmLOs(
                                                elm.module_id
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(elm.moduleName) + " "
                                          ),
                                        ]
                                      )
                                    }),
                                    0
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "mb-3 enable-course-plagiarism",
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.enable_course_plagiarism_detection,
                                            expression:
                                              "enable_course_plagiarism_detection",
                                          },
                                        ],
                                        attrs: {
                                          type: "checkbox",
                                          name: "enable_course_plagiarism_detection",
                                        },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.enable_course_plagiarism_detection
                                          )
                                            ? _vm._i(
                                                _vm.enable_course_plagiarism_detection,
                                                null
                                              ) > -1
                                            : _vm.enable_course_plagiarism_detection,
                                        },
                                        on: {
                                          change: function ($event) {
                                            var $$a =
                                                _vm.enable_course_plagiarism_detection,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  (_vm.enable_course_plagiarism_detection =
                                                    $$a.concat([$$v]))
                                              } else {
                                                $$i > -1 &&
                                                  (_vm.enable_course_plagiarism_detection =
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      ))
                                              }
                                            } else {
                                              _vm.enable_course_plagiarism_detection =
                                                $$c
                                            }
                                          },
                                        },
                                      }),
                                      _c(
                                        "label",
                                        {
                                          attrs: {
                                            for: "enable_course_plagiarism_detection",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "Enable plagiarism detection in this course"
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm.enable_course_plagiarism_detection
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "plagiarism-los-container",
                                        },
                                        _vm._l(
                                          _vm.plagiarismLOs,
                                          function (lo, index) {
                                            return _c(
                                              "div",
                                              {
                                                key: lo.id,
                                                staticClass: "lo-view",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "lo-view__selection",
                                                  },
                                                  [
                                                    _c(
                                                      "button",
                                                      {
                                                        staticClass:
                                                          "btn lo-view__btn",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.switchActiveLO(
                                                              lo.id
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(lo.name) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "lo-view__settings",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "lo-settings__checkbox",
                                                      },
                                                      [
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                _vm
                                                                  .plagiarismLOs[
                                                                  index
                                                                ]
                                                                  .enable_plagiarism_detection,
                                                              expression:
                                                                "plagiarismLOs[index].enable_plagiarism_detection",
                                                            },
                                                          ],
                                                          attrs: {
                                                            type: "checkbox",
                                                          },
                                                          domProps: {
                                                            checked:
                                                              Array.isArray(
                                                                _vm
                                                                  .plagiarismLOs[
                                                                  index
                                                                ]
                                                                  .enable_plagiarism_detection
                                                              )
                                                                ? _vm._i(
                                                                    _vm
                                                                      .plagiarismLOs[
                                                                      index
                                                                    ]
                                                                      .enable_plagiarism_detection,
                                                                    null
                                                                  ) > -1
                                                                : _vm
                                                                    .plagiarismLOs[
                                                                    index
                                                                  ]
                                                                    .enable_plagiarism_detection,
                                                          },
                                                          on: {
                                                            change: function (
                                                              $event
                                                            ) {
                                                              var $$a =
                                                                  _vm
                                                                    .plagiarismLOs[
                                                                    index
                                                                  ]
                                                                    .enable_plagiarism_detection,
                                                                $$el =
                                                                  $event.target,
                                                                $$c =
                                                                  $$el.checked
                                                                    ? true
                                                                    : false
                                                              if (
                                                                Array.isArray(
                                                                  $$a
                                                                )
                                                              ) {
                                                                var $$v = null,
                                                                  $$i = _vm._i(
                                                                    $$a,
                                                                    $$v
                                                                  )
                                                                if (
                                                                  $$el.checked
                                                                ) {
                                                                  $$i < 0 &&
                                                                    _vm.$set(
                                                                      _vm
                                                                        .plagiarismLOs[
                                                                        index
                                                                      ],
                                                                      "enable_plagiarism_detection",
                                                                      $$a.concat(
                                                                        [$$v]
                                                                      )
                                                                    )
                                                                } else {
                                                                  $$i > -1 &&
                                                                    _vm.$set(
                                                                      _vm
                                                                        .plagiarismLOs[
                                                                        index
                                                                      ],
                                                                      "enable_plagiarism_detection",
                                                                      $$a
                                                                        .slice(
                                                                          0,
                                                                          $$i
                                                                        )
                                                                        .concat(
                                                                          $$a.slice(
                                                                            $$i +
                                                                              1
                                                                          )
                                                                        )
                                                                    )
                                                                }
                                                              } else {
                                                                _vm.$set(
                                                                  _vm
                                                                    .plagiarismLOs[
                                                                    index
                                                                  ],
                                                                  "enable_plagiarism_detection",
                                                                  $$c
                                                                )
                                                              }
                                                            },
                                                          },
                                                        }),
                                                        _c("label", [
                                                          _vm._v(
                                                            "Enable plagiarism detection on this LO"
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "lo-settings__range",
                                                      },
                                                      [
                                                        _c("label", [
                                                          _vm._v("Less Strict"),
                                                        ]),
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                _vm
                                                                  .plagiarismLOs[
                                                                  index
                                                                ]
                                                                  .plagiarism_strictness,
                                                              expression:
                                                                "plagiarismLOs[index].plagiarism_strictness",
                                                            },
                                                          ],
                                                          attrs: {
                                                            type: "range",
                                                            min: "0",
                                                            max: "100",
                                                            disabled:
                                                              !_vm
                                                                .plagiarismLOs[
                                                                index
                                                              ]
                                                                .enable_plagiarism_detection,
                                                          },
                                                          domProps: {
                                                            value:
                                                              _vm.plagiarismLOs[
                                                                index
                                                              ]
                                                                .plagiarism_strictness,
                                                          },
                                                          on: {
                                                            __r: function (
                                                              $event
                                                            ) {
                                                              return _vm.$set(
                                                                _vm
                                                                  .plagiarismLOs[
                                                                  index
                                                                ],
                                                                "plagiarism_strictness",
                                                                $event.target
                                                                  .value
                                                              )
                                                            },
                                                          },
                                                        }),
                                                        _c("label", [
                                                          _vm._v("More Strict"),
                                                        ]),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ]
                          ),
                          _c("div", { staticClass: "modal-footer" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn default",
                                attrs: { type: "button" },
                                on: { click: _vm.hide },
                              },
                              [_vm._v(" Close ")]
                            ),
                            _c(
                              "button",
                              {
                                staticClass: "btn primary",
                                attrs: { type: "button" },
                                on: { click: _vm.savePlagiarismSetting },
                              },
                              [_vm._v(" Save Plagiarism Settings ")]
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ]),
                ]),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }