import { render, staticRenderFns } from "./Characters.vue?vue&type=template&id=58cd9724"
import script from "./Characters.vue?vue&type=script&lang=js"
export * from "./Characters.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/tmp/build_2457f267/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('58cd9724')) {
      api.createRecord('58cd9724', component.options)
    } else {
      api.reload('58cd9724', component.options)
    }
    module.hot.accept("./Characters.vue?vue&type=template&id=58cd9724", function () {
      api.rerender('58cd9724', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Characters.vue"
export default component.exports